import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 * MobileAlert
 */

const MobileAlert = () => {
  const [showMobileAlert, setShowMobileAlert] = useState(true)
  const { t } = useTranslation()

  if (!showMobileAlert) {
    return null
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          className="inline-block align-bottom bg-gradient-to-r from-blue-200 to-yellow-400 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              aria-label="Close"
            >
              {/* <!-- Heroicon name: x --> */}
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="p-6 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-xl leading-6 font-bold text-heading" id="modal-headline">
                {t('msg:mobileAlert')}
              </h3>
            </div>
          </div>
          <div
            className="cursor-pointer absolute top-0 right-0"
            onClick={() => {
              setShowMobileAlert(false)
            }}
          >
            <img className="m-3" src="/images/icon-close-blue.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

export default MobileAlert

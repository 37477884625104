const loadPlayerJS = callback => {
  const existingScript = document.getElementById('playerjs')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = '/playerjs_v1.3.js'
    script.id = 'playerjs'
    script.async = false
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) {
        callback()
      }
    }
  }
  if (existingScript && callback) {
    callback()
  }
}
export default loadPlayerJS

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * PollOption
 */

const PollOption = ({ onClick, option, index, result, alreadyVoted, selected }) => {
  const [hasClicked, setClicked] = useState(selected)

  const handleClick = useCallback(() => {
    if (alreadyVoted || hasClicked) {
      return
    }

    setClicked(true)
    onClick(index)
  }, [alreadyVoted, hasClicked, index, onClick])

  const showResults = hasClicked || alreadyVoted
  const imageSrc = hasClicked ? '/images/icon-poll-selected.svg' : '/images/icon-poll-unselected.svg'

  return (
    <>
      <div
        className="option relative overflow-hidden mb-2 rounded-full cursor-pointer bg-heading text-white text-sm border border-heading"
        onClick={handleClick}
      >
        {showResults && <div className="progress absolute h-full rounded-full" />}
        <div className="flex flex-row h-full items-center justify-between py-2 px-3">
          <div className="flex flex-row z-10">
            <img className="mr-2" src={imageSrc} alt="#" />
            <span className="font-semibold">{option}</span>
          </div>
          {showResults && <span className={`z-10 ${hasClicked ? 'font-bold' : 'font-normal'}`}>{result}%</span>}
        </div>
      </div>
      <style jsx>{`
        .option {
          height: 40px;
        }

        .progress {
          width: ${result}%;
          transition: width 0.5s ease-in-out;
          background: linear-gradient(to right, #fddd0d, #00baff);
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

PollOption.propTypes = {
  option: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  result: PropTypes.number.isRequired,
  alreadyVoted: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
}

/**
 * Exports
 */

export default PollOption

import React, { useContext, useEffect } from 'react'

import { AuthContext } from '~/context/auth'
import { useSocketManager } from '~/context/socketManager'

/**
 * LogoutHandler
 */

const LogoutHandler = () => {
  const { logout } = useContext(AuthContext)
  const { loginKickOut } = useSocketManager()

  useEffect(() => {
    if (loginKickOut) {
      logout()
      window.location = '/full_capacity'
    }
  }, [loginKickOut, logout])

  return <div></div>
}

/**
 * Exports
 */

export default LogoutHandler

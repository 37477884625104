import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import { isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import ButtonLoader from '~/components/ButtonLoader'
import { bugsnag } from '~/lib/bugsnag'
import { removeEmojis } from '~/lib/helpers'
import { CometChatManager } from '../../util/controller'
import { SvgAvatar } from '../../util/svgavatar'
import AddMemberView from '../AddMemberView'
import Backdrop from '../Backdrop'
import { MAX_GROUP_USERS } from '../CometChatAddMembers'
import GroupDetailContext from '../CometChatGroupDetail/context'
import { AddMembersManager } from './controller'

import './style.scss'

/**
 * CometChatCreateGroupMembers
 */

class CometChatCreateGroupMembers extends React.Component {
  static contextType = GroupDetailContext

  state = {
    userlist: [],
    membersToAdd: [],
    isCreatingGroup: false,
    groupName: '',
    nameInputError: false,
  }

  componentDidMount() {
    if (this.props?.friendsOnly) {
      this.friendsOnly = this.props.friendsOnly
    }

    this.AddMembersManager = new AddMembersManager(this.friendsOnly)
    this.AddMembersManager.attachListeners(this.userUpdated)
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.getUsers()
    }
  }

  componentWillUnmount() {
    this.AddMembersManager.removeListeners()
    this.AddMembersManager = null
  }

  userUpdated = user => {
    const userlist = [...this.state.userlist]
    // search for user
    const userKey = userlist.findIndex(u => u.uid === user.uid)

    // if found in the list, update user object
    if (userKey > -1) {
      let userObj = userlist[userKey] // {...userlist[userKey]};
      let newUserObj = Object.assign({}, userObj, user)
      userlist.splice(userKey, 1, newUserObj)

      this.setState({ userlist: userlist })
    }
  }

  handleScroll = e => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight)
    if (bottom) {
      this.getUsers()
    }
  }

  searchUsers = e => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let val = e.target.value
    this.timeout = setTimeout(() => {
      this.AddMembersManager = new AddMembersManager(this.friendsOnly, val)
      this.setState({ userlist: [], membersToRemove: [] }, () => this.getUsers())
    }, 500)
  }

  getUsers = async () => {
    try {
      await new CometChatManager().getLoggedInUser()

      try {
        const userList = await this.AddMembersManager.fetchNextUsers()
        userList.forEach(user => (user = this.setAvatar(user)))
        this.setState({ userlist: [...this.state.userlist, ...userList] })
      } catch (error) {
        bugsnag.notify(error)
      }
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  setAvatar(user) {
    if (!user.getAvatar()) {
      const uid = user.getUid()
      const char = user
        .getName()
        .charAt(0)
        .toUpperCase()
      user.setAvatar(SvgAvatar.getAvatar(uid, char))
    }
  }

  setGroupAvatar = group => {
    if (!group.getIcon()) {
      const guid = group.getGuid()
      const char = group
        .getName()
        .charAt(0)
        .toUpperCase()
      group.setIcon(SvgAvatar.getAvatar(guid, char))
    }
  }

  membersUpdated = (user, userState) => {
    if (userState) {
      const members = [...this.state.membersToAdd]
      members.push(user)
      this.setState({ membersToAdd: [...members] })
    } else {
      const membersToAdd = [...this.state.membersToAdd]
      const IndexFound = membersToAdd.findIndex(member => member.uid === user.uid)
      if (IndexFound > -1) {
        membersToAdd.splice(IndexFound, 1)
        this.setState({ membersToAdd: [...membersToAdd] })
      }
    }
  }

  createGroup = async () => {
    const { groupName, membersToAdd, userlist } = this.state
    const name = removeEmojis(groupName).trim()

    if (isEmpty(name)) {
      this.setState({ nameInputError: true })

      return
    }

    this.setState({ isCreatingGroup: true })
    const user = await new CometChatManager().getLoggedInUser()
    const guid = `group_${new Date().getTime()}${user.uid}`
    const group = new CometChat.Group(guid, name, CometChat.GROUP_TYPE.PRIVATE)
    const imageNumber = Math.floor(Math.random() * 9 + 1)
    group.setMetadata({ photoUrl: `/images/defaults/groups/default_group_${imageNumber}.jpg` })
    const createdGroup = await CometChat.createGroup(group)
    const membersList = []

    membersToAdd.forEach(newmember => {
      // if a selected member is already part of the member list, don't add
      const newMember = new CometChat.GroupMember(newmember.uid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
      membersList.push(newMember)
      newmember['type'] = 'add'
    })

    if (membersList.length) {
      const membersToAdd = []
      const response = await CometChat.addMembersToGroup(createdGroup.guid, membersList, [])
      if (Object.keys(response).length) {
        for (const member in response) {
          if (response[member] === 'success') {
            const found = userlist.find(user => user.uid === member)
            if (!isNil(found)) {
              found['scope'] = CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
              membersToAdd.push(found)
            }
          }
        }
      }

      this.setGroupAvatar(createdGroup)
      const groupConversation = await CometChat.getConversation(createdGroup.guid, 'group')
      this.props.onGroupCreated(createdGroup, groupConversation)
      this.setState({ membersToAdd: [], groupName: '' })
      this.props.close()
    }

    this.setState({ isCreatingGroup: false })
  }

  handleCloseClick = () => {
    this.setState({ membersToAdd: [] })
    this.props.close()
  }

  handleGroupNameChange = e => {
    if (this.state.nameInputError) {
      this.setState({ nameInputError: false })
    }

    this.setState({ groupName: e.target.value })
  }

  render() {
    const group = this.context
    const userList = [...this.state.userlist]
    const { open } = this.props
    const { isCreatingGroup, nameInputError, membersToAdd, groupName } = this.state

    const filteredUserList = userList.filter(user => {
      const found = group.memberlist.find(member => user.uid === member.uid)
      const foundbanned = group.bannedmemberlist.find(member => user.uid === member.uid)
      if (found || foundbanned) {
        return false
      }

      return true
    })

    const wrapperClassName = classNames({
      'popup-box': true,
      'create-group-popup': true,
      show: open,
    })

    return (
      <>
        <Backdrop show={open} clicked={this.handleCloseClick} />
        <div className={wrapperClassName}>
          <span className="popup-close" onClick={this.handleCloseClick} title="Close" />
          <div className="popup-body">
            <div className="container">
              <div className="item-container mb-3">Crear grupo</div>
              <div className="item-container search relative flex mb-5">
                <input
                  type="text"
                  autoComplete="off"
                  className={`member-search bg-gray-100 border-2 ${
                    nameInputError ? 'border-red-400' : 'border-gray-100'
                  }`}
                  id="chatSearch"
                  value={this.state.groupName}
                  placeholder="Nombre"
                  onChange={this.handleGroupNameChange}
                  maxLength={50}
                />
                {nameInputError && (
                  <span className="name-input-error text-xs text-red-400">
                    El nombre es requerido para crear el grupo
                  </span>
                )}
              </div>
              <div className="item-container mb-3">
                Participantes ({membersToAdd.length}/{MAX_GROUP_USERS})
              </div>
              <div className="item-container search mb-4">
                <input
                  type="text"
                  autoComplete="off"
                  className="member-search bg-gray-100 border-2 border-gray-100"
                  placeholder="Buscar personas"
                  onChange={this.searchUsers}
                />
              </div>
              <div className="item-container user-list mb-2" onScroll={this.handleScroll}>
                {open &&
                  filteredUserList.map(user => {
                    return (
                      <AddMemberView
                        key={user.uid}
                        loggedinuser={group.loggedinuser}
                        user={user}
                        maxMembersAllowed={MAX_GROUP_USERS}
                        membersToAdd={this.state.membersToAdd}
                        changed={this.membersUpdated}
                      />
                    )
                  })}
              </div>
            </div>
            <button
              className={`create-group-button ${
                !isEmpty(membersToAdd) && !isEmpty(groupName) ? 'bg-primary' : 'bg-gray-400'
              }`}
              onClick={this.createGroup}
              disabled={isEmpty(membersToAdd) || isCreatingGroup}
            >
              {!isCreatingGroup && 'Agregar participantes'}
              {isCreatingGroup && <ButtonLoader />}
            </button>
          </div>
        </div>
        <style jsx>{`
          .name-input-error {
            position: absolute;
            bottom: -20px;
          }
        `}</style>
      </>
    )
  }
}

/**
 * PropTypes
 */

CometChatCreateGroupMembers.propTypes = {
  friendsOnly: PropTypes.bool,
  open: PropTypes.bool,
  close: PropTypes.func,
  onGroupCreated: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatCreateGroupMembers

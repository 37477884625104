import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import Backdrop from '../Backdrop'
import GroupDetailContext from '../CometChatGroupDetail/context'
import MemberView from '../MemberView'

import './style.scss'

/**
 * CometChatViewMembers
 */

class CometChatViewMembers extends React.Component {
  static contextType = GroupDetailContext

  handleScroll = e => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight)
    if (bottom) {
      this.props.actionGenerated('fetchGroupMembers')
    }
  }

  updateMembers = (action, member) => {
    switch (action) {
      case 'kick':
        this.kickMember(member)
        break
      default:
        break
    }
  }

  kickMember = async memberToKick => {
    try {
      await CometChat.kickGroupMember(this.props.item.guid, memberToKick.uid)
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  render() {
    const { close, open, item } = this.props
    const group = this.context

    const wrapperClassName = classNames({
      'popup-box': true,
      'view-member-popup': true,
      show: open,
    })

    return (
      <>
        <Backdrop show={open} clicked={close} />
        <div className={wrapperClassName}>
          <span className="popup-close" onClick={close} title="Close"></span>
          <div className="popup-body">
            <table>
              <caption>Participantes del grupo</caption>
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  {item.scope !== CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT && <th scope="col">Eliminar</th>}
                </tr>
              </thead>
              <tbody onScroll={this.handleScroll}>
                {group.memberlist.map((member, key) => {
                  return <MemberView key={key} member={member} item={item} actionGenerated={this.updateMembers} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

/**
 * PropTypes
 */

CometChatViewMembers.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  item: PropTypes.object,
  actionGenerated: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatViewMembers

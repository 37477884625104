import React from 'react'
import PropTypes from 'prop-types'

import Play from './Play'

function CameraRemote({ stream, play, muted, displayOption, domId }) {
  return <Play stream={stream} muted={muted} play={play} displayOption={displayOption} domId={domId} />
}

/**
 * PropTypes
 */
CameraRemote.propTypes = {
  stream: PropTypes.string.isRequired,
  play: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  displayOption: PropTypes.string.isRequired,
  domId: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default CameraRemote

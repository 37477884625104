import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'

/**
 * Constants
 */

const DEFAULT_STYLE = {
  transition: `opacity 300ms ease-in-out`,
  opacity: 0,
}

const TRANSITION_STYLES = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
}

/**
 * CardBtnCancel
 */

const CardBtnCancel = ({ onClick, show }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleClick = useCallback(async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }, [onClick])

  const cursorClassNames = show ? 'cursor-pointer' : 'cursor-default pointer-events-none'

  return (
    <Transition in={show} timeout={100} appear>
      {state => (
        <div style={{ ...DEFAULT_STYLE, ...TRANSITION_STYLES[state] }}>
          <div
            className={`button rounded-full h-8 flex items-center justify-center ${
              !loading ? 'bg-white' : 'bg-blue-200'
            } hover:bg-gray-200 border border-primary px-4 py-1 text-primary text-xs mr-2 leading-5 transition-all ${cursorClassNames}`}
            onClick={handleClick}
            disabled={loading || !show}
          >
            {t('sessions:cardButtons.cancel')}
          </div>
        </div>
      )}
    </Transition>
  )
}

/**
 * PropTypes
 */

CardBtnCancel.propTypes = {
  onClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

/**
 * Exports
 */

export default CardBtnCancel

import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'

import Heading from '~/components/Heading'
import HostsAvatars from '~/components/HostsAvatars'
import Paragraph from '~/components/Paragraph'
import { bugsnag } from '~/lib/bugsnag.js'
import { vodPropType } from '~/prop-types'
import loadPlayerJS from '../../scripts/loadplayer.js'

let player = null
let secureURL = 'https://vod.dev.larus.dift.io'

function isSecure(fileURL, suffix) {
  let hlsconfig = {}

  if (fileURL.indexOf(secureURL) === 0) {
    hlsconfig = {
      xhrSetup: function(xhr, url) {
        xhr.withCredentials = true // do send cookies
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type, Accept, X-Requested-With')
        xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:3000')
        xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
        if (url.indexOf(secureURL) === 0) {
          url += '?' + suffix // this suffix is updated every [x] seconds by polling the server
          xhr.open('GET', url, true)
        }
      },
    }
  }

  return { hlsconfig }
}

const VODPlayer = ({ vod }) => {
  const hls = vod.hls && vod.hls.substring(0, vod.hls.indexOf('?'))
  const suffix = vod.hls && vod.hls.substring(vod.hls.indexOf('?') + 1)
  const PLAYER_ID = 'player_' + vod.id

  useEffect(() => {
    let fileURL = vod.youtube ? vod.youtube : hls
    const { hlsconfig } = isSecure(fileURL, suffix)
    loadPlayerJS(() => {
      try {
        player = new window.Playerjs({
          id: PLAYER_ID,
          file: fileURL,
          hlsconfig,
          autoplay: vod.youtube ? 0 : 1,
        })
      } catch (error) {
        bugsnag.notify(error)
      }
    })

    return () => {
      if (player) {
        player.api('stop')
        player.api('destroy')
        player = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // let policy =
  //   'eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cCo6Ly92b2QuZGV2LmxhcnVzLmRpZnQuaW8vKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTk5OTExMDkwNX19fV19'
  // let signature =
  //   'aQvfp9ExDpGmr19OKxuEyltmWJjuWb-JUaiuyRmhE1hd-O5cRKm4USthx7gLlhnC3-IUOlevSab2s3xWLDKWEZAg8wdpSBQWFE6cXLB8rMCez9YEhbFey3nJxhGkNV5qRiJ9p21hYPO8gxXznfaI1sqWkesynTNgPrw9YHmYfkPzA~pZrEIe5Kj3vwQpg9imvwhXO8f6V4sFnqBr18OtVjArRx7-Ro7SsL4HxNde0RqAZPGCaPx9ubQ-0aIrV~eld9NN~jL9BTeuK--4IiFqxqbuFaWh3eUKC6IAjXcrSRkwKmAc7WU50ImC4Syz~QmmaPigfcZYfs~BxCJ5gsM-ng__'
  // let keyPairId = 'APKAJYJ5HRTT5PKDLY4Q'
  // let expires = '1799110906'

  // let cookie1 = `CloudFront-Policy=${policy}; Path=/; Domain=.vod.dev.larus.dift.io; Expires=Fri, 10 Sep 2021 14:40:47 GMT;`
  // let cookie2 = `CloudFront-Signature=${signature}; Path=/; Domain=.vod.dev.larus.dift.io; Expires=Fri, 10 Sep 2021 14:41:36 GMT;`
  // let cookie3 = `CloudFront-Key-Pair-Id=${keyPairId}; Path=/; Domain=.vod.dev.larus.dift.io; Expires=Fri, 10 Sep 2021 14:41:18 GMT;`

  // document.cookie = cookie1
  // document.cookie = cookie2
  // document.cookie = cookie3

  // let suffix = `Expires=${expires}&Policy=${policy}&Signature=${signature}&Key-Pair-Id=${keyPairId}`
  // let my_url_prefix = 'https://vod.dev.larus.dift.io'

  // useEffect(() => {
  //   let player = null

  //   function runPlayer() {
  //     let hlsconfig = {
  //       xhrSetup: function(xhr, url) {
  //         xhr.withCredentials = true // do send cookies
  //         xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
  //         xhr.setRequestHeader('Access-Control-Allow-Headers', 'Content-Type, Accept, X-Requested-With')
  //         xhr.setRequestHeader('Access-Control-Allow-Origin', 'http://localhost:3000')
  //         xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true')
  //         if (url.indexOf(my_url_prefix) === 0) {
  //           url += '?' + suffix // this suffix is updated every [x] seconds by polling the server
  //           xhr.open('GET', url, true)
  //         }
  //         // xhr.setRequestHeader('CloudFront-Policy', policy, { path: '/', expires: expires })
  //         // xhr.setRequestHeader('CloudFront-Signature', signature, { path: '/', expires: expires })
  //         // xhr.setRequestHeader('CloudFront-Key-Pair-Id', keyPairId, { path: '/', expires: expires })
  //         // xhr.setRequestHeader('Cookie', cookie1 + ';' + cookie2 + ';' + cookie3 + ';')
  //         // xhr.setRequestHeader('X-Cookie', cookie1)
  //         // xhr.setRequestHeader('X-Cookie', cookie2)
  //         // xhr.setRequestHeader('X-Cookie', cookie3)
  //       },
  //     }

  //     player = new window.Playerjs({
  //       id: 'player',
  //       file: hls,
  //       hlsconfig,
  //     })
  //   }

  //   const script = document.createElement('script')
  //   script.src = '/playerjs_vod_token.js'
  //   script.async = false
  //   script.onload = () => runPlayer()
  //   document.body.appendChild(script)

  //   return () => {
  //     document.body.removeChild(script)
  //     removePlayer()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <div>
      <div className="w overflow-hidden shadow-lg h-auto -mt-4 -mx-4 mb-6 md:mt-0 md:mx-4 md:mb-8">
        <div id={PLAYER_ID} className="w-full h-full" style={{ width: '100%' }}>
          <div className="bg-black" style={{ paddingBottom: '56%' }}></div>
        </div>
      </div>
      <div className="m-4">
        <div className="flex items-center">
          <Heading level={1} size="md">
            {vod.title}
          </Heading>
        </div>
        {!isEmpty(vod.Hosts) && (
          <div className="flex items-center my-4">
            <div className="flex relative z-0 overflow-hidden">
              {!isEmpty(vod.Hosts) && <HostsAvatars hosts={vod.Hosts} />}
            </div>
          </div>
        )}
        <div className="flex items-center mt-4">
          <Paragraph className="mt-1">
            <span dangerouslySetInnerHTML={{ __html: vod.description }} />
          </Paragraph>
        </div>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

VODPlayer.propTypes = {
  vod: vodPropType.isRequired,
}

/**
 * Exports
 */

export default VODPlayer

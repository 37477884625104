import React, { useContext, useEffect, useState } from 'react'
import { CometChat } from '@cometchat-pro/chat'

import { CometChatMessageListScreen } from '~/components/CometChat'
import { AuthContext } from '~/context/auth'
import { bugsnag } from '~/lib/bugsnag'
import PrivateChatMessage from './PrivateChatMessage'

/**
 * Constants
 */

const SUPPORT_USER_ID = 'coloquio_soporte'

/**
 * ChatSupport
 */

const ChatSupport = () => {
  const { cometChatUser } = useContext(AuthContext)
  const [supportUser, setSupportUser] = useState(null)

  useEffect(() => {
    const loadSupportUser = async () => {
      try {
        const user = await CometChat.getUser(SUPPORT_USER_ID)
        setSupportUser(user)
      } catch (error) {
        bugsnag.notify(error)
      }
    }

    loadSupportUser()
  }, [])

  return (
    <div className="bg-chatBackground">
      {supportUser && (
        <>
          <PrivateChatMessage />
          <CometChatMessageListScreen
            item={supportUser}
            hideMessageHeader
            hideInvitationMessage
            type="user"
            actionGenerated={() => {}}
            composedthreadmessage={{}}
            widgetconfig={{ 'threaded-chats': false }}
            loggedInUser={cometChatUser}
          />
        </>
      )}
    </div>
  )
}

export default ChatSupport

import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { useSessions } from '~/api'
import Heading from '~/components/Heading'
import PageContainer from '~/components/layout/PageContainer'
import Alert from '~/components/sessions/Alert'
import SessionCard from '~/components/sessions/SessionCard'

/**
 * Sessions
 */
const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

const Sessions = () => {
  const { t } = useTranslation()
  const { grouped, groupBlocked } = useSessions()

  let renderGroup = grouped => {
    const keys = Object.keys(grouped)

    return (
      <>
        <Alert title={t('sessions:alert')}></Alert>
        {keys.map(key => {
          return (
            <div key={key}>
              <div className="flex items-center mb-4">
                <Heading level={2} size="md" className="mb-2">
                  {capitalize(key)}
                </Heading>
              </div>
              <ul className="grid gap-6 grid-cols-1 xl:grid-cols-2 pb-8">
                {!isEmpty(grouped[key]) &&
                  grouped[key].map((session, index) => {
                    return <SessionCard session={session} key={index} groupBlocked={groupBlocked} />
                  })}
              </ul>
            </div>
          )
        })}
      </>
    )
  }

  return <PageContainer>{!isEmpty(grouped) && renderGroup(grouped)}</PageContainer>
}

/**
 * Exports
 */

export default Sessions

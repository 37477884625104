import React, { useContext } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import moment from 'moment'

import { useSession } from '~/api'
import { AuthContext } from '~/context/auth'

import 'moment-timezone'

/**
 * Detail
 */

const Detail = () => {
  const { id } = useParams()
  const { session } = useSession(id)
  const { user } = useContext(AuthContext)
  const { online, Users, zoom_id, hls, open_date, start_date, end_date } = session

  const isLive = Users && Users.length > 0 && (online || (moment().isAfter(start_date) && moment().isBefore(end_date)))
  const isOpened = Users && Users.length > 0 && open_date && moment().isAfter(open_date)
  const isModerator = parseInt(session.moderator, 10) === user.id

  if (isLive || isOpened || isModerator) {
    if (zoom_id) {
      return <Redirect to={`/sesiones/zoom/${id}`} />
    } else if (hls) {
      return <Redirect to={`/sesiones/stream/${id}`} />
    } else {
      return <Redirect to={`/sesiones/vivo/${id}`} />
    }
  }
}

/**
 * Exports
 */

export default Detail

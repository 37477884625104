// eslint-disable-next-line
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

import * as apiClient from '~/lib/api-client'
import { BugsnagErrorBoundary } from '~/lib/bugsnag'
import { setVhProperty } from '~/lib/helpers'
import { AuthProvider } from './context/auth'
import App from './App'

import '~/lib/i18n'
import '~/styles/main.css'
import 'moment/locale/es'

// Hack to get real browser viewport height
setVhProperty(window.innerHeight * 0.01)

window.addEventListener('resize', () => {
  setVhProperty(window.innerHeight * 0.01)
})

apiClient.init(process.env.REACT_APP_API_BASE_URL)

ReactDOM.render(
  <BugsnagErrorBoundary>
    <AuthProvider>
      <App />
    </AuthProvider>
  </BugsnagErrorBoundary>,
  document.getElementById('root')
)

import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

/**
 * RemainingTime
 */

const OffLineAgenda = ({ start_date }) => {
  const { t } = useTranslation()
  let diff = moment(start_date).diff(moment(), 'minutes')

  let label1 = 'HH:mm'
  let label2 = 'hs'

  if (diff > 1400) {
    label1 = 'D'
    label2 = ' días'
  } else if (diff < 60) {
    label1 = 'mm'
    label2 = ' minutos'
  }

  let timeLeft =
    moment()
      .utc()
      .startOf('month')
      .add(diff, 'minutes')
      .format(label1) + label2

  return (
    <div className="self-center flex justify-between w-full">
      <img className="align-top w-16 md:w-auto" src="/images/icon-time-left.svg" alt="" />
      <span className="block text-sm md:text-base leading-5 font-bold m-4">
        {t('offlineAgenda:remaining', { time: timeLeft })}
      </span>
      <span className="block text-sm text-paragraph leading-6 mt-4 ml-auto">{moment(start_date).format('HH:mmA')}</span>
    </div>
  )
}

/**
 * Exports
 */

export default OffLineAgenda

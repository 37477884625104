import React from 'react'
import PropTypes from 'prop-types'

import 'balloon-css'
import './style.scss'

/**
 * MessageHeader
 */

class MessageHeader extends React.Component {
  handleBackClick = () => this.props.actionGenerated('menuClicked')

  handleViewDetailClick = () => this.props.actionGenerated('viewDetail')

  render() {
    const isSupportUser = this.props.item.uid === 'coloquio_soporte'

    return (
      <div className="cc1-chat-win-header">
        <div className="cc1-chat-win-user">
          <div className="left-panel flex flex-row items-center">
            <div className="cc1-left-panel-trigger" onClick={this.handleBackClick}></div>
            <div className="cc1-chat-win-user-name-wrap">
              <h6 className="cc1-chat-win-user-name">{this.props.item.name}</h6>
            </div>
          </div>
          {!isSupportUser && <div className="cc1-right-panel-trigger" onClick={this.handleViewDetailClick} />}
        </div>
        <style jsx>{`
          .left-panel {
            width: calc(100% - 29px);
          }
        `}</style>
      </div>
    )
  }
}

/**
 * PropTypes
 */

MessageHeader.propTypes = {
  item: PropTypes.object,
  actionGenerated: PropTypes.func,
}

/**
 * Exports
 */

export default MessageHeader

import React, { useCallback, useState } from 'react'
import { isIE } from 'react-device-detect'

import Forgot from '~/components/guest/Forgot'
import Login from '~/components/guest/Login'
import SignUp from '~/components/guest/SignUp'
import UnsupportedBrowser from '~/components/guest/UnsupportedBrowser'
import GuestLayout from '~/components/layout/GuestLayout'

/**
 * Guest
 */

const Guest = () => {
  const [guest, setGuest] = useState(0)

  const handleLoginClick = useCallback(() => setGuest(0), [])
  const handleSignupClick = useCallback(() => setGuest(1), [])
  const handleForgotClick = useCallback(() => setGuest(2), [])

  return (
    <GuestLayout>
      {isIE && <UnsupportedBrowser />}
      {!isIE && guest === 0 && <Login onSignupClick={handleSignupClick} onForgotClick={handleForgotClick} />}
      {!isIE && guest === 1 && <SignUp onLoginClick={handleLoginClick} />}
      {!isIE && guest === 2 && <Forgot onForgotClick={handleForgotClick} onLoginClick={handleLoginClick} />}
    </GuestLayout>
  )
}

/**
 * Exports
 */

export default Guest

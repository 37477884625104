import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import { useOutsideClick } from '~/hooks'

/**
 * QuestionModal
 */

const QuestionModal = ({ onClose, onSubmit }) => {
  const { register, handleSubmit } = useForm()
  const modalRef = useRef(null)
  useOutsideClick(modalRef, onClose)
  const { t } = useTranslation()

  return (
    <>
      <div className="overlay fixed w-full h-full inset-0 z-20 flex justify-center">
        <div ref={modalRef} className="question-modal absolute bottom-0 px-6 py-5 bg-white rounded-lg font-bold">
          <div className="flex flex-row justify-between mb-6 px-1">
            <span className="pl-3">{t('mainStage:reactions.questionLabel')}</span>
            <div role="button" onClick={onClose}>
              <img
                src="/images/icon-close.svg"
                className="cursor-pointer"
                alt={t('mainStage:reactions.closeModalAlt')}
              />
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-row justify-between items-center">
              <input
                name="question"
                type="text"
                ref={register}
                placeholder="Escribe un mensaje"
                className="w-full mr-4 py-2 px-4 rounded-full outline-none bg-gray-300"
              />
              <button type="submit">
                <img src="/images/icon-send-message.svg" alt={t('mainStage:reactions.sendMessageAlt')} />
              </button>
            </form>
          </div>
        </div>
      </div>
      <style jsx>{`
        .overlay {
          background-color: rgba(0, 0, 0, 0.2);
        }

        .question-modal {
          width: 448px;
          margin-right: 250px;
          margin-bottom: 9rem;
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

QuestionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default QuestionModal

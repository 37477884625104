import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * Recovery
 */

const Recovery = () => {
  const { t } = useTranslation()
  const { recoveryPassword } = useContext(AuthContext)
  const history = useHistory()
  const { h } = useParams()
  const { register, handleSubmit, setValue, formState } = useForm()
  const [error, setError] = useState()

  useEffect(() => {
    register('token')
  }, [register])

  useEffect(() => {
    setValue('token', h)
  }, [h, setValue])

  const handleRecovery = useCallback(
    async ({ password, token }) => {
      try {
        let answer = await recoveryPassword(password, token)
        setError({ text: answer.message, layout: 'blue' })
        setTimeout(() => history.push('/'), 2000)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // If you have a user account with us, we've sent you an email with password reset instructions. If the email doesn't show up soon, check your spam folder.
  // app.bugsnag.com/user/password/edit?i=em&m=pr&reset_password_token=Q-8ntz19VYo4Sb8XFPze

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div>
            <Heading level={1} size="xl">
              {t('guest:recovery.title')}
            </Heading>
            <Heading level={2} size="sm" className="mt-4">
              {t('guest:recovery.subtitle')}
            </Heading>
          </div>
          <form className="mt-8" onSubmit={handleSubmit(handleRecovery)}>
            <div className="mb-4">
              <TextField label={t('guest:recovery.password1')} name="password" type="password" register={register} />
            </div>
            <div className="mt-6">
              <SubmitButton text={t('guest:recovery.submit')} width={211} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

export default Recovery

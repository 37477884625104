import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import NavItem from '~/components/layout/NavItem'
import LiveLabel from '~/components/LiveLabel'
import { Routes } from '~/constants/routes'
import { useSocketManager } from '~/context/socketManager'
/**
 * Nav
 */

const Nav = ({ isOpen, onItemClick }) => {
  const { mainStageLive } = useSocketManager()
  const { t } = useTranslation()

  return (
    <>
      <div className="nav absolute md:static flex flex-col justify-between items-center p-2 border-r-2 bg-white overflow-hidden">
        <ul>
          <NavItem onClick={onItemClick} imgSrc="/images/icon-home.svg" to={Routes.HOME} label={t('nav:home')} />
          <div className="relative">
            <NavItem
              onClick={onItemClick}
              imgSrc="/images/icon-plenary.svg"
              to={Routes.PLENARY}
              label={t('nav:plenaria')}
            />
            {!!(mainStageLive && mainStageLive.online) && <LiveLabel />}
          </div>
          <NavItem
            onClick={onItemClick}
            imgSrc="/images/icon-sessions.svg"
            to={Routes.SESSIONS}
            label={t('nav:sesiones')}
          />
          <NavItem
            onClick={onItemClick}
            imgSrc="/images/icon-expo.svg"
            to={Routes.EXPO}
            label={t('nav:expo')}
            className="text-6xs"
          />
          <NavItem onClick={onItemClick} imgSrc="/images/icon-vod.svg" to={Routes.VOD} label={t('nav:videos')} />
        </ul>
        <NavItem onClick={onItemClick} imgSrc="/images/icon-help.svg" to={Routes.SUPPORT} />
      </div>
      <style jsx>{`
        .nav {
          width: 80px;
          left: ${isOpen ? '0' : '-80px'};
          transition: left 0.2s ease-in-out;
        }

        @media only screen and (max-width: 768px) {
          .nav {
            height: calc(100% - 64px);
            z-index: 10;
          }
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onItemClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Nav

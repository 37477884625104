import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { uploadProfile } from '~/api'
import PageContainer from '~/components/layout/PageContainer'
import Success from '~/components/msg/Success'
import ImageProfile from '~/components/profile/ImageProfile'
import LogoutButton from '~/components/profile/LogoutButton'
import SliderButton from '~/components/profile/SliderButton'
import TextField from '~/components/profile/TextField'
import { useAuth } from '~/context/auth'
import { useSocketManager } from '~/context/socketManager'
import { bugsnag } from '~/lib/bugsnag'
import { getUserPhotoUrl } from '~/lib/helpers'

/**
 * Profile
 */

const Profile = () => {
  const { t } = useTranslation()
  const { user, mutateUser, logout, loginToCometChat } = useAuth()
  const { socketEmit } = useSocketManager()
  const [inboxActive, setUploadInboxActive] = useState(!user.inbox_active)
  const [success, setSuccess] = useState('')

  async function updateUser(query, callback) {
    try {
      await uploadProfile(user.id, query)
      mutateUser(query)
    } catch (error) {
      bugsnag.notify(error)
    }

    if (callback) {
      callback()
    }
  }

  function logoutCall() {
    socketEmit('disconnectAll')
    logout()
  }

  function mutateUserHandler(obj) {
    setSuccess(t('profile:messageImage'))
    mutateUser(obj)
  }
  const userPhotoUrl = getUserPhotoUrl(user)

  return (
    <PageContainer className="max-w-xl relative">
      <ImageProfile id={user.id} photoUrlUser={userPhotoUrl} mutateUser={mutateUserHandler} />
      <Success
        title={success}
        onClose={() => {
          setSuccess('')
        }}
      />
      <form action="#" method="POST" className="mt-12">
        <div>
          <TextField
            label={t('profile:name')}
            defaultValue={user.full_name}
            disabled={true}
            onChange={(full_name, callback) => {
              updateUser({ full_name }, callback)
            }}
          />
        </div>
        <div className="mt-6">
          <TextField
            label={t('profile:companyName')}
            defaultValue={user.company_fantasy}
            disabled={true}
            onChange={(company_fantasy, callback) => {
              updateUser({ company_fantasy }, callback)
            }}
          />
        </div>
        <div className="mt-6">
          <TextField
            label={t('profile:position')}
            defaultValue={user.company_role}
            onChange={(company_role, callback) => {
              updateUser({ company_role }, callback)
            }}
          />
        </div>
        <SliderButton
          label={t('profile:inboxActive')}
          secondLabel={t('profile:inboxActiveExplained')}
          defaultValue={inboxActive}
          onClick={inbox_active => {
            setUploadInboxActive(inbox_active)
            updateUser({ inbox_active: !inbox_active })
            setSuccess(t('profile:messageInbox'))
            if (!inbox_active) {
              loginToCometChat()
            }
          }}
        />
      </form>
      <LogoutButton label={t('profile:closeSession')} logout={logoutCall} />
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Profile

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

/**
 * CardArrow
 */
const LinkDuo = props => {
  let isExternal = props.to.indexOf('http') === 0

  return isExternal ? <a href={props.to} {...props} /> : <Link {...props} />
}
/**
 * PropTypes
 */

LinkDuo.propTypes = {
  to: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default LinkDuo

import React from 'react'
import PropTypes from 'prop-types'

/**
 * Paragraph
 */

const Paragraph = ({ children, className }) => {
  return (
    <p
      className={`text-xs sm:text-xs md:text-sm lg:text-sm xl:text-base text-paragraph leading-5 sm:leading-5 md:leading-5 lg:tracking-wide lg:leading-5 xl:leading-6 ${className}`}
    >
      {children}
    </p>
  )
}

/**
 * PropTYpes
 */

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Paragraph.defaultProps = {
  className: '',
}

/**
 * Exports
 */

export default Paragraph

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag.js'
import loadPlayerJS from '../scripts/loadplayer.js'

/**
 * Onboarding
 */

let player = null
// TODO: Setup final URL
const ONBOARDING_VIDEO_URL = 'https://vod.dev.larus.dift.io/institucional/onboarding/onboarding.m3u8'

const Onboarding = ({ toClose }) => {
  useEffect(() => {
    loadPlayerJS(() => {
      try {
        player = new window.Playerjs({
          id: 'onboarding_player',
          file: ONBOARDING_VIDEO_URL,
          autoplay: 1,
        })
      } catch (error) {
        bugsnag.notify(error)
      }
    })

    return () => {
      if (player) {
        player.api('stop')
        player.api('destroy')
        player = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
        <div
          className="relative inline-block text-center align-bottom bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform 
          transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 min-w-full lg:min-w-5xl"
        >
          <div id="onboarding_player" className="w-full h-full" style={{ width: '100%' }}>
            <div className="bg-black" style={{ paddingBottom: '56%' }}></div>
          </div>
          <div className="cursor-pointer absolute top-0 right-0" onClick={toClose}>
            <img className="m-3" src="/images/icon-close-blue.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

Onboarding.propTypes = {
  toClose: PropTypes.func,
}

/**
 * Exports
 */

export default Onboarding

import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import { get, size } from 'lodash'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { SvgAvatar } from '../../util/svgavatar'
import AddMemberView from '../AddMemberView'
import Backdrop from '../Backdrop'
import GroupDetailContext from '../CometChatGroupDetail/context'
import { AddMembersManager } from './controller'

import './style.scss'

/**
 * Constants
 */

export const MAX_GROUP_USERS = 40

/**
 * CometChatAddMembers
 */

class CometChatAddMembers extends React.Component {
  static contextType = GroupDetailContext

  state = {
    userlist: [],
    membersToAdd: [],
  }

  componentDidMount() {
    if (this.props?.friendsOnly) {
      this.friendsOnly = this.props.friendsOnly
    }

    this.AddMembersManager = new AddMembersManager(this.friendsOnly)
    this.getUsers()
    this.AddMembersManager.attachListeners(this.userUpdated)
  }

  componentWillUnmount() {
    this.AddMembersManager.removeListeners()
    this.AddMembersManager = null
  }

  userUpdated = user => {
    const userlist = [...this.state.userlist]
    const userIndex = userlist.findIndex(u => u.uid === user.uid)

    if (userIndex > -1) {
      const userObj = userlist[userIndex]
      const newUserObj = Object.assign({}, userObj, user)
      userlist.splice(userIndex, 1, newUserObj)

      this.setState({ userlist })
    }
  }

  handleScroll = e => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight)
    if (bottom) {
      this.getUsers()
    }
  }

  searchUsers = e => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let val = e.target.value
    this.timeout = setTimeout(() => {
      this.AddMembersManager = new AddMembersManager(this.friendsOnly, val)
      this.setState({ userlist: [], membersToAdd: [], membersToRemove: [] }, () => this.getUsers())
    }, 500)
  }

  getUsers = async () => {
    const group = this.context

    try {
      let userList = await this.AddMembersManager.fetchNextUsers()
      let secondPage = []

      const filteredUserList = userList.filter(user => {
        const found = group.memberlist.find(member => user.uid === member.uid)
        const foundbanned = group.bannedmemberlist.find(member => user.uid === member.uid)
        if (found || foundbanned) {
          return false
        }

        return true
      })

      if (userList.length > 5 && filteredUserList.length < 5) {
        secondPage = await this.AddMembersManager.fetchNextUsers()
        userList = [...userList, ...secondPage]
      }

      userList.forEach(user => (user = this.setAvatar(user)))
      this.setState({ userlist: [...this.state.userlist, ...userList] })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  setAvatar = user => {
    if (!user.getAvatar()) {
      const uid = user.getUid()
      const char = user
        .getName()
        .charAt(0)
        .toUpperCase()
      user.setAvatar(SvgAvatar.getAvatar(uid, char))
    }
  }

  membersUpdated = (user, userState) => {
    if (userState) {
      const members = [...this.state.membersToAdd]
      members.push(user)
      this.setState({ membersToAdd: [...members] })
    } else {
      const membersToAdd = [...this.state.membersToAdd]
      const indexFound = membersToAdd.findIndex(member => member.uid === user.uid)
      if (indexFound > -1) {
        membersToAdd.splice(indexFound, 1)
        this.setState({ membersToAdd: [...membersToAdd] })
      }
    }
  }

  updateMembers = async () => {
    const { close } = this.props
    const { membersToAdd } = this.state
    const group = this.context
    const guid = get(group, 'item.guid')
    const membersList = []

    membersToAdd.forEach(newmember => {
      // if a selected member is already part of the member list, don't add
      const IndexFound = group.memberlist.findIndex(member => member.uid === newmember.uid)
      if (IndexFound === -1) {
        const newMember = new CometChat.GroupMember(newmember.uid, CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT)
        membersList.push(newMember)
        newmember['type'] = 'add'
      }
    })

    if (membersList.length) {
      try {
        await CometChat.addMembersToGroup(guid, membersList, [])
        close()
      } catch (error) {
        bugsnag.notify(error)
      }
    }
  }

  render() {
    const { membersToAdd } = this.state
    const group = this.context
    const memberListCount = size(group.memberlist)

    const userList = [...this.state.userlist]

    const filteredUserList = userList.filter(user => {
      const found = group.memberlist.find(member => user.uid === member.uid)
      const foundbanned = group.bannedmemberlist.find(member => user.uid === member.uid)
      if (found || foundbanned) {
        return false
      }

      return true
    })

    const wrapperClassName = classNames({
      'popup-box': true,
      'add-member-popup': true,
      show: this.props.open,
    })

    return (
      <>
        <Backdrop show={this.props.open} clicked={this.props.close} />
        <div className={wrapperClassName}>
          <span className="popup-close" onClick={this.props.close} title="Close"></span>
          <div className="popup-body">
            <table>
              <caption>
                Agregar personas al grupo ({memberListCount + membersToAdd.length - 1}/{MAX_GROUP_USERS})
              </caption>
              <caption className="search">
                <input
                  type="text"
                  autoComplete="off"
                  className="member-search"
                  id="chatSearch"
                  placeholder="Buscar personas"
                  onChange={this.searchUsers}
                />
              </caption>
              <caption className="user-list" onScroll={this.handleScroll}>
                {filteredUserList.map(user => {
                  return (
                    <AddMemberView
                      key={user.uid}
                      loggedinuser={group.loggedinuser}
                      user={user}
                      membersToAdd={membersToAdd}
                      maxMembersAllowed={MAX_GROUP_USERS - memberListCount + 1}
                      changed={this.membersUpdated}
                    />
                  )
                })}
              </caption>
            </table>
            <button className="add-button" onClick={this.updateMembers}>
              Agregar
            </button>
          </div>
        </div>
      </>
    )
  }
}

/**
 * PropTypes
 */

CometChatAddMembers.propTypes = {
  open: PropTypes.bool,
  friendsOnly: PropTypes.bool,
  close: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatAddMembers

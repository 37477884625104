import React from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { useSession } from '~/api'
import Heading from '~/components/Heading'
import HostsAvatars from '~/components/HostsAvatars'
import PageContainer from '~/components/layout/PageContainer'
import Paragraph from '~/components/Paragraph'
import ZoomCard from '~/components/sessions/ZoomCard'

import 'moment-timezone'

/**
 * Zoom
 */

const Zoom = () => {
  const { id } = useParams()
  const { session } = useSession(id)
  const { title, Hosts, description } = session

  return (
    <PageContainer>
      <img className="align-top w-full mb-6 rounded-lg" src="/images/sessions/zoom.jpg" alt="Zoom" />
      <div>
        <div className="flex items-center mb-4">
          <Heading level={1} size="md">
            {title}
          </Heading>
        </div>
        <div className="flex items-center mb-4">
          <div className="flex relative z-0 overflow-hidden">{!isEmpty(Hosts) && <HostsAvatars hosts={Hosts} />}</div>
        </div>
        <div className="flex items-center mb-4 md:mr-4">
          <Paragraph className="mt-1">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </Paragraph>
        </div>
        <ZoomCard session={session}></ZoomCard>
      </div>
      <style jsx>{`
        .hero {
          min-height: 235px;
          background-image: url('/images/sessions/session_detail.jpg');
        }
      `}</style>
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Zoom

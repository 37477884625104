import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import UnreadMessageCount from './UnreadMessageCount'

/**
 * SidebarTab
 */

const SidebarTab = ({ label, onClick, selected, unreadMessageCount }) => {
  const handleClick = useCallback(() => onClick(label), [label, onClick])
  const showUnreadMessageCount = unreadMessageCount > 0

  return (
    <button
      onClick={handleClick}
      className={`flex flex-1 justify-center py-4 px-1 text-center border-b-2 border-transparent font-medium text-sm leading-5 focus:outline-none ${!selected &&
        'text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:text-gray-700 focus:border-gray-300'} ${selected &&
        'border-black text-heading'}`}
    >
      <div className="relative">
        {label}
        {showUnreadMessageCount && <UnreadMessageCount count={unreadMessageCount} />}
      </div>
    </button>
  )
}

/**
 * PropTypes
 */

SidebarTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  unreadMessageCount: PropTypes.number,
}

/**
 * Exports
 */

export default SidebarTab

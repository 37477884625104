import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useOutsideClick = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const useDetectClick = (shouldAddListener, onClick) => {
  useEffect(() => {
    if (shouldAddListener) {
      document.addEventListener('click', onClick)
    }

    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [onClick, shouldAddListener])
}

export const useHistoryChange = onChange => {
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen(onChange)

    return unlisten
  }, [listen, onChange])
}

export const useWindowResize = onResize => {
  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])
}

import React from 'react'

import './style.scss'

const avatar = props => {
  const cornerRadius = props.cornerRadius || '50%'

  const getStyle = () => ({
    borderRadius: cornerRadius,
  })

  return <img src={props.image} alt="Avatar" style={getStyle()} />
}

export default avatar

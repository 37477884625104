import React from 'react'
import PropTypes from 'prop-types'

/**
 * AccessoryLabel
 */

const AccessoryLabel = ({ text, onClick }) => (
  <p className="cursor-pointer text-base text-paragraph" onClick={onClick}>
    {text}
  </p>
)

/**
 * PropTypes
 */

AccessoryLabel.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default AccessoryLabel

import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'

import 'emoji-mart/css/emoji-mart.css'
import './style.scss'

/**
 * MessageComposer
 */

class MessageComposer extends React.PureComponent {
  node = React.createRef()
  imageUploaderRef = React.createRef()
  fileUploaderRef = React.createRef()
  audioUploaderRef = React.createRef()
  videoUploaderRef = React.createRef()
  messageInputRef = React.createRef()
  messageSending = false
  loggedInUser = null

  state = {
    showFilePicker: false,
    messageInput: '',
    messageType: '',
  }

  async componentDidMount() {
    const user = await new CometChatManager().getLoggedInUser()
    this.loggedInUser = user
  }

  pasteHtmlAtCaret(html, selectPastedContent) {
    var sel, range
    if (window.getSelection) {
      // IE9 and non-IE
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0)
        range.deleteContents()

        // Range.createContextualFragment() would be useful here but is
        // only relatively recently standardized and is not supported in
        // some browsers (IE9, for one)
        var el = document.createElement('div')
        el.innerHTML = html
        var frag = document.createDocumentFragment()
        let node, lastNode
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node)
        }
        var firstNode = frag.firstChild
        range.insertNode(frag)

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange()
          range.setStartAfter(lastNode)
          if (selectPastedContent) {
            range.setStartBefore(firstNode)
          } else {
            range.collapse(true)
          }
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }
    } else if ((sel = document.selection) && sel.type !== 'Control') {
      // IE < 9
      var originalRange = sel.createRange()
      originalRange.collapse(true)
      sel.createRange().pasteHTML(html)
      if (selectPastedContent) {
        range = sel.createRange()
        range.setEndPoint('StartToStart', originalRange)
        range.select()
      }
    }
  }

  changeHandler = event => {
    const elem = event.currentTarget
    let messageInput = elem.textContent.trim()

    if (!messageInput.length) {
      event.currentTarget.textContent = messageInput

      return false
    }

    this.setState({ messageInput: elem.innerText, messageType: 'text' })
  }

  sendMessageOnEnter = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault()
      this.messageInputRef.current.textContent = ''
      this.composeMessage()

      return true
    }
  }

  /**
   * Adds temp message to component message list and calls sendMessage()
   */
  composeMessage = () => {
    if (!this.state.messageInput.trim().length) {
      return false
    }

    const { type, item, actionGenerated, parentMessageId } = this.props

    const receiverId = type === 'user' ? item.uid : item.guid
    const textMessage = new CometChat.TextMessage(receiverId, this.state.messageInput.trim(), type)

    if (parentMessageId) {
      textMessage.setParentMessageId(parentMessageId)
    }

    const tempId = 'temp_id_' + new Date().getTime()
    const tempMessage = {
      ...textMessage,
      receiver: {
        uid: receiverId,
      },
      sender: {
        uid: this.loggedInUser.uid,
      },
      id: tempId,
    }

    this.setState({ messageInput: '' })
    this.messageInputRef.current.textContent = ''
    actionGenerated('messageComposed', [tempMessage])
    this.sendMessage(textMessage, tempId)
  }

  /**
   * Sends message to Comet Chat and update temp message in component message list
   */
  sendMessage = async (textMessage, tempId) => {
    try {
      const message = await CometChat.sendMessage(textMessage)
      this.props.actionGenerated('messageSent', message, null, null, tempId)
    } catch (error) {
      bugsnag.notify(error)
      // TODO: Handle message sent error
      // E.g.: actionGenerated('messageSentFailed', tempId)
    }
  }

  render() {
    const inputClassName = classNames({
      'cc1-chat-win-inpt-box': true,
      'selectable-text': true,
      disabled: this.props.item.blockedByMe,
    })

    return (
      <div className="cc1-chat-win-inpt-ext-wrap">
        <div className="cc1-chat-win-inpt-int-wrap">
          <div tabIndex="-1" className="cc1-chat-win-inpt-wrap">
            <div
              className={inputClassName}
              contentEditable="true"
              placeholder="Escribe un mensaje"
              dir="ltr"
              onInput={this.changeHandler}
              onKeyDown={this.sendMessageOnEnter}
              ref={this.messageInputRef}
            />
            <div className="cc1-chat-win-inpt-box-sticky">
              <div
                className="cc1-chat-win-inpt-icon-wrap"
                ref={node => {
                  this.node = node
                }}
              >
                <div id="messageInput" className="cc1-chat-win-inpt-send-btn" onClick={this.composeMessage}>
                  <img src="/images/icon-send-message.svg" alt="Enviar mensaje" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

/**
 * PropTypes
 */

MessageComposer.propTypes = {
  item: PropTypes.object,
  actionGenerated: PropTypes.func,
  parentMessageId: PropTypes.string,
  type: PropTypes.string,
}

/**
 * Exports
 */

export default MessageComposer

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

import { Routes } from '~/constants/routes'

/**
 * Helpers
 */

const isSelected = (pathname, to) => {
  return (to === Routes.HOME && pathname === Routes.HOME) || (to !== Routes.HOME && includes(pathname, to))
}

/**
 * NavItem
 */

const NavItem = ({ to, imgSrc, onClick, label }) => {
  const location = useLocation()
  const selected = isSelected(location.pathname, to)

  return (
    <Link to={to}>
      <div className="container">
        <div
          onClick={onClick}
          className={`icon flex items-center justify-center cursor-pointer mt-3 mb-1 w-full h-10 ${selected &&
            'bg-backgroundPrimary'} hover:bg-backgroundPrimary rounded-md`}
        >
          <img className={`w-7 h-7 ${selected && 'selected'}`} src={imgSrc} alt={label} />
        </div>
        <div
          className={`label flex justify-center text-heading ${
            label && label.length > 8 ? 'text-6xs' : 'text-5xs'
          } font-bold ${selected && 'selected'}`}
        >
          {label}
        </div>
      </div>
      <style jsx>{`
        .selected {
          filter: invert(0.5) sepia(1) saturate(5) hue-rotate(160deg);
        }

        .container:hover > .icon {
          background-color: #e0f8fe !important;
        }
      `}</style>
    </Link>
  )
}

/**
 * PropTypes
 */

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default NavItem

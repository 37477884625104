import React from 'react'
import Moment from 'react-moment'

import { sessionPropType } from '~/prop-types'

import 'moment-timezone'

/**
 * SessionInfo
 */

const SessionInfo = ({ session, isRegistered }) => {
  const { start_date, end_date, enrolled, quota } = session

  return (
    <div className="">
      <div className="relative -mr-px flex py-2 text-xs leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg ease-in-out duration-150">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
          <g fill="none" fillRule="evenodd">
            <rect width="24" height="24" />
            <path
              fill="#212F4F"
              fillRule="nonzero"
              d="M1.87666667,20 C0.8425,20 0.00166666667,19.1591667 0.00166666667,18.125 L0.00166666667,4.375 C0.00166666667,3.34083333 0.8425,2.5 1.87666667,2.5 L5.00166667,2.5 L5.00166667,0.625 C5.00166667,0.28 5.28166667,0 5.62666667,0 C5.97166667,0 6.25166667,0.28 6.25166667,0.625 L6.25166667,2.5 L13.7516667,2.5 L13.7516667,0.625 C13.7516667,0.28 14.0316667,0 14.3766667,0 C14.7216667,0 15.0016667,0.28 15.0016667,0.625 L15.0016667,2.5 L18.1266667,2.5 C19.1608333,2.5 20.0016667,3.34083333 20.0016667,4.375 L20.0016667,18.125 C20.0016667,19.1591667 19.1608333,20 18.1266667,20 L1.87666667,20 Z M1.25166667,18.125 C1.25166667,18.47 1.53166667,18.75 1.87666667,18.75 L18.1266667,18.75 C18.4716667,18.75 18.7516667,18.47 18.7516667,18.125 L18.7516667,8.75 L1.25166667,8.75 L1.25166667,18.125 Z M18.7516667,7.5 L18.7516667,4.375 C18.7516667,4.03 18.4716667,3.75 18.1266667,3.75 L15.0016667,3.75 L15.0016667,5 C15.0016667,5.345 14.7216667,5.625 14.3766667,5.625 C14.0316667,5.625 13.7516667,5.345 13.7516667,5 L13.7516667,3.75 L6.25166667,3.75 L6.25166667,5 C6.25166667,5.345 5.97166667,5.625 5.62666667,5.625 C5.28166667,5.625 5.00166667,5.345 5.00166667,5 L5.00166667,3.75 L1.87666667,3.75 C1.53166667,3.75 1.25166667,4.03 1.25166667,4.375 L1.25166667,7.5 L18.7516667,7.5 Z"
              transform="translate(2 2)"
            />
          </g>
        </svg>
        <span className="ml-1 -mt-px">
          <Moment format="DD MMM HH:mm">{start_date}</Moment> - <Moment format="HH:mm">{end_date}</Moment>
        </span>
        {!isRegistered && (
          <>
            <svg className="ml-4" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <rect width="24" height="24" />
                <path
                  fill="#212F4F"
                  fillRule="nonzero"
                  d="M9.16666667 10C6.41 10 4.16666667 7.75666667 4.16666667 5 4.16666667 2.24333333 6.41 0 9.16666667 0 11.9233333 0 14.1666667 2.24333333 14.1666667 5 14.1666667 7.75666667 11.9233333 10 9.16666667 10zM9.16666667 1.25C7.09916667 1.25 5.41666667 2.9325 5.41666667 5 5.41666667 7.0675 7.09916667 8.75 9.16666667 8.75 11.2341667 8.75 12.9166667 7.0675 12.9166667 5 12.9166667 2.9325 11.2341667 1.25 9.16666667 1.25zM17.2916667 20C16.9466667 20 16.6666667 19.72 16.6666667 19.375 16.6666667 15.2391667 13.3025 11.875 9.16666667 11.875 5.03083333 11.875 1.66666667 15.2391667 1.66666667 19.375 1.66666667 19.72 1.38666667 20 1.04166667 20 .696666667 20 .416666667 19.72.416666667 19.375.416666667 14.55 4.34166667 10.625 9.16666667 10.625 13.9916667 10.625 17.9166667 14.55 17.9166667 19.375 17.9166667 19.72 17.6366667 20 17.2916667 20z"
                  transform="translate(3 2)"
                />
              </g>
            </svg>
            <span className="ml-1 -mt-px">
              {enrolled}/{quota}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

SessionInfo.propTypes = {
  session: sessionPropType.isRequired,
}

/**
 * Exports
 */

export default SessionInfo

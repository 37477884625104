import React from 'react'

import './style.scss'

const statusindicator = props => {
  const cornerRadius = props.cornerRadius || '50%'

  const getStyle = () => ({
    borderRadius: cornerRadius,
  })

  const presenceClass = `presence ${props.status}`

  return <span className={presenceClass} style={getStyle()}></span>
}

export default statusindicator

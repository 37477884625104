import React from 'react'
import PropTypes from 'prop-types'

/**
 * Toast
 * LAYOUTs: red, blue, green
 */

const Toast = ({ message }) => (
  <div className={`absolute w-full top-0 py-3 md:p-6 ${message.layout === 'blue' ? 'bg-blue-200' : 'bg-red-200'}`}>
    <p className={`font-bold text-sm text-center ${message.layout === 'blue' ? 'text-blue-700' : 'text-red-600'}`}>
      {message.text}
    </p>
  </div>
)

/**
 * PropTypes
 */

Toast.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    layout: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default Toast

import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { get, includes, isEmpty } from 'lodash'
import { replace } from 'lodash'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'
import * as enums from '../../util/enums.js'
import ReceiverMessageBubble from '../ReceiverMessageBubble'
import SenderMessageBubble from '../SenderMessageBubble'
import { MessageListManager } from './controller'

import './style.scss'

/**
 * MessageList
 */

class MessageList extends React.PureComponent {
  loggedInUser = null
  lastScrollTop = 0
  messagesEnd = React.createRef()

  state = {
    loading: false,
  }

  componentDidMount() {
    const { item, type, parentMessageId, messageListType } = this.props

    if (parentMessageId) {
      this.MessageListManager = new MessageListManager(item, type, parentMessageId)
    } else {
      this.MessageListManager = new MessageListManager(item, type, null, messageListType)
    }

    this.getMessages()
    this.MessageListManager.attachListeners(this.messageUpdated)
  }

  componentDidUpdate(prevProps) {
    const previousMessageStr = JSON.stringify(prevProps.messages)
    const currentMessageStr = JSON.stringify(this.props.messages)

    if (this.props.type === 'user' && prevProps.item.uid !== this.props.item.uid) {
      this.MessageListManager.removeListeners()
      this.MessageListManager = new MessageListManager(this.props.item, this.props.type)
      this.getMessages()
      this.MessageListManager.attachListeners(this.messageUpdated)
    } else if (this.props.type === 'group' && prevProps.item.guid !== this.props.item.guid) {
      this.MessageListManager.removeListeners()
      this.MessageListManager = new MessageListManager(this.props.item, this.props.type)
      this.getMessages()
      this.MessageListManager.attachListeners(this.messageUpdated)
    } else if (prevProps.parentMessageId !== this.props.parentMessageId) {
      this.MessageListManager.removeListeners()
      this.MessageListManager = new MessageListManager(this.props.item, this.props.type, this.props.parentMessageId)
      this.getMessages()
      this.MessageListManager.attachListeners(this.messageUpdated)
    } else if (previousMessageStr !== currentMessageStr) {
      if (this.props.scrollToBottom) {
        this.scrollToBottom()
      } else {
        this.scrollToBottom(this.lastScrollTop)
      }
    }
  }

  componentWillUnmount() {
    this.MessageListManager.removeListeners()
    this.MessageListManager = null
  }

  scrollToBottom = (scrollHeight = 0) => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight - scrollHeight
    }
  }

  getMessages = async () => {
    this.setState({ loading: true })

    try {
      const loggedInUser = await new CometChatManager().getLoggedInUser()
      const user = await CometChat.getUser(loggedInUser.uid)
      this.loggedInUser = user

      try {
        const messageList = await this.MessageListManager.fetchPreviousMessages()
        messageList.forEach(message => {
          // if the sender of the message is not the loggedin user, mark it as read.
          const messageId = message.getId().toString()
          const senderUid = message.getSender().getUid()

          if (senderUid !== loggedInUser.getUid() && !message.getReadAt()) {
            if (message.getReceiverType() === 'user') {
              CometChat.markAsRead(messageId, senderUid, message.getReceiverType())
            } else if (message.getReceiverType() === 'group') {
              CometChat.markAsRead(messageId, message.getReceiverId(), message.getReceiverType())
            }
          }
        })

        this.lastScrollTop = this.messagesEnd.scrollHeight
        this.props.actionGenerated('messageFetched', messageList)
        this.setState({ loading: false })
      } catch (error) {
        bugsnag.notify(error)
        // TODO: Comet Chat not sending message for deleted group. Investigate other options
        if (error.code === 'ERR_GUID_NOT_FOUND') {
          this.props.onGroupError(this.props.item)
        }
        this.setState({ loading: false })
      }
    } catch (error) {
      bugsnag.notify(error)
      this.setState({ loading: false })
    }
  }

  // callback for listener functions
  messageUpdated = (key, message, group, options) => {
    switch (key) {
      case enums.MESSAGE_DELETED:
        this.messageDeleted(message)
        break
      case enums.MESSAGE_DELIVERED:
      case enums.MESSAGE_READ:
        this.messageReadAndDelivered(message)
        break
      case enums.MESSAGE_EDITED:
        this.messageEdited(message)
        break
      case enums.TEXT_MESSAGE_RECEIVED:
      case enums.MEDIA_MESSAGE_RECEIVED:
      case enums.CUSTOM_MESSAGE_RECEIVED:
        this.messageReceived(message)
        break
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_JOINED:
      case enums.GROUP_MEMBER_LEFT:
      case enums.GROUP_MEMBER_ADDED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_UNBANNED:
        this.groupUpdated(key, message, group, options)
        break
      default:
        break
    }
  }

  messageDeleted = message => {
    if (
      this.props.type === 'group' &&
      message.getReceiverType() === 'group' &&
      message.getReceiver().guid === this.props.item.guid
    ) {
      this.props.actionGenerated('messageDeleted', [message])
    } else if (
      this.props.type === 'user' &&
      message.getReceiverType() === 'user' &&
      message.getSender().getUid() === this.props.item.uid
    ) {
      this.props.actionGenerated('messageDeleted', [message])
    }
  }

  messageReadAndDelivered = message => {
    // read receipts
    if (
      message.getReceiverType() === 'user' &&
      message.getSender().getUid() === this.props.item.uid &&
      message.getReceiver() === this.loggedInUser.uid
    ) {
      let messageList = [...this.props.messages]
      if (message.getReceiptType() === 'delivery') {
        // search for same message
        const msg = messageList.find(m => m.id === message.messageId)

        // if found, update state
        if (msg) {
          msg['deliveredAt'] = message.getDeliveredAt()
          this.props.actionGenerated('messageUpdated', messageList)
        }
      } else if (message.getReceiptType() === 'read') {
        // search for same message
        const msg = messageList.find(m => m.id === message.messageId)
        // if found, update state
        if (msg) {
          msg['readAt'] = message.getReadAt()
          this.props.actionGenerated('messageUpdated', messageList)
        }
      }
    } else if (message.getReceiverType() === 'group' && message.getReceiver() === this.props.item.guid) {
      // not implemented
    }
  }

  messageEdited = message => {
    const messageList = [...this.props.messages]
    const msg = messageList.find(m => m.id === message.id)
    msg.metadata = message.getMetadata()
    this.props.actionGenerated('messageUpdated', messageList)
  }

  messageReceived = message => {
    if (
      this.props.type === 'group' &&
      message.getReceiverType() === 'group' &&
      message.getReceiver().guid === this.props.item.guid
    ) {
      if (!message.getReadAt()) {
        CometChat.markAsRead(message.getId().toString(), message.getReceiverId(), message.getReceiverType())
      }
      this.props.actionGenerated('messageReceived', [message])
    } else if (
      this.props.type === 'user' &&
      message.getReceiverType() === 'user' &&
      message.getSender().getUid() === this.props.item.uid
    ) {
      if (!message.getReadAt()) {
        CometChat.markAsRead(message.getId().toString(), message.getSender().getUid(), message.getReceiverType())
      }

      this.props.actionGenerated('messageReceived', [message])
    }
  }

  groupUpdated = (key, message, group, options) => {
    if (
      this.props.type === 'group' &&
      message.getReceiverType() === 'group' &&
      message.getReceiver().guid === this.props.item.guid
    ) {
      this.props.actionGenerated('groupUpdated', message, key, group, options)
    }
  }

  handleScroll = e => {
    const top = Math.round(e.currentTarget.scrollTop) === 0
    if (top && this.props.messages.length) {
      this.getMessages()
    }
  }

  handleClick = message => {
    this.props.onItemClick(message, 'message')
  }

  getSenderMessageComponent = (message, key) => {
    let component
    switch (message.type) {
      case CometChat.MESSAGE_TYPE.TEXT:
        component = message.text ? (
          <SenderMessageBubble
            key={key}
            message={message}
            widgetconfig={this.props.widgetconfig}
            actionGenerated={this.props.actionGenerated}
          />
        ) : null
        break
      default:
        break
    }

    return component
  }

  getReceiverMessageComponent = (message, key) => {
    const { widgetconfig, actionGenerated } = this.props

    let component
    switch (message.type) {
      case 'message':
      case CometChat.MESSAGE_TYPE.TEXT:
        component = message.text ? (
          <ReceiverMessageBubble
            key={key}
            loggedInUser={this.loggedInUser}
            message={message}
            widgetconfig={widgetconfig}
            actionGenerated={actionGenerated}
          />
        ) : null
        break
      default:
        break
    }

    return component
  }

  getActionMessageComponent = (message, key) => {
    let component = null
    if (message.message) {
      let newMessage = message.message || ''

      if (includes(message.message, 'System')) {
        newMessage = replace(newMessage, 'System', 'Organización')
      }

      if (includes(message.message, 'added')) {
        newMessage = replace(newMessage, 'added', 'agregó a')
      }

      if (includes(message.message, 'kicked')) {
        newMessage = replace(newMessage, 'kicked', 'eliminó a')
      }

      if (includes(message.message, 'left')) {
        newMessage = replace(newMessage, 'left', 'abandonó el grupo')
      }

      component = (
        <div className="cc1-chat-win-action-msg-wrap" key={key}>
          <p className="chat-txt-msg">{newMessage}</p>
        </div>
      )

      // if action messages are set to hide in config
      if (this.props.messageconfig) {
        const found = this.props.messageconfig.find(cfg => {
          return cfg.action === message.action && cfg.category === message.category
        })

        if (found && !found.enabled) {
          component = null
        }
      }
    }

    return component
  }

  getComponent = (message, key) => {
    if (isEmpty(this.loggedInUser)) {
      return null
    }

    let component
    const loggedInUserUid = get(this, 'loggedInUser.uid')
    const senderUid = get(message, 'sender.uid')

    switch (message.category) {
      case 'action':
        component = this.getActionMessageComponent(message, key)
        break
      case 'message':
        if (loggedInUserUid === senderUid) {
          component = this.getSenderMessageComponent(message, key)
        } else {
          component = this.getReceiverMessageComponent(message, key)
        }
        break
      default:
        break
    }

    return component
  }

  handleMessagesRef = el => {
    this.messagesEnd = el
  }

  render() {
    const { messages } = this.props
    const { loading } = this.state

    return (
      <div className="cc1-chat-win-conver-wrap">
        <div className="cc1-chat-win-conver-list-wrap" ref={this.handleMessagesRef} onScroll={this.handleScroll}>
          {loading && <div className="loading-text">Cargando...</div>}
          {!isEmpty(messages) && messages.map((message, key) => this.getComponent(message, key))}
        </div>
      </div>
    )
  }
}

/**
 * PropTypes
 */

MessageList.propTypes = {
  parentMessageId: PropTypes.string,
  messages: PropTypes.array,
  widgetconfig: PropTypes.object,
  actionGenerated: PropTypes.func,
  onGroupError: PropTypes.func,
  onItemClick: PropTypes.func,
  messageconfig: PropTypes.array,
  messageListType: PropTypes.string,
  item: PropTypes.object,
  type: PropTypes.string,
  scrollToBottom: PropTypes.bool,
}

/**
 * Exports
 */

export default MessageList

import React, { useCallback, useState } from 'react'
import { find, isEmpty, isNil, isNumber } from 'lodash'

import PollOption from '~/components/polls//PollOption'
import PollCountdown from '~/components/polls/PollCountdown'
import { usePollQuery } from '~/hooks/polls'

/**
 * Constants
 */

export const POLL_TIME = 59

/**
 * Helpers
 */

const calculateResultPercentage = (optionResults, totalAmount) => {
  if (!optionResults) {
    return 0
  }

  return Math.round((optionResults / totalAmount) * 100)
}

/**
 * Poll
 */

const Poll = () => {
  const { data, results, totalResults, sendAnswer } = usePollQuery()
  const { question, options, alreadyVoted, end_date } = data
  const [hasVoted, setVoted] = useState(alreadyVoted)

  const handleOptionClick = useCallback(
    option => {
      setVoted(true)
      sendAnswer(option)
    },
    [sendAnswer]
  )

  return (
    <>
      <div className="poll absolute top-0 w-full z-10 p-4 bg-heading">
        <div className="p-4 rounded-lg bg-white">
          <h3 className="mb-2 font-bold text-heading">{question}</h3>
          <div className="mb-3">
            {!isEmpty(options) &&
              options.map((item, index) => {
                const optionResult = find(results, item => item.option === index + 1)
                const resultPercentage = !isNil(optionResult)
                  ? calculateResultPercentage(optionResult.total, totalResults)
                  : 0

                return (
                  <PollOption
                    key={item}
                    index={index + 1}
                    option={item}
                    result={resultPercentage}
                    onClick={handleOptionClick}
                    selected={alreadyVoted === index + 1}
                    alreadyVoted={hasVoted || isNumber(alreadyVoted)}
                  />
                )
              })}
          </div>
          <PollCountdown endDate={end_date} />
        </div>
      </div>
      <style jsx>{`
        .poll {
          opacity: 1;
          animation-name: fadeIn;
          animation-iteration-count: 1;
          animation-timing-function: ease-in-out;
          animation-duration: 0.6s;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

export default Poll

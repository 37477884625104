import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import loadPlayerJS from '../../scripts/loadplayer.js'
import LangSelector from './LangSelector'

let player = null

function VideoPlayer({ agenda }) {
  const [hls, setHls] = useState(null)

  useEffect(() => {
    setHls(agenda.hls)
  }, [agenda.hls])

  useEffect(() => {
    if (hls && agenda.online) {
      loadPlayerJS(() => {
        player = new window.Playerjs({
          id: 'player',
          file: hls,
          autoplay: 1,
        })
      })
    } else if (!agenda.online && player) {
      player.api('destroy')
    }
  }, [agenda.online, hls])

  function handleOnChange(hls) {
    setHls(hls)
  }

  return (
    <>
      <div className="shadow-lg h-auto bg-black mb-4 bg-cover" style={{ backgroundImage: `url(${agenda.banner})` }}>
        <div id="player">
          <div className="" style={{ paddingBottom: '56%' }}></div>
        </div>
      </div>
      <LangSelector onChange={handleOnChange} agenda={agenda}></LangSelector>
    </>
  )
}

/**
 * PropTypes
 */
VideoPlayer.propTypes = {
  agenda: PropTypes.shape({
    hls: PropTypes.string.isRequired,
    online: PropTypes.number.isRequired,
    banner: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default VideoPlayer

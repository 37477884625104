import React, { useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import Avatar from '../Avatar'

import './style.scss'

/**
 * AddMemberView
 */

const AddMemberView = ({ membersToAdd, user, changed, maxMembersAllowed }) => {
  const [checked, setChecked] = useState(() => {
    const found = membersToAdd.find(member => member.uid === user.uid)

    return !isEmpty(found)
  })

  const handleCheck = useCallback(() => {
    if (membersToAdd.length >= maxMembersAllowed && !checked) {
      return
    }

    if (membersToAdd.length >= maxMembersAllowed && checked) {
      setChecked(false)
      changed(user, false)

      return
    }

    const value = checked ? false : true
    setChecked(value)
    changed(user, value)
  }, [changed, checked, maxMembersAllowed, membersToAdd.length, user])

  const checkboxImgSrc = checked ? '/images/checkbox-selected.svg' : '/images/checkbox-unselected.svg'

  return (
    <div className="flex flex-row justify-between items-center mt-4 mb-4">
      <div className="flex flex-row">
        <span className="avatar">
          <Avatar image={user.avatar} cornerRadius="50%" borderColor="#CCC" borderWidth="1px" />
        </span>
        <span className="flex items-center name">{user.name}</span>
      </div>
      <div>
        <img
          type="checkbox"
          alt="Seleccionar participante"
          className="checkbox"
          src={checkboxImgSrc}
          checked={checked}
          id={user.uid + 'sel'}
          onClick={handleCheck}
        />
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

AddMemberView.propTypes = {
  user: PropTypes.object,
  membersToAdd: PropTypes.array,
  maxMembersAllowed: PropTypes.number,
  changed: PropTypes.func,
}

/**
 * Exports
 */

export default AddMemberView

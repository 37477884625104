import React from 'react'
import PropTypes from 'prop-types'
/**
 * TextField
 */

const SliderButton = ({ label, secondLabel, defaultValue, onClick }) => {
  return (
    <>
      <div className="mt-6 flex items-center justify-between border-t sm:border-gray-300 pt-5">
        <div className="flex items-center">
          <label htmlFor="remember_me" className="ml-2 block text-base font-bold leading-5 text-gray-900">
            {label}
          </label>
        </div>
        <div className="text-sm leading-5">
          <span
            role="checkbox"
            onClick={() => {
              onClick(!defaultValue)
            }}
            className={`relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline  ${
              defaultValue ? 'bg-green-600' : 'bg-gray-200'
            }`}
          >
            <span
              className={`inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200 ${
                defaultValue ? 'translate-x-5' : 'translate-x-0'
              }`}
            ></span>
          </span>
        </div>
      </div>
      <div>
        <span className="text-xs align-left text-gray-500 block ml-2 w-8/12">{secondLabel}</span>
      </div>
    </>
  )
}

/**
 * PropTypes
 */

SliderButton.propTypes = {
  defaultValue: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  secondLabel: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SliderButton

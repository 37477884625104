import React from 'react'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'

import HostNames from '~/components/global/HostsNames'

/**
 * HostsAvatars
 */

const HostsAvatars = ({ hosts }) => {
  let sortedHost = orderBy(hosts, ['last_name'], ['asc'])
  sortedHost = orderBy(sortedHost, ['moderator'], ['desc'])

  return (
    <div className="flex w-full flex-col lg:flex-row ">
      <div>
        {sortedHost.map((host, index) => (
          <img
            key={index}
            className={`relative z-${50 - index * 10} ${
              index > 0 ? '-ml-6' : '-ml-1'
            }  inline-block h-11 w-11 box-content rounded-full object-cover text-white border-4 border-gray-100 shadow-solid`}
            src={host.image}
            alt={host.name}
          />
        ))}
      </div>
      <div className="mb-3 mt-1 lg:m-3">
        <HostNames sortedHost={sortedHost} />
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

HostsAvatars.propTypes = {
  hosts: PropTypes.array.isRequired,
}

/**
 * Exports
 */

export default HostsAvatars

import React, { useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import ReactGA from 'react-ga'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

import { uploadProfilePic } from '~/api'
import * as animationDataError from '~/lotties/profile_pic_error.json'
import * as animationData from '~/lotties/profile_pic_upload.json'

const ImageProfile = ({ id, photoUrlUser, mutateUser }) => {
  const inputFile = useRef(null)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const [photoUrl, setPhotoUrl] = useState(photoUrlUser)

  const onChange = e => {
    setPhotoUrl(null)
    uploadProfilePic(
      id,
      e.target.files[0],
      progressEvent => {
        setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)))
      },
      newPhotoUrl => {
        setPhotoUrl(newPhotoUrl)
        setUploadPercentage(0)
        mutateUser({ photo_url: newPhotoUrl })
      }
    )
    ReactGA.event({ category: 'Profile', action: 'ImageUpload' })
  }

  function handleClick() {
    inputFile.current.click()
  }

  return (
    <div className="relative mt-4">
      <div className="h-24 mb-2 md:mb-4">
        <div className="flex items-center justify-center">
          {photoUrl && <img className="w-24 h-24 rounded-full" src={photoUrl} alt="Imagen de perfil" />}
          {uploadPercentage > 0 && (
            <div>
              <div className="absolute top-1/2 h-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Lottie
                  isClickToPauseDisabled
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData.default,
                  }}
                />
              </div>
              <div className="w-full"></div>
            </div>
          )}
          {uploadPercentage < 0 && (
            <div className="absolute top-1/2 h-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Lottie
                isClickToPauseDisabled
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: animationDataError.default,
                }}
                onChange={onChange}
              />
            </div>
          )}
        </div>
      </div>
      {!isMobile && !isTablet && (
        <div className="flex flex-row justify-center items-center cursor-pointer" onClick={handleClick}>
          <p className="text-xs align-center text-gray-500 text-center block mr-2">Cambiar imagen</p>
          <img className="align-top w-3 inline-block" src="/images/icon-pincel.svg" alt="Cambiar imagen" />
        </div>
      )}
      <form className="hidden">
        <input type="file" ref={inputFile} accept="image/*" onChange={onChange} />
      </form>
    </div>
  )
}

/**
 * PropTypes
 */

ImageProfile.propTypes = {
  id: PropTypes.number.isRequired,
  photoUrlUser: PropTypes.string.isRequired,
  mutateUser: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ImageProfile

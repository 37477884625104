import React from 'react'

/**
 * MobileHeader
 */

const MobileHeader = () => (
  <>
    <div className="background relative overflow-hidden">
      <div className="header lg:hidden flex flex-row items-center justify-between bg-heading" />
      <div className="circle bg-white" />
      <div className="absolute h-full w-full top-0 flex items-center justify-center">
        <img className="logo object-contain" src="/images/guest/logo.svg" alt="Idea Logo" />
        <img className="text" src="/images/guest/mobile-text.svg" alt="Qué país queremos ser" />
      </div>
    </div>
    <style jsx>{`
      .background {
        background: linear-gradient(#00baff, #fddd0d);
        height: 70px;
      }

      .header {
        height: 100%;
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
        margin-right: 25px;
      }

      .logo {
        position: absolute;
        left: 20px;
        width: 58px;
      }

      .text {
        padding-top: 2px;
      }

      .circle {
        position: absolute;
        top: 0;
        right: -45px;
        border-radius: 9999px;
        width: 70px;
        height: 100%;
      }
    `}</style>
  </>
)

/**
 * Exports
 */

export default MobileHeader

import React from 'react'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'

import Heading from '~/components/Heading'

/**
 * VODCard
 */

const VODCard = ({ vod }) => {
  const { id, poster, title, icon } = vod

  return (
    <>
      <li className="card h-full col-span-1">
        <Link to={`/vod/${id}`}>
          <div className="flex flex-col h-full">
            <div>
              <div className="w-full flex items-center justify-between">
                <img
                  className="inline-block w-full box-content rounded-lg object-cover text-white"
                  src={poster}
                  alt={title}
                />
              </div>
            </div>
            <div className="relative -mr-px flex-row items-start py-2 text-xs leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg ease-in-out duration-150">
              <Heading level={2} size="xs" className="leading-6">
                {icon && <img className="mr-2 w-8 -mt-1 inline-block" src={icon} alt="" />}
                {title}
              </Heading>
            </div>
          </div>
        </Link>
      </li>
      <style jsx>{`
        .card {
          min-height: 210px;
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

VODCard.propTypes = {
  vod: PropTypes.object.isRequired,
}

/**
 * Exports
 */

export default VODCard

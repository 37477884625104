import React from 'react'
import PropTypes from 'prop-types'

/**
 * PageContainer
 */
const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce((r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r), {})

const PageContainer = ({ children, className }) => {
  const { containerwidth } = getQueryParams()

  return (
    <div className={`w-full overflow-y-scroll ${className}`}>
      <div className={`flex-1 py-4 md:py-8 ${containerwidth ? 'maxWidth' : 'max-w-5xl'} mx-auto px-4 sm:px-8 lg:px-8`}>
        {children}
      </div>
      <style jsx>{`
        .maxWidth {
          max-width: ${containerwidth}px;
        }
      `}</style>
    </div>
  )
}

/**
 * PropTypes
 */

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}

PageContainer.defaultProps = {
  className: '',
}

/**
 * Exports
 */

export default PageContainer

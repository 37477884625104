import React from 'react'
import PropTypes from 'prop-types'

/**
 * TextField
 */

const TextField = ({ label, name, type, register, accessoryLabel }) => (
  <div>
    <div className="flex justify-between items-center mb-2 text-xs">
      <label htmlFor={type} className="block text-base font-normal leading-5 text-paragraph">
        {label}
      </label>
      {accessoryLabel}
    </div>
    <div className="mt-1 relative rounded-md">
      <input
        aria-label={label}
        name={name}
        type={type}
        required
        className="form-input block w-full py-3 sm:text-base sm:leading-5"
        ref={register}
      />
    </div>
  </div>
)

/**
 * PropTypes
 */

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  accessoryLabel: PropTypes.node,
}

/**
 * Exports
 */

export default TextField

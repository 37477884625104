import React, { useContext } from 'react'
import { isEmpty } from 'lodash'

import VideoInfo from '~/components/VideoInfo'
import { AuthContext } from '~/context/auth'
import { sessionPropType } from '~/prop-types'
import ScreenPad from './Controls/ScreenPad'
import Program from './Program'

/**
 * StudioLive
 */

const StudioLive = ({ session }) => {
  const { moderator, full_title, description, Hosts } = session
  const { user } = useContext(AuthContext)
  const isModerator = parseInt(moderator, 10) === user.id
  const hasSession = !isEmpty(session)

  return (
    <>
      <div className="flex mb-4 w-full shadow-lg">
        <div className="w-full">
          <Program session={session}></Program>
        </div>
      </div>
      {hasSession && isModerator && <ScreenPad session={session}></ScreenPad>}
      {hasSession && <VideoInfo title={full_title} description={description} hosts={Hosts} />}
    </>
  )
}

/**
 * PropTypes
 */

StudioLive.propTypes = {
  session: sessionPropType.isRequired,
}

/**
 * Exports
 */

export default StudioLive

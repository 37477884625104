import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'

import Heading from '~/components/Heading'
import Paragraph from '~/components/Paragraph'

/**
 * UnsupportedBrowser
 */

const UnsupportedBrowser = () => {
  const { t } = useTranslation()

  useEffect(() => {
    ReactGA.event({ category: 'Message', action: 'UnsupportedBrowser' })
  }, [])

  return (
    <div className="relative">
      <div className="lg:min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-6 lg:px-9">
        <div className="max-w-md w-full">
          <div>
            <Heading level={1} size="xl" className="mb-6">
              {t('guest:login.title')}
            </Heading>
            <Paragraph className="mb-3">{t('guest:unsupported.description')}</Paragraph>
            <Paragraph className="mb-3">{t('guest:unsupported.recommendation')}</Paragraph>
            <div className="pl-4">
              <a className="underline" href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                <Paragraph className="mb-2">{t('guest:unsupported.chrome')}</Paragraph>
              </a>
              <a
                href="https://www.mozilla.org/en-US/firefox/new/"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Paragraph className="mb-2">{t('guest:unsupported.firefox')}</Paragraph>
              </a>
              {/* <a href="https://support.apple.com/downloads/safari" className="underline" target="_blank" rel="noopener noreferrer">
                <Paragraph className="mb-2">{t('guest:unsupported.safari')}</Paragraph>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

export default UnsupportedBrowser

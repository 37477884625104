import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { findIndex, get, includes, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { addGroupToUserMetadata } from '~/api'
import InvitationMessage from '~/components/chat/InvitationMessage'
import PrivateChatMessage from '~/components/sidebar/PrivateChatMessage'
import { bugsnag } from '~/lib/bugsnag'
import CometChatManager from '../../util/controller'
import MessageComposer from '../MessageComposer'
import MessageHeader from '../MessageHeader'
import MessageList from '../MessageList'

import './style.scss'

/**
 * CometChatMessageListScreen
 */

class CometChatMessageListScreen extends React.PureComponent {
  loggedInUser

  state = {
    messageList: [],
    scrollToBottom: true,
    outgoingCall: null,
  }

  async componentDidMount() {
    try {
      const loggedInUser = await new CometChatManager().getLoggedInUser()

      if (!isEmpty(loggedInUser)) {
        const user = await CometChat.getUser(loggedInUser.uid)
        this.loggedInUser = user
      }
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.type === 'user' && prevProps.item.uid !== this.props.item.uid) {
      this.setState({ messageList: [], scrollToBottom: true })
    } else if (this.props.type === 'group' && prevProps.item.guid !== this.props.item.guid) {
      this.setState({ messageList: [], scrollToBottom: true })
    } else if (prevProps.type !== this.props.type) {
      this.setState({ messageList: [], scrollToBottom: true })
    }
  }

  messageHeaderActionHandler = action => {
    switch (action) {
      case 'viewDetail':
        this.props.actionGenerated('viewDetail')
        break
      case 'menuClicked':
        this.props.actionGenerated('menuClicked')
        break
      default:
        break
    }
  }

  actionHandler = (action, messages, key, group, options) => {
    switch (action) {
      case 'messageReceived':
        this.appendMessage(messages)
        break
      case 'messageComposed':
        this.appendMessage(messages)
        break
      case 'messageSent':
        this.updateMessage(messages, options)
        break
      case 'messageUpdated':
        this.updateMessages(messages)
        break
      case 'messageFetched':
        this.prependMessages(messages)
        break
      case 'messageDeleted':
        this.removeMessages(messages)
        break
      case 'viewMessageThread':
        this.props.actionGenerated('viewMessageThread', messages)
        break
      case 'groupUpdated':
        this.groupUpdated(messages, key, group, options)
        break
      default:
        break
    }
  }

  // messages are deleted
  removeMessages = messages => {
    const messageList = [...this.state.messageList]
    const filteredMessages = messageList.filter(message => message.id !== messages[0].id)
    this.setState({ messageList: filteredMessages, scrollToBottom: false })
  }

  // messages are fetched from backend
  prependMessages = messages => {
    const messageList = [...messages, ...this.state.messageList]
    this.setState({ messageList: messageList, scrollToBottom: false })
  }

  // message is received or composed & sent
  appendMessage = message => {
    let messages = [...this.state.messageList]
    messages = messages.concat(message)
    this.setState({ messageList: messages, scrollToBottom: true })
  }

  // message status is updated
  updateMessages = messages => {
    this.setState({ messageList: messages })
  }

  // message updated
  updateMessage = (message, tempId) => {
    const messageList = [...this.state.messageList]
    const index = findIndex(messageList, { id: tempId })
    messageList.splice(index, 1, message)
    this.props.actionGenerated('updateConversation', message)
    this.setState({ messageList })
  }

  groupUpdated = (message, key, group, options) => {
    this.appendMessage([message])
    this.props.actionGenerated('groupUpdated', message, key, group, options)
  }

  callScreenAction = (action, call) => {
    switch (action) {
      case 'callStarted':
      case 'callEnded':
        if (!call) {
          return
        }
        this.appendMessage(call)
        break
      default:
        break
    }
  }

  handleAcceptInvitationClick = async item => {
    try {
      await addGroupToUserMetadata(item.guid)
      const loggedInUser = await new CometChatManager().getLoggedInUser()
      const user = await CometChat.getUser(loggedInUser.uid)
      this.props.actionGenerated('updateUser', user)
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  handleDenyInvitationClick = async () => {
    try {
      await CometChat.leaveGroup(this.props.item.guid)
      this.props.actionGenerated('leftGroup', this.props.item)
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  render() {
    const {
      item,
      type,
      viewdetail,
      hideMessageHeader,
      showPrivateMessageHeader,
      messageconfig,
      widgetconfig,
      loggedInUser,
      hideInvitationMessage,
      messageListType,
    } = this.props

    const isOwner = !isEmpty(loggedInUser) && item.owner === loggedInUser.uid
    const acceptedGroups = get(loggedInUser, 'metadata.acceptedGroups')
    const showInvitationMessage =
      !hideInvitationMessage && !isOwner && type === 'group' && !includes(acceptedGroups, item.guid)
    const isShadow = !isEmpty(loggedInUser) && loggedInUser.role === 'shadow'

    return (
      <>
        <div>
          {!hideMessageHeader && (
            <MessageHeader
              item={item}
              type={type}
              viewdetail={viewdetail}
              actionGenerated={this.messageHeaderActionHandler}
            />
          )}
          {showPrivateMessageHeader && <PrivateChatMessage />}
          <div className="container">
            <MessageList
              messages={this.state.messageList}
              item={item}
              type={type}
              scrollToBottom={this.state.scrollToBottom}
              messageconfig={messageconfig}
              widgetconfig={widgetconfig}
              actionGenerated={this.actionHandler}
              messageListType={messageListType}
              onGroupError={this.props.onGroupError}
            />
            {!isShadow && <MessageComposer item={item} type={type} actionGenerated={this.actionHandler} />}
            {!hideInvitationMessage && (
              <InvitationMessage
                open={showInvitationMessage}
                item={item}
                type={type}
                onAcceptClick={this.handleAcceptInvitationClick}
                onDenyClick={this.handleDenyInvitationClick}
              />
            )}
          </div>
        </div>
        <style jsx>
          {`
            .container {
              position: relative;
              display: flex;
              flex: 1;
              flex-direction: column;
              height: calc(100vh - ${hideInvitationMessage ? '160' : '217'}px);
            }
          `}
        </style>
      </>
    )
  }
}

/**
 * PropTypes
 */

CometChatMessageListScreen.propTypes = {
  showPrivateMessageHeader: PropTypes.bool,
  hideMessageHeader: PropTypes.bool,
  type: PropTypes.string,
  item: PropTypes.object,
  widgetconfig: PropTypes.object,
  viewdetail: PropTypes.bool,
  messageconfig: PropTypes.array,
  loggedInUser: PropTypes.object,
  actionGenerated: PropTypes.func,
  onGroupError: PropTypes.func,
  hideInvitationMessage: PropTypes.bool,
  messageListType: PropTypes.string,
}

CometChatMessageListScreen.defaultProps = {
  showPrivateMessageHeader: false,
  hideMessageHeader: false,
  hideInvitationMessage: false,
  loggedInUser: {},
  messageListType: null,
}

/**
 * Exports
 */

export default CometChatMessageListScreen

import React from 'react'
import classNames from 'classnames'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'
import * as enums from '../../util/enums.js'
import CometChatConversationList from '../CometChatConversationList'
import CometChatGroupDetail from '../CometChatGroupDetail'
import CometChatMessageListScreen from '../CometChatMessageListScreen'
import CometChatUserDetail from '../CometChatUserDetail'

import './style.scss'

/**
 * CometChatConversationListScreen
 */

class CometChatConversationListScreen extends React.Component {
  leftPanelRef = React.createRef()

  state = {
    viewdetailscreen: false,
    item: {},
    type: '',
    tab: 'conversations',
    groupToDelete: {},
    groupToLeave: {},
    groupToUpdate: {},
  }

  componentDidMount() {
    if (!Object.keys(this.state.item).length) {
      this.toggleSideBar()
    }
  }

  onItemClicked = (item, type) => {
    this.toggleSideBar()
    this.setState({ item, type, viewdetailscreen: false })
  }

  actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case 'blockUser':
        this.blockUser()
        break
      case 'unblockUser':
        this.unblockUser()
        break
      case 'viewDetail':
      case 'closeDetailClicked':
        this.toggleDetailView()
        break
      case 'menuClicked':
      case 'closeMenuClicked':
        this.toggleSideBar()
        break
      case 'groupUpdated':
        this.groupUpdated(item, count, ...otherProps)
        break
      case 'groupDeleted':
        this.deleteGroup(item)
        break
      case 'leftGroup':
        this.leaveGroup(item, ...otherProps)
        break
      case 'membersUpdated':
        this.updateMembersCount(item, count)
        break
      case 'updateUser':
        this.updateUser(item)
        break
      case 'updateConversation':
        this.props.updateConversation(item)
        break
      default:
        break
    }
  }

  updateUser = user => {
    this.props.updateUser(user)
  }

  blockUser = async () => {
    const usersList = [this.state.item.uid]
    try {
      await CometChatManager.blockUsers(usersList)
      this.setState({ item: { ...this.state.item, blockedByMe: true } })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  unblockUser = async () => {
    const usersList = [this.state.item.uid]
    try {
      await CometChatManager.unblockUsers(usersList)
      this.setState({ item: { ...this.state.item, blockedByMe: false } })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  toggleDetailView = () => {
    this.setState({ viewdetailscreen: !this.state.viewdetailscreen })
  }

  toggleSideBar = () => {
    const element = this.leftPanelRef.current

    if (element.classList.contains('active')) {
      element.classList.remove('active')
    } else {
      setTimeout(() => {
        this.setState({ item: {} })
        this.props.setSelectedConversation({})
      }, 500)
      element.classList.add('active')
    }
  }

  deleteGroup = group => {
    this.setState({ groupToDelete: group, item: {}, type: 'group', viewdetailscreen: false })
    this.toggleSideBar()
  }

  leaveGroup = group => {
    this.setState({ groupToLeave: group, item: {}, type: 'group', viewdetailscreen: false })
    this.toggleSideBar()
  }

  updateMembersCount = (item, count) => {
    const group = Object.assign({}, item, { membersCount: count })
    this.setState({ item: group, groupToUpdate: group })
  }

  groupUpdated = (message, key, group, options) => {
    const { loggedInUser } = this.props
    const optionsUserUid = get(options, 'user.uid')

    switch (key) {
      case enums.GROUP_MEMBER_BANNED:
      case enums.GROUP_MEMBER_KICKED: {
        if (optionsUserUid === loggedInUser.uid) {
          this.setState({ item: {}, type: 'group', viewdetailscreen: false })
        }
        break
      }
      case enums.GROUP_MEMBER_SCOPE_CHANGED: {
        if (optionsUserUid === loggedInUser.uid) {
          const newObj = Object.assign({}, this.state.item, { scope: options['scope'] })
          this.setState({ item: newObj, type: 'group', viewdetailscreen: false })
        }
        break
      }
      default:
        break
    }
  }

  render() {
    const { viewdetailscreen, type, item, tab, groupToDelete, groupToUpdate, groupToLeave } = this.state
    const {
      conversations,
      setNewConversations,
      getConversations,
      selectedConversation,
      setSelectedConversation,
      updateConversation,
      onNewGroupClick,
      loggedInUser,
    } = this.props

    const wrapperClassName = classNames({
      chats: true,
    })

    const centerPanelClassName = classNames({
      'ccl-center-panel': true,
      'ccl-chat-center-panel': true,
      'right-panel-active': viewdetailscreen,
    })

    const shouldShowUserDetail = viewdetailscreen && type === 'user'
    const shouldShowGroupDetail = viewdetailscreen && type === 'group'

    return (
      <div className={wrapperClassName}>
        <div className="ccl-left-panel" ref={this.leftPanelRef}>
          <CometChatConversationList
            item={item}
            onItemClick={this.onItemClicked}
            actionGenerated={this.actionHandler}
            groupToDelete={groupToDelete}
            groupToUpdate={groupToUpdate}
            groupToLeave={groupToLeave}
            conversations={conversations}
            setNewConversations={setNewConversations}
            getConversations={getConversations}
            selectedConversation={selectedConversation}
            setSelectedConversation={setSelectedConversation}
            updateConversation={updateConversation}
            onNewGroupClick={onNewGroupClick}
          />
        </div>
        <div className={centerPanelClassName}>
          {!isEmpty(item) && (
            <CometChatMessageListScreen
              item={item}
              tab={tab}
              type={type}
              actionGenerated={this.actionHandler}
              widgetconfig={{ 'threaded-chats': false }}
              showPrivateMessageHeader
              loggedInUser={loggedInUser}
              onGroupError={this.deleteGroup}
            />
          )}
        </div>
        {shouldShowUserDetail && (
          <div className="ccl-right-panel" ref={this.rightPanelRef}>
            <CometChatUserDetail item={item} type={type} actionGenerated={this.actionHandler} />
          </div>
        )}
        {shouldShowGroupDetail && (
          <div className="ccl-right-panel">
            <CometChatGroupDetail item={item} type={type} actionGenerated={this.actionHandler} />
          </div>
        )}
      </div>
    )
  }
}

/**
 * PropTypes
 */

CometChatConversationListScreen.propTypes = {
  setNewConversations: PropTypes.func,
  getConversations: PropTypes.func,
  updateConversation: PropTypes.func,
  conversations: PropTypes.array,
  selectedConversation: PropTypes.object,
  setSelectedConversation: PropTypes.func,
  onNewGroupClick: PropTypes.func,
  loggedInUser: PropTypes.object,
  updateUser: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatConversationListScreen

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CometChat } from '@cometchat-pro/chat'
import { isEmpty, isNil } from 'lodash'

import { CometChatMessageListScreen } from '~/components/CometChat'
import Poll from '~/components/polls/Poll'
import PublicChatMessage from '~/components/sidebar/PublicChatMessage'
import SidebarMessage from '~/components/sidebarMessage/SidebarMessage'
import { Routes } from '~/constants/routes'
import { useAuth } from '~/context/auth'
import { useSessionManager } from '~/context/session'
import { usePollQuery } from '~/hooks/polls'
import { useSidebarMessageQuery } from '~/hooks/sidebarMessages'
import ChatLoading from './ChatLoading'

/**
 * ChatRoom
 */

const ChatRoom = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { cometChatUser } = useAuth()
  const [cometChatGroup, setCometChatGroup] = useState(null)
  const { data: pollData } = usePollQuery()
  const { data: messageData } = useSidebarMessageQuery()
  const { session } = useSessionManager()
  const location = useLocation()

  const sessionGroupId = isEmpty(session) ? null : `session_${session.id}`
  const groupId = location.pathname === Routes.PLENARY ? 'plenaria' : sessionGroupId

  const loadGroup = useCallback(async () => {
    if (isNil(groupId)) {
      return
    }

    setLoading(true)

    try {
      const cometChatGroup = await CometChat.getGroup(groupId)
      setCometChatGroup(cometChatGroup)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [groupId])

  useEffect(() => {
    loadGroup()
  }, [groupId, loadGroup])

  const showPoll = !isEmpty(pollData)
  const showSidebarMessage = !isEmpty(messageData)

  if (loading || isEmpty(cometChatUser)) {
    return <ChatLoading />
  }

  return (
    <div className="bg-chatBackground">
      {error && (
        <>
          <div className="container flex flex-col h-full justify-center items-center">
            <h2 className="text-lg font-bold text-gray-600">{t('sidebar:chat.error.description')}</h2>
            <div className="cursor-pointer" onClick={loadGroup}>
              <h2 className="text-lg font-bold text-primary">{t('sidebar:chat.error.retry')}</h2>
            </div>
          </div>
          <style jsx>{`
            .container {
              height: calc(100vh - 120px);
            }
          `}</style>
        </>
      )}
      {cometChatGroup && (
        <>
          <PublicChatMessage />
          <div className="relative">
            {showPoll && <Poll />}
            {showSidebarMessage && <SidebarMessage />}
            <CometChatMessageListScreen
              hideMessageHeader
              hideInvitationMessage
              item={cometChatGroup}
              tab="groups"
              type="group"
              actionGenerated={() => {}}
              composedthreadmessage={{}}
              widgetconfig={{ 'threaded-chats': false }}
              loggedInUser={cometChatUser}
              messageListType="text"
            />
          </div>
        </>
      )}
    </div>
  )
}

/**
 * Exports
 */

export default ChatRoom

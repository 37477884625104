import React, { useContext, useEffect, useState } from 'react'
import { isEmpty } from 'lodash'

import PageContainer from '~/components/layout/PageContainer'
import LoadingContent from '~/components/LoadingContent'
import Stage from '~/components/sessions/stream/Stage'
import { AuthContext } from '~/context/auth'
import { useSessionManager } from '~/context/session'
import { useSocketManager } from '~/context/socketManager'

/**
 * SessionsStream
 */

const SessionsStream = () => {
  const { sessionOnline, socketEmit } = useSocketManager()
  const { user } = useContext(AuthContext)
  const { session } = useSessionManager()
  const [sessionPlay, setSessionPlay] = useState(0)
  const hasSession = !isEmpty(session)

  useEffect(() => {
    if (session && session.online >= 0) {
      setSessionPlay(session.online)
    }
  }, [session])

  useEffect(() => {
    if (sessionOnline) {
      setSessionPlay(parseInt(sessionOnline, 10))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionOnline])

  useEffect(() => {
    if (hasSession) {
      setTimeout(async () => {
        await socketEmit('sessionLogin', { token: user.token, session: session.id })
      }, 400)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession])

  const hasBottomBar = sessionPlay === 1 && session.reactionbox === 1

  return (
    <PageContainer
      className={`${!hasSession ? 'h-full justify-center flex flex-1' : ''} ${hasBottomBar ? 'md:mb-24' : ''}`}
    >
      {!hasSession && (
        <div className="flex justify-center h-full">
          <LoadingContent />
        </div>
      )}
      {hasSession && <Stage session={session} online={sessionPlay}></Stage>}
    </PageContainer>
  )
}

/**
 * Exports
 */

export default SessionsStream

const LOCAL_STORAGE_KEY = 'auth_token'

export const setToken = token => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, token)
}

export const removeToken = () => {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export const getToken = () => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY)
}

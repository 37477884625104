import React from 'react'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'
import { SvgAvatar } from '../../util/svgavatar'
import UserView from '../UserView'
import { UserListManager } from './controller'

import './style.scss'

/**
 * CometChatUserList
 */

class CometChatUserList extends React.PureComponent {
  timeout
  friendsOnly = false

  state = {
    userlist: [],
    loading: false,
  }

  componentDidMount() {
    if (this.props?.friendsOnly) {
      this.friendsOnly = this.props.friendsOnly
    }

    this.UserListManager = new UserListManager(this.friendsOnly)
    this.getUsers()
    this.UserListManager.attachListeners(this.userUpdated)
  }

  componentDidUpdate(prevProps) {
    // if user is blocked/unblocked, update userlist in state
    if (
      prevProps.item &&
      Object.keys(prevProps.item).length &&
      prevProps.item.uid === this.props.item.uid &&
      prevProps.item.blockedByMe !== this.props.item.blockedByMe
    ) {
      let userlist = [...this.state.userlist]
      let userObj = userlist.find(u => u.uid === this.props.item.uid)

      if (userObj) {
        userObj = Object.assign(userObj, { blockedByMe: this.props.item.blockedByMe })
      }

      this.setState({ userlist })
    }
  }

  componentWillUnmount() {
    this.UserListManager.removeListeners()
    this.UserListManager = null
  }

  userUpdated = user => {
    let userlist = [...this.state.userlist]
    // search for user
    let userKey = userlist.findIndex(u => u.uid === user.uid)

    // if found in the list, update user object
    if (userKey > -1) {
      let userObj = { ...userlist[userKey] }
      let newUserObj = { ...userObj, ...user }
      userlist.splice(userKey, 1, newUserObj)

      this.setState({ userlist: userlist })
    }
  }

  handleScroll = e => {
    const bottom =
      Math.round(e.currentTarget.scrollHeight - e.currentTarget.scrollTop) === Math.round(e.currentTarget.clientHeight)

    if (bottom) {
      this.getUsers()
    }
  }

  handleClick = user => {
    const { onItemClick } = this.props

    if (!onItemClick) {
      return
    }

    onItemClick(user, 'user')
  }

  handleMenuClose = () => {
    const { actionGenerated } = this.props

    if (!actionGenerated) {
      return false
    }

    actionGenerated('closeMenuClicked')
  }

  searchUsers = e => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    let val = e.target.value
    this.timeout = setTimeout(() => {
      this.UserListManager = new UserListManager(this.friendsOnly, val)
      this.setState({ userlist: [] }, () => this.getUsers())
    }, 500)
  }

  getUsers = async () => {
    this.setState({ loading: true })

    try {
      await new CometChatManager().getLoggedInUser()

      try {
        const userList = await this.UserListManager.fetchNextUsers()
        userList.forEach(user => (user = this.setAvatar(user)))
        this.setState({ userlist: [...this.state.userlist, ...userList], loading: false })
      } catch (error) {
        bugsnag.notify(error)
        this.setState({ loading: false })
      }
    } catch (error) {
      bugsnag.notify(error)
      this.setState({ loading: false })
    }
  }

  setAvatar(user) {
    if (!user.getAvatar()) {
      const uid = user.getUid()
      const char = user
        .getName()
        .charAt(0)
        .toUpperCase()
      user.setAvatar(SvgAvatar.getAvatar(uid, char))
    }
  }

  render() {
    const userList = [...this.state.userlist]
    let currentLetter = ''
    const users = userList.map((user, key) => {
      const chr = user.name[0].toUpperCase()
      if (chr !== currentLetter) {
        currentLetter = chr

        return (
          <div id={key} onClick={() => this.handleClick(user)} key={key}>
            <UserView key={user.uid} user={user}></UserView>
          </div>
        )
      } else {
        return (
          <div id={key} onClick={() => this.handleClick(user)} key={key}>
            <UserView key={user.uid} user={user}></UserView>
          </div>
        )
      }
    })

    const loading = this.state.loading ? <div className="loading-text">Cargando...</div> : null

    return (
      <>
        <div className="ccl-left-panel-srch-wrap">
          <div className="ccl-left-panel-srch-inpt-wrap">
            <input
              type="text"
              autoComplete="off"
              className="ccl-left-panel-srch"
              id="chatSearch"
              placeholder="Buscar personas"
              onChange={this.searchUsers}
            />
          </div>
        </div>
        <div className="chat-contact-list-ext-wrap" onScroll={this.handleScroll}>
          {loading}
          {users}
        </div>
      </>
    )
  }
}

/**
 * Exports
 */

CometChatUserList.propTypes = {
  onItemClick: PropTypes.func,
  actionGenerated: PropTypes.func,
  friendsOnly: PropTypes.bool,
  item: PropTypes.object,
}

/**
 * Exports
 */

export default CometChatUserList

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

/**
 * QuestionSuccessModal
 */

const QuestionSuccessModal = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="success-modal-container absolute bottom-0 w-full flex justify-center">
        <div className="success-modal relative flex justify-center items-center px-6 py-3 rounded-lg font-bold border-2 border-green-300 bg-green-100">
          <div className="text-green-700">{t('mainStage:reactions.successQuestion')}</div>
          <img
            alt={t('mainStage:reactions.closeModalAlt')}
            src="/images/icon-close.svg"
            className="absolute right-0 mr-3 cursor-pointer"
            onClick={onClose}
          />
        </div>
      </div>
      <style jsx>{`
        .success-modal-container {
          bottom: 7.5rem;
        }

        .success-modal {
          width: 354px;
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

QuestionSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default QuestionSuccessModal

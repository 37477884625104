import React from 'react'
import PropTypes from 'prop-types'

/**
 * Helpers
 */

const getFontStyle = size => {
  switch (size) {
    case 'xl':
      return 'text-3xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-5xl leading-8 sm:leading-8 md:leading-10 lg:leading-10 xl:leading-12 font-heading font-extrabold'
    case 'lg':
      return 'text-2xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-4xl leading-6 font-heading font-extrabold'
    case 'md':
      return 'text-2xl md:text-3xl leading-7 md:leading-9 font-heading font-extrabold'
    case 'sm':
      return 'text-lg md:text-xl leading-5 md:leading-6 font-bold'
    case 'xs':
      return 'text-lg leading-5 font-bold'
    default:
      return 'text-base leading-5 font-bold'
  }
}

/**
 * Heading
 */

const Heading = ({ children, level, size, className }) => {
  const Tag = level > 6 ? 'h6' : `h${level}`

  const fontStyle = getFontStyle(size)

  return <Tag className={`${fontStyle} text-heading ${className}`}>{children}</Tag>
}

/**
 * PropTYpes
 */

Heading.propTypes = {
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', '2xs']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Heading.defaultProps = {
  level: 1,
  size: 'medium',
}

/**
 * Exports
 */

export default Heading

import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import PropTypes from 'prop-types'

import Avatar from '../Avatar'
import GroupDetailContext from '../CometChatGroupDetail/context'
import StatusIndicator from '../StatusIndicator'
import kickIcon from './resources/delete.svg'

import './style.scss'

class MemberView extends React.Component {
  static contextType = GroupDetailContext

  state = {
    showChangeScope: false,
    scope: null,
  }

  render() {
    const group = this.context

    const hideKick =
      this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT ||
      this.props.item.owner === this.props.member.uid ||
      group.loggedinuser.uid === this.props.member.uid ||
      (this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
        (this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN ||
          this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR)) ||
      (this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN &&
        this.props.item.owner !== group.loggedinuser.uid &&
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN)

    const canEdit =
      this.props.item.scope !== CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT ||
      group.loggedinuser.uid !== this.props.member.uid

    // if the loggedin user is participant, don't show kick group members
    const editClassName = this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT ? 'noedit' : ''

    return (
      <tr>
        <td data-label="Name" className={editClassName}>
          <span className="avatar">
            <Avatar image={this.props.member.avatar} cornerRadius="18px" borderColor="#CCC" borderWidth="1px" />
            <StatusIndicator
              status={this.props.member.status}
              cornerRadius="50%"
              borderColor="rgb(238, 238, 238)"
              borderWidth="1px"
            />
          </span>
          <span className="name">{this.props.member.name}</span>
        </td>
        {canEdit && !hideKick && (
          <td data-label="Eliminar" className="kick">
            <span>
              <img
                src={kickIcon}
                alt="Eliminar"
                onClick={() => {
                  this.props.actionGenerated('kick', this.props.member)
                }}
              />
            </span>
          </td>
        )}
      </tr>
    )
  }
}

/**
 * PropTypes
 */

MemberView.propTypes = {
  member: PropTypes.object,
  item: PropTypes.object,
  actionGenerated: PropTypes.func,
}

/**
 * Exports
 */

export default MemberView

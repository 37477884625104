import React from 'react'
import Lottie from 'react-lottie'

import MobileHeader from '~/components/guest/MobileHeader'
import * as animationData from '~/lotties/login_background.json'

/**
 * Hero
 */

const Hero = () => (
  <>
    <div className="relative hidden lg:w-1/2 bg-heading lg:flex items-center justify-center">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-heading z-0">
        <Lottie
          isClickToPauseDisabled
          resizeMode="cover"
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData.default,
          }}
        />
      </div>
      <img className="logo lg:w-20 w-24" src="/images/guest/logo.svg" alt="Logo IDEA" />
      <img className="date  lg:w-56 w-92" src="/images/guest/date.png" alt="Fechas del evento" />
      <img className="anniversary lg:w-20 w-24" src="/images/guest/anniversary.png" alt="Logo aniversario" />
      <img className="text" src="/images/guest/text.png" alt="Qué país queremos ser" />
    </div>
    <MobileHeader />
    <style jsx>{`
      .logo {
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 1;
      }

      .date {
        position: absolute;
        left: 30px;
        bottom: 30px;
        z-index: 1;
      }

      .anniversary {
        position: absolute;
        right: 30px;
        bottom: 20px;
        z-index: 1;
      }

      .text {
        width: 65%;
        z-index: 1;
      }
    `}</style>
  </>
)

/**
 * Exports
 */

export default Hero

import React from 'react'
import PropTypes from 'prop-types'

import UnreadMessageCount from './UnreadMessageCount'

/**
 * Constants
 */

const BUTTON_OFFSET = { x: -5, y: -5 }

/**
 * SidebarButton
 */

const SidebarButton = ({ imgSrc, onClick, label, unreadMessageCount }) => {
  const showUnreadMessageCount = unreadMessageCount > 0

  return (
    <div className="my-2 cursor-pointer">
      <div
        onClick={onClick}
        className="relative flex items-center justify-center w-10 h-10 hover:bg-backgroundPrimary rounded-md"
      >
        <img className="w-7 h-7" src={imgSrc} alt={label} />
        {showUnreadMessageCount && <UnreadMessageCount offset={BUTTON_OFFSET} count={unreadMessageCount} />}
      </div>
      <div className="label flex justify-center text-heading text-5xs font-bold false uppercase">
        {label.length < 5 ? label : '...'}
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

SidebarButton.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  unreadMessageCount: PropTypes.number,
}

/**
 * Exports
 */

export default SidebarButton

import React from 'react'
import ReactGA from 'react-ga'

import { sessionPropType } from '~/prop-types'
import Heading from '../Heading'
import Paragraph from '../Paragraph'

/**
 * ZoomCard
 */

const ZoomCard = ({ session }) => {
  const { zoom_id, zoom_pass, zoom_link } = session

  return (
    <>
      <div className="p-6 card col-span-1 bg-white rounded-lg md:w-92 shadow relative mt-4 md:mt-10">
        <div className="flex flex-col justify-between">
          <div>
            <div className="text-left mb-4">
              <Paragraph className="mb-1">ID de reunión</Paragraph>
              <Heading level={3} size="sm">
                {zoom_id}
              </Heading>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <Paragraph className="mb-1">Contraseña</Paragraph>
              <Heading level={3} size="sm">
                {zoom_pass}
              </Heading>
            </div>
            <a
              href={zoom_link}
              target="_blank"
              onClick={() => {
                ReactGA.event({ category: 'Sessions', action: 'Zoom', label: zoom_id })
              }}
              rel="noopener noreferrer"
              className="text-white bg-primary hover:bg-blue-400 active:bg-indigo-700 inline-flex items-center align-baseline px-8 py-0 h-10 border border-transparent text-sm leading-3 font-medium rounded-full focus:outline-none transition ease-in-out duration-150"
            >
              Unirse
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

/**
 * PropTypes
 */

ZoomCard.propTypes = {
  session: sessionPropType.isRequired,
}

/**
 * Exports
 */

export default ZoomCard

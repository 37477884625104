import axios from 'axios'

import * as auth from '~/lib/auth'

export const init = baseURL => {
  axios.defaults.baseURL = baseURL

  axios.interceptors.request.use(function(config) {
    const token = auth.getToken()

    if (token) {
      config.headers.Authorization = `bearer ${token}`
    }

    config.params = { ...config.params, eventId: process.env.REACT_APP_EVENT_ID }
    config.headers['Access-Control-Allow-Origin'] = '*'

    return config
  })

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        auth.removeToken()
        window.location = '/session_expired'

        return
      }

      return Promise.reject(error)
    }
  )
}

export default axios

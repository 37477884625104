import React, { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import { isEmpty } from 'lodash'

import { sendSessionQuestion, sendSessionReactions } from '~/api'
import QuestionModal from '~/components/mainstage/QuestionModal'
import QuestionSuccessModal from '~/components/mainstage/QuestionSuccessModal'
import RemainingTime from '~/components/RemainingTime'
import { useSessionManager } from '~/context/session'
import { bugsnag } from '~/lib/bugsnag'
import * as animationDataClap from '~/lotties/mainstage_clap.json'
import * as animationDataDisLike from '~/lotties/mainstage_dislike.json'

/**
 * SessionReactionsBox
 */

const SessionReactionsBox = () => {
  const { t } = useTranslation()
  const { session } = useSessionManager()
  const [showQuestionModal, setShowQuestionModal] = useState(false)
  const [showSuccessMessage, setShowClearSuccessMessage] = useState(false)
  const [lottieClapStopped, setLottieClapStopped] = useState(true)
  const [lottieDisLikeStopped, setLottieDisLikeStopped] = useState(true)

  const handleCloseQuestionModal = useCallback(() => {
    setShowQuestionModal(false)
    ReactGA.event({ category: 'Session', action: 'SubmitQuestion', label: 'Close' })
  }, [])

  const handleClapClick = useCallback(() => {
    setLottieClapStopped(false)

    try {
      sendSessionReactions(session.id, 'clap')
    } catch (error) {
      bugsnag.notify(error)
    }

    setTimeout(() => {
      setLottieClapStopped(true)
    }, 1500)
    ReactGA.event({ category: 'Session', action: 'ReactionBox', label: 'Clap' })
  }, [session.id])

  const handleDislikeClick = useCallback(() => {
    setLottieDisLikeStopped(false)

    try {
      sendSessionReactions(session.id, 'dislike')
    } catch (error) {
      bugsnag.notify(error)
    }

    setTimeout(() => {
      setLottieClapStopped(true)
    }, 1000)
    ReactGA.event({ category: 'Session', action: 'ReactionBox', label: 'DisLike' })
  }, [session.id])

  const handleAskQuestionClick = useCallback(() => {
    setShowQuestionModal(true)
    ReactGA.event({ category: 'Session', action: 'SubmitQuestion', label: 'Open' })
  }, [])

  const handleSubmitQuestion = useCallback(
    values => {
      try {
        sendSessionQuestion(session.id, values.question)
      } catch (error) {
        bugsnag.notify(error)
      }

      setShowQuestionModal(false)
      setShowClearSuccessMessage(true)
      ReactGA.event({ category: 'Session', action: 'SubmitQuestion', label: 'Sent' })
    },
    [session.id]
  )

  const handleClearSuccessMessage = useCallback(() => {
    setShowClearSuccessMessage(false)
  }, [])

  useEffect(() => {
    let timeout = null

    if (showSuccessMessage) {
      timeout = setTimeout(handleClearSuccessMessage, 3000)
    }

    return () => clearTimeout(timeout)
  }, [handleClearSuccessMessage, showSuccessMessage])

  if (isEmpty(session)) {
    return null
  }

  return (
    <div className="md:block w-full bg-white border-b border-gray-200 absolute bottom-0 inset-x-0 h-24">
      <div className="flex h-full max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="self-center w-1/3">
          <RemainingTime start={session.start_date} end={session.end_date} />
        </div>
        <div className="w-1/3 flex flex-row items-center justify-center">
          <div className="mr-8 cursor-pointer max-w-15" onClick={handleClapClick}>
            <Lottie
              isClickToPauseDisabled
              isStopped={lottieClapStopped}
              width="100%"
              options={{
                loop: false,
                autoplay: false,
                animationData: animationDataClap.default,
              }}
            />
          </div>
          <div className="mr-8 cursor-pointer max-w-15" onClick={handleDislikeClick}>
            <Lottie
              isClickToPauseDisabled
              isStopped={lottieDisLikeStopped}
              width="100%"
              options={{
                loop: false,
                autoplay: false,
                animationData: animationDataDisLike.default,
              }}
            />
          </div>
          <div className="cursor-pointer">
            <img
              src="/images/icon-toolbox-question.svg"
              alt={t('mainStage:reactions.askQuestion')}
              onClick={handleAskQuestionClick}
            />
          </div>
        </div>
        <div className="w-1/3" />
      </div>
      {showQuestionModal && <QuestionModal onSubmit={handleSubmitQuestion} onClose={handleCloseQuestionModal} />}
      {showSuccessMessage && <QuestionSuccessModal onClose={handleClearSuccessMessage} />}
    </div>
  )
}

/**
 * Exports
 */

export default SessionReactionsBox

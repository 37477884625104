import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import DropdownItem from '~/components/header/DropdownItem'
import Transition from '~/components/Transition'
import { Routes } from '~/constants/routes'
import { AuthContext } from '~/context/auth'
import { useDetectClick } from '~/hooks'

/**
 * Dropdown
 */

const Dropdown = ({ isOpen, onOutsidePress, onChatClick }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { logout } = useContext(AuthContext)
  useDetectClick(isOpen, onOutsidePress)

  const handleProfileClick = useCallback(() => {
    history.push(Routes.PROFILE)
  }, [history])

  return (
    <div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-8 w-48 rounded-md shadow-lg z-20">
          <div
            className="py-1 rounded-md bg-white shadow-xs"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu"
          >
            <DropdownItem text={t('header:dropdown.profile')} onClick={handleProfileClick} />
            {/* <div className="block md:hidden">
              <DropdownItem text={t('header:dropdown.chat')} onClick={onChatClick} />
            </div> */}
            <DropdownItem text={t('header:dropdown.signOut')} onClick={logout} />
          </div>
        </div>
      </Transition>
    </div>
  )
}

/**
 * PropTypes
 */

Dropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOutsidePress: PropTypes.func.isRequired,
  onChatClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Dropdown

import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * LiveLabel
 */

const LiveLabel = () => {
  const { t } = useTranslation()

  return (
    <>
      <span className="live-label flex justify-center items-center absolute w-full tracking-widest bg-liveLabel rounded-sm font-bold text-white text-2xs     pointer-events-none">
        {t('nav:live')}
      </span>
      <style jsx>{`
        .live-label {
          bottom: -4px;
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

export default LiveLabel

import React from 'react'
import { Link } from 'react-router-dom'

/**
 * HiddenModeMessage
 */

const HiddenModeMessage = () => {
  return (
    <>
      <div className="container flex flex-col h-full justify-center items-center">
        <h2 className="text-lg font-bold text-gray-600">Estás en modo oculto</h2>
        <p className="text-base text-gray-500">
          Para activar el chat andá a tu{' '}
          <Link to="/perfil">
            <span className="font-bold text-primary">Perfil</span>
          </Link>
        </p>
      </div>
      <style jsx>{`
        .container {
          height: calc(100vh - 120px);
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

export default HiddenModeMessage

import React from 'react'
import PropTypes from 'prop-types'

import VideoInfo from '~/components/VideoInfo'
import VideoPlayer from './VideoPlayer'

/**
 * SessionStage
 */

const SessionStage = ({ session, online }) => {
  const { title, description, Hosts } = session

  return (
    <>
      <VideoPlayer session={session} online={online} />
      <VideoInfo title={title} description={description} hosts={Hosts} />
    </>
  )
}

/**
 * PropTypes
 */
SessionStage.propTypes = {
  session: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    Hosts: PropTypes.array,
  }),
  online: PropTypes.number.isRequired,
}

/**
 * Exports
 */

export default SessionStage

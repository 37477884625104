import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * SignUp
 */

const SignUp = ({ onLoginClick }) => {
  const { t } = useTranslation()
  const { signup } = useContext(AuthContext)
  const [error, setError] = useState()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      company_fantasy: '',
      company_role: '',
      email: '',
      password: '',
      externalId: 'App Sign Up',
    },
  })

  const handleSignup = useCallback(
    async ({ first_name, last_name, company_fantasy, company_role, email, password, externalId }) => {
      try {
        await signup(first_name, last_name, company_fantasy, company_role, email, password, externalId)
      } catch (error) {
        setError({ text: error.response.data.message, layout: 'red' })
      }
    },
    [signup]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} layout="red" />}
      <div className="min-h-screen flex justify-center items-center py-12 bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <Heading level={1} size="xl" className="mt-6">
            {t('guest:signup.title')}
          </Heading>
          <form className="mt-8" onSubmit={handleSubmit(handleSignup)}>
            <input type="hidden" name="remember" value="true" />
            <div className="mb-4">
              <TextField label={t('guest:signup.firstNameLabel')} name="first_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField label={t('guest:signup.lastNameLabel')} name="last_name" type="text" register={register} />
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:signup.companyLabel')}
                name="company_fantasy"
                type="text"
                register={register}
              />
            </div>
            <div className="mb-4">
              <TextField
                label={t('guest:signup.companyRoleLabel')}
                name="company_role"
                type="text"
                register={register}
              />
            </div>
            <div className="mb-4">
              <TextField label={t('guest:signup.emailLabel')} name="email" type="email" register={register} />
            </div>
            <TextField label={t('guest:signup.passwordLabel')} name="password" type="password" register={register} />
            <div className="mt-6 flex">
              <div className="text-sm leading-5" onClick={onLoginClick}>
                <p className="cursor-pointer font-medium text-paragraph focus:outline-none focus:underline">
                  {t('guest:signup.login')}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <SubmitButton text={t('guest:signup.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * Exports
 */

SignUp.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default SignUp

import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { AuthContext } from '~/context/auth'
import { getUserPhotoUrl } from '~/lib/helpers'

/**
 * DropdownButton
 */

const DropdownButton = ({ onClick }) => {
  const { user } = useContext(AuthContext)
  const userPhotoUrl = getUserPhotoUrl(user)

  return (
    <button
      className="flex items-center text-sm border-2 border-transparent rounded-full focus:outline-none transition duration-150 ease-in-out"
      id="user-menu"
      aria-label="User menu"
      aria-haspopup="true"
      onClick={onClick}
    >
      <img className="h-8 w-8 rounded-full" src={userPhotoUrl} alt="" />
      <svg className="-mr-1 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fillRule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  )
}

/**
 * PropTypes
 */

DropdownButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default DropdownButton

import React from 'react'

import './style.scss'

class CometChatUserDetail extends React.Component {
  render() {
    let blockUserDisplay

    if (this.props.item.blockedByMe) {
      blockUserDisplay = (
        <span className="ccl-dtls-section-listitem-link" onClick={() => this.props.actionGenerated('unblockUser')}>
          Desbloquear usuario
        </span>
      )
    } else {
      blockUserDisplay = (
        <span className="ccl-dtls-section-listitem-link" onClick={() => this.props.actionGenerated('blockUser')}>
          Bloquear usuario
        </span>
      )
    }

    return (
      <div className="ccl-dtls-panel-wrap">
        <div className="ccl-right-panel-head-wrap">
          <div className="cc1-right-panel-close" onClick={() => this.props.actionGenerated('closeDetailClicked')}></div>
        </div>
        <div className="ccl-dtls-panel-body">
          <div className="ccl-dtls-panel-section privacy">
            <h6 className="ccl-dtls-panel-section-head">Opciones</h6>
            <div className="ccl-dtls-section-list">
              <div className="ccl-dtls-section-listitem">{blockUserDisplay}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CometChatUserDetail

import { get, groupBy, isEmpty, isNil } from 'lodash'
import moment from 'moment'
import useSWR, { mutate } from 'swr'

import apiClient from '~/lib/api-client'
import { bugsnag } from '~/lib/bugsnag'

moment.locale('es')

/**
 * API Helpers
 */

const getSessions = () => apiClient.get('/api/sessions')
const getUrlId = url => apiClient.get(`/api/${url}`)
const getExpos = () => apiClient.get('/api/expos/all')

/**
 * API Hooks
 */

export const useSessions = () => {
  const { data, error } = useSWR('sessions', getSessions, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  let grouped = groupBy(data.data, session => {
    return moment(session.start_date)
      .format('dddd D [de] MMMM')
      .toLowerCase()
  })

  let groupBlocked = data.data.map(function(obj) {
    return obj.group && obj.Users && obj.Users.length > 0 ? obj.group : false
  })

  groupBlocked = groupBlocked.filter(function(v, i) {
    return groupBlocked.indexOf(v) === i
  })

  return { grouped, groupBlocked, error }
}

export const useSession = (id, suspense = true) => {
  const { data, error } = useSWR(!isNil(id) ? `sessions/${id}` : null, getUrlId, {
    suspense,
    revalidateOnFocus: false,
  })

  if (isEmpty(data)) {
    return { error }
  }

  return { session: data.data, error }
}

export const useEvent = (id, role) => {
  const { data, error } = useSWR(`events/${id}/role/${role}`, getUrlId, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  return { event: data.data, error }
}

export const useExpos = () => {
  const { data, error } = useSWR('expos', getExpos, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  return { expos: data.data.expos, error }
}

export const useVods = () => {
  const { data, error } = useSWR('vods', getUrlId, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  let grouped = groupBy(data.data, vods => {
    return vods.Group.name
  })

  return { grouped, error }
}

export const useVod = id => {
  const { data, error } = useSWR(`vods/${id}`, getUrlId, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  return { vod: data.data, error }
}

export const useFAQs = id => {
  const { data, error } = useSWR(`faqs/${id}`, getUrlId, { suspense: true })

  if (isEmpty(data)) {
    return { error }
  }

  return { support: data.data, error }
}

export const useMainStage = id => {
  const { data, error } = useSWR(`events/${id}/mainstage`, getUrlId, { suspense: true, revalidateOnFocus: false })

  if (isEmpty(data)) {
    return { error }
  }

  return { agenda: data.data, error }
}

export const forceMutateMainStage = id => {
  mutate(`events/${id}/mainstage`, getUrlId(`events/${id}/mainstage`))
}

/**
 * API HOOKS POST METHOD
 */

export const subscribeSessionUser = async (SessionId, action) => {
  const answer = await apiClient.post(`/api/sessions/${SessionId}/${action}`, {
    SessionId: SessionId,
    action: action,
  })

  mutate(`sessions`)

  if (isEmpty(answer.data)) {
    return { error: answer.error }
  }

  return { session: answer.data.session, error: answer.error }
}

export const uploadProfilePic = async (id, file, onUploadProgress, updateUser) => {
  let formData = new FormData()
  formData.append('file', file)
  formData.append('id', id)

  try {
    let res = await apiClient.put(`/api/users/update/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    updateUser(res.data.data.photo_url)

    return res
  } catch (err) {
    const status = get(err, 'response.status')
    if (status === 500) {
      onUploadProgress(-10)
    } else {
      onUploadProgress(-10)
    }
  }
}

export const uploadProfile = async (id, data) => {
  try {
    const response = await apiClient.put(`/api/users/update/${id}`, data)

    return response
  } catch (err) {
    bugsnag.notify(err)
    throw err
  }
}

export const sendReactions = async (AgendaId, reaction) => {
  const answer = await apiClient.post(`/api/reactions/${AgendaId}`, {
    reaction,
  })

  if (isEmpty(answer.data)) {
    return { error: answer.error }
  }

  return { session: answer.data.session, error: answer.error }
}

export const sendSessionReactions = async (sessionId, reaction) => {
  const answer = await apiClient.post(`/api/reactions/sessions/${sessionId}`, {
    reaction,
  })

  if (isEmpty(answer.data)) {
    return { error: answer.error }
  }

  return { session: answer.data.session, error: answer.error }
}

export const sendQuestion = async (AgendaId, question) => {
  const answer = await apiClient.post(`/api/questions/${AgendaId}`, {
    question,
  })

  if (isEmpty(answer.data)) {
    return { error: answer.error }
  }

  return { session: answer.data, error: answer.error }
}

export const sendSessionQuestion = async (sessionId, question) => {
  const answer = await apiClient.post(`/api/questions/sessions/${sessionId}`, {
    question,
  })

  if (isEmpty(answer.data)) {
    return { error: answer.error }
  }

  return { session: answer.data, error: answer.error }
}

export const addGroupToUserMetadata = async id => {
  try {
    const response = await apiClient.put(`/api/users/update/addGroup/${id}`)

    return response
  } catch (err) {
    bugsnag.notify(err)
    throw err
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import ButtonLoader from './ButtonLoader'

/**
 * SubmitButton
 */

const SubmitButton = ({ text, isSubmitting, width }) => {
  const content = !isSubmitting ? text : <ButtonLoader />

  return (
    <div>
      <button
        disabled={isSubmitting}
        type="submit"
        className="container relative flex justify-center py-3 px-8 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
      >
        {content}
      </button>
      <style jsx>{`
        .container {
          width: ${width}px;
        }
      `}</style>
    </div>
  )
}

/**
 * PropTypes
 */

SubmitButton.propTypes = {
  text: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool,
  width: PropTypes.number,
}

SubmitButton.defaultProps = {
  width: 120,
}

/**
 * Exports
 */

export default SubmitButton

import React from 'react'
import Lottie from 'react-lottie'

import * as animationData from '~/lotties/loading_spinner.json'

/**
 * LoadingContent
 */

const LoadingContent = () => (
  <div className="flex flex-1 justify-center items-center w-full">
    <div className="h-20">
      <Lottie
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: animationData.default,
        }}
      />
    </div>
  </div>
)

/**
 * Exports
 */

export default LoadingContent

import React from 'react'
import { isEmpty } from 'lodash'

import { CometChatUserListScreen } from '~/components/CometChat'
import { useAuth } from '~/context/auth'
import { useChat } from '~/context/chat'
import ChatLoading from './ChatLoading'

/**
 * ChatPeople
 */

const ChatPeople = () => {
  const { cometChatUser } = useAuth()
  const { updateConversation } = useChat()

  if (isEmpty(cometChatUser)) {
    return <ChatLoading />
  }

  return <CometChatUserListScreen updateConversation={updateConversation} />
}

/**
 * Exports
 */

export default ChatPeople

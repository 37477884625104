import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'
import { includes, isNil } from 'lodash'

import { useMainStage } from '~/api'
import ReactionsBox from '~/components/mainstage/ReactionsBox'
import SessionReactionsBox from '~/components/sessions/SessionReactionsBox'
import { Routes } from '~/constants/routes'
import { useSessionManager } from '~/context/session'

/**
 * BottomBar
 */

const BottomBar = () => {
  const location = useLocation()
  const { session, sessionPlay } = useSessionManager()
  const { agenda } = useMainStage(process.env.REACT_APP_EVENT_ID)
  const showReactionsBox = includes(location.pathname, Routes.PLENARY) && !isNil(agenda.start_date)
  const showSessionsBox =
    sessionPlay === 1 &&
    session.reactionbox === 1 &&
    (includes(location.pathname, '/sesiones/vivo/') || includes(location.pathname, '/sesiones/stream/'))

  return (
    <Suspense fallback={null}>
      <div className="hidden md:block">
        {showReactionsBox && <ReactionsBox />}
        {showSessionsBox && <SessionReactionsBox />}
      </div>
    </Suspense>
  )
}

/**
 * Exports
 */

export default BottomBar

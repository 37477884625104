import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { split } from 'lodash'
import PropTypes from 'prop-types'

import { useSession } from '~/api'
import { useSocketManager } from './socketManager'

const SessionContext = React.createContext()

export function SessionManager(props) {
  const location = useLocation()
  const id = split(location.pathname, '/sesiones/vivo/')[1] || split(location.pathname, '/sesiones/stream/')[1]
  const { session: data } = useSession(id, false)
  const [session, setSession] = useState({})
  const [isModerator, setIsModerator] = useState(false)
  const [isCameraActive, setIsCameraActive] = useState(false)
  const [mediaConstraints, setNewMediaConstraints] = useState({ video: true, audio: true })
  const [moderator, setModerator] = useState(0)
  const { sessionOnline } = useSocketManager()
  const [sessionPlay, setSessionPlay] = useState(0)

  useEffect(() => {
    if (session && session.online >= 0) {
      setSessionPlay(session.online)
    }
  }, [session])

  useEffect(() => {
    if (sessionOnline) {
      setSessionPlay(parseInt(sessionOnline, 10))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionOnline])

  useEffect(() => {
    if (data) {
      setSession(data)
      setIsModerator(parseInt(data.moderator, 10) === parseInt(props.user.id))
      setModerator(parseInt(data.moderator, 10))
    }
  }, [data, props.user])

  const setCameraActive = useCallback(data => {
    setIsCameraActive(data)
  }, [])

  const setMediaConstraints = useCallback(data => {
    setNewMediaConstraints(data)
  }, [])

  const value = useMemo(() => {
    return {
      session,
      moderator,
      isModerator,
      sessionPlay,
      setCameraActive,
      isCameraActive,
      setMediaConstraints,
      mediaConstraints,
    }
  }, [
    isCameraActive,
    isModerator,
    mediaConstraints,
    moderator,
    session,
    sessionPlay,
    setCameraActive,
    setMediaConstraints,
  ])

  return <SessionContext.Provider value={value} {...props} />
}

export function useSessionManager() {
  const context = React.useContext(SessionContext)
  if (!context) {
    throw new Error('useSessionManager should be inside SessionContext provider')
  }

  return context
}

/**
 * PropTypes
 */

SessionManager.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * TextField
 */

const TextField = ({ label, defaultValue, onChange, disabled, className }) => {
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    let interval = setTimeout(function() {
      if (defaultValue !== value) {
        setLoading(true)
        setSaved(false)
        onChange(value, () => {
          setSaved(true)
          setLoading(false)
          setTimeout(() => {
            setSaved(false)
          }, 1800)
        })
      }
    }, 800)

    return () => {
      clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div>
      <label className="block text-base font-medium leading-5 text-gray-700 mb-2">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          type="text"
          onChange={e => setValue(e.target.value)}
          disabled={disabled}
          defaultValue={defaultValue ? defaultValue : ''}
          className={`appearance-none ${
            disabled ? 'placeholder-gray-600 text-gray-600 cursor-not-allowed' : 'placeholder-gray-800 text-gray-800'
          } block w-full px-3 py-4 border bg-white border-gray-300 rounded-md  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-base sm:leading-5 ${className}`}
        />
        {saved && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        )}
        {loading && (
          <div className="absolute inset-y-0 right-0 pr-4 mr-4 flex items-center pointer-events-none">
            <svg className="circular-loader h-4 w-4" viewBox="25 25 50 50">
              <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#70c542" strokeWidth="2" />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

/**
 * Exports
 */

export default TextField

import React, { useContext, useState } from 'react'

import { AuthContext } from '~/context/auth'
import { useSocketManager } from '~/context/socketManager'
import { sessionPropType } from '~/prop-types'
import CameraRemote from '../WebRTC/CameraRemote'
import ScreenPadBtn from './ScreenPadBtn'

function ScreenPad({ session }) {
  const { user } = useContext(AuthContext)
  const [sessionOnline, setSessionOnline] = useState(session.online)
  const { socketEmit, programGraphics, programStatus, programGuest } = useSocketManager()

  async function showImage(e) {
    e.preventDefault()
    socketEmit('program', { program_status: 'image' })
  }

  async function showModerator(e) {
    e.preventDefault()
    socketEmit('program', { program_status: 'moderator' })
  }

  async function showSplit(e) {
    e.preventDefault()
    socketEmit('program', { program_status: 'split' })
  }

  if (session && user && user.id !== parseInt(session.moderator, 10)) {
    return <div>Cargando</div>
  }

  return (
    <div className="m-8 flex">
      <div className="self-center w-2/3">
        <ul className="mt-3 grid gap-4 sm:gap-6 grid-cols-3">
          <ScreenPadBtn
            title="Offline"
            sup="Imagen"
            sub="Poster Inicio"
            active={sessionOnline === 0}
            enable={true}
            onClick={e => {
              e.preventDefault()
              if (sessionOnline !== 0) {
                setSessionOnline(0)
                showImage(e)
                socketEmit('programGraphics', null)
                socketEmit('programRequest', {})
                socketEmit('sessionOnline', { online: 0 })
              }
            }}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Online"
            sup="Moderador"
            sub="Moderador"
            active={sessionOnline === 1}
            enable={true}
            onClick={e => {
              e.preventDefault()
              if (sessionOnline !== 1) {
                setSessionOnline(1)
                socketEmit('sessionOnline', { online: 1 })
              }
            }}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Terminar"
            sup="Terminar"
            sub="Terminar"
            active={sessionOnline === 2}
            enable={true}
            onClick={e => {
              e.preventDefault()
              if (sessionOnline !== 2) {
                setSessionOnline(2)
                showImage(e)
                socketEmit('programGraphics', null)
                socketEmit('programRequest', {})
                socketEmit('sessionOnline', { online: 2 })
              }
            }}
          ></ScreenPadBtn>
        </ul>
        <ul className="mt-3 grid gap-4 sm:gap-6 grid-cols-3">
          <ScreenPadBtn
            title="No Graph"
            sup="Imagen"
            sub="Poster Inicio"
            active={!programGraphics}
            enable={sessionOnline === 1}
            onClick={e => {
              e.preventDefault()
              socketEmit('programGraphics', null)
            }}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Intro"
            sup="Moderador"
            sub="Moderador"
            active={!!programGraphics && programGraphics.status === 'bump'}
            enable={sessionOnline === 1}
            onClick={e => {
              e.preventDefault()
              socketEmit('programGraphics', { status: 'bump' })
            }}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Zócalo"
            sup="Split"
            sub="Mod & Inv"
            active={!!programGraphics && programGraphics.status === 'snipe'}
            enable={sessionOnline === 1}
            onClick={e => {
              e.preventDefault()
              socketEmit('programGraphics', { status: 'snipe' })
            }}
          ></ScreenPadBtn>
        </ul>
        <ul className="mt-3 grid gap-4 sm:gap-6 grid-cols-3">
          <ScreenPadBtn
            title="Camara Apagada"
            sup="Imagen"
            sub="Poster Inicio"
            active={programStatus === 'image'}
            enable={sessionOnline === 1}
            onClick={showImage}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Moderador"
            sup="Moderador"
            sub="Moderador"
            active={programStatus === 'moderator'}
            enable={sessionOnline === 1}
            onClick={showModerator}
          ></ScreenPadBtn>
          <ScreenPadBtn
            title="Moderador Invitado"
            sup="Split"
            sub="Mod & Inv"
            active={programStatus === 'split'}
            enable={sessionOnline === 1 && !!programGuest && programGuest.id > 0}
            onClick={showSplit}
          ></ScreenPadBtn>
        </ul>
      </div>
      <div className="relative w-1/3 p-4">
        {!!programGuest && programGuest.id > 0 && programStatus === 'moderator' && (
          <CameraRemote
            play={true}
            muted={true}
            stream={'' + programGuest.id}
            displayOption="max-w-32 absolute bottom-0"
            domId="preview"
          />
        )}
      </div>
    </div>
  )
}

/**
 * PropTypes
 */
ScreenPad.propTypes = {
  session: sessionPropType,
}

/**
 * Exports
 */

export default ScreenPad

import React from 'react'
import PropTypes from 'prop-types'

/**
 * DropdownButton
 */

const DropdownButton = ({ text, onClick }) => (
  <button
    onClick={onClick}
    className="block w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
    role="menuitem"
  >
    {text}
  </button>
)

/**
 * PropTypes
 */

DropdownButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default DropdownButton

import React from 'react'
import PropTypes from 'prop-types'

/**
 * Alert
 */

const Alert = ({ title }) => {
  return (
    <>
      <div className="rounded-r-full rounded-bl-full bg-white p-3 mb-4 md:mb-8">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <img alt="Alert" src="/images/bubble-alert.svg" />
          </div>
          <div className="ml-4 flex items-center">
            <h3 className="text-base leading-5 font-medium text-heading">{title}</h3>
          </div>
        </div>
      </div>
    </>
  )
}

/**
 * PropTypes
 */

Alert.propTypes = {
  title: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default Alert

import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import SubmitButton from '~/components/SubmitButton'
import TextField from '~/components/TextField'
import Toast from '~/components/Toast'
import { AuthContext } from '~/context/auth'
import Heading from '../Heading'

/**
 * Forgot
 */

const Forgot = ({ onLoginClick }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { forgotPassword } = useContext(AuthContext)
  const { register, handleSubmit, formState } = useForm()
  const [error, setError] = useState()

  const handleForgotPassword = useCallback(
    async ({ email }) => {
      try {
        const answer = await forgotPassword(email)
        setError({ text: answer.message, layout: 'blue' })
        setTimeout(() => history.go(0), 2000)
      } catch (error) {
        const errorMessage = get(error, 'response.data.message')
        setError({ text: errorMessage, layout: 'red' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forgotPassword]
  )

  return (
    <div className="relative">
      {!isEmpty(error) && <Toast message={error} />}
      <div className="lg:min-h-screen flex pt-16 lg:pt-32 justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full">
          <div>
            <Heading level={1} size="xl">
              {t('guest:forgot.title')}
            </Heading>
            <Heading level={2} size="sm" className="mt-4">
              {t('guest:forgot.subtitle')}
            </Heading>
          </div>
          <form className="mt-8" onSubmit={handleSubmit(handleForgotPassword)}>
            <div className="mb-4">
              <TextField label={t('guest:forgot.email')} name="email" type="text" register={register} />
            </div>
            <div className="mt-6 flex">
              <div className="text-sm leading-5" onClick={onLoginClick}>
                <p className="cursor-pointer font-medium text-paragraph focus:outline-none focus:underline">
                  {t('guest:signup.login')}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <SubmitButton text={t('guest:forgot.submit')} isSubmitting={formState.isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

Forgot.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default Forgot

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { POLL_TIME } from '~/components/polls/Poll'
import { getPollCountdownTime } from '~/lib/helpers'

/**
 * Countdown
 */

const Countdown = ({ endDate }) => {
  const [countdown, setCountdown] = useState(POLL_TIME)
  const interval = useRef(null)

  useEffect(() => {
    interval.current = setInterval(() => {
      const updatedTime = getPollCountdownTime(endDate)
      setCountdown(Math.round(updatedTime))
    }, 1000)

    return () => clearInterval(interval.current)
  }, [endDate])

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(interval.current)
    }
  }, [countdown])

  return (
    <div className="flex flex-row items-center">
      <span className="text-sm text-primary">{`${countdown}s`}</span>
      <div className="relative overflow-hidden rounded-lg w-full h-1 ml-2 bg-heading">
        <div className="progress absolute h-full" />
        <style jsx>{`
          .progress {
            width: ${(countdown * 100) / POLL_TIME}%;
            transition: width 0.5s ease-in-out;
            background: linear-gradient(to right, #fddd0d, #00baff);
          }
        `}</style>
      </div>
    </div>
  )
}

/**
 * Exports
 */

Countdown.propTypes = {
  endDate: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default Countdown

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag.js'
import loadPlayerJS from '../../../scripts/loadplayer.js'

let player = null

function VideoPlayer({ session, online }) {
  const [hls, setHls] = useState(null)

  useEffect(() => {
    setHls(session.hls)
  }, [session.hls])

  useEffect(() => {
    if (hls && online === 1) {
      loadPlayerJS(() => {
        try {
          player = new window.Playerjs({
            id: 'player',
            file: hls,
            autoplay: 1,
          })
        } catch (error) {
          bugsnag.notify(error)
        }
      })
    } else if (online !== 1 && player) {
      player.api('destroy')
    }
  }, [online, hls])

  return (
    <>
      <div
        className="shadow-lg h-auto bg-black mb-4 bg-cover"
        style={{ backgroundImage: `url(${online === 2 ? session.program_image_end : session.program_image})` }}
      >
        <div id="player">
          <div className="" style={{ paddingBottom: '56%' }}></div>
        </div>
      </div>
    </>
  )
}

/**
 * PropTypes
 */
VideoPlayer.propTypes = {
  session: PropTypes.shape({
    hls: PropTypes.string.isRequired,
    program_image: PropTypes.string.isRequired,
    program_image_end: PropTypes.string.isRequired,
  }),
  online: PropTypes.number.isRequired,
}

/**
 * Exports
 */

export default VideoPlayer

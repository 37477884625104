import React, { useContext, useEffect } from 'react'
import { isEmpty } from 'lodash'

import PageContainer from '~/components/layout/PageContainer'
import LoadingContent from '~/components/LoadingContent'
import StudioLive from '~/components/sessions/live/StudioLive'
import { AuthContext } from '~/context/auth'
import { useSessionManager } from '~/context/session'
import { useSocketManager } from '~/context/socketManager'

/**
 * SessionLive
 */

const SessionLive = () => {
  const { session } = useSessionManager()
  const { socketEmit } = useSocketManager()
  const { user } = useContext(AuthContext)
  const hasSession = !isEmpty(session)

  useEffect(() => {
    if (hasSession) {
      setTimeout(async () => {
        await socketEmit('sessionLogin', { token: user.token, session: session.id })
        // await socketEmit('programGet')
      }, 400)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSession])

  return (
    <PageContainer className={`md:mb-24 ${!hasSession ? 'h-full justify-center flex flex-1' : ''}`}>
      {!hasSession && (
        <div className="flex justify-center h-full">
          <LoadingContent />
        </div>
      )}
      {hasSession && <StudioLive session={session}></StudioLive>}
    </PageContainer>
  )
}

/**
 * Exports
 */

export default SessionLive

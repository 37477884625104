import React from 'react'

import LoadingContent from '../LoadingContent'

/**
 * ChatLoading
 */

const ChatLoading = () => (
  <>
    <div className="flex container justify-content items-center">
      <LoadingContent />
    </div>
    <style jsx>{`
      .container {
        height: calc(100% - 118px);
      }
    `}</style>
  </>
)

/**
 * Exports
 */

export default ChatLoading

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useUserMedia } from '~/hooks/useUserMedia'
import { bugsnag } from '~/lib/bugsnag'

function CameraLocal({ displayOption, play, isCameraActive, mediaConstraints }) {
  const videoRef = useRef()

  const { mediaStream, enableStream, disableStream } = useUserMedia(mediaConstraints, play)

  useEffect(() => {
    if (isCameraActive === true) {
      enableStream()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraActive])

  useEffect(() => {
    if (play) {
      try {
        // if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        //   console.log('CameraLocal mediaStream', mediaStream)
        //   videoRef.current.srcObject = mediaStream
        // }
        videoRef.current.srcObject = mediaStream
      } catch (error) {
        bugsnag.notify(error)
      }
    } else {
      disableStream()
      videoRef.current.srcObject = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play])

  function handleCanPlay() {
    videoRef.current.play()
  }

  return <video ref={videoRef} onCanPlay={handleCanPlay} autoPlay playsInline muted className={displayOption} />
}

/**
 * PropTypes
 */
CameraLocal.propTypes = {
  displayOption: PropTypes.string.isRequired,
  play: PropTypes.bool.isRequired,
  isCameraActive: PropTypes.bool,
  mediaConstraints: PropTypes.object,
}

/**
 * Exports
 */

export default CameraLocal

import React from 'react'
import PropTypes from 'prop-types'

/**
 * LogoutButton
 */

const LogoutButton = ({ label, logout }) => {
  return (
    <div className="mt-4 justify-between border-t sm:border-gray-300 sm:pt-5">
      <span className="block w-full rounded-md shadow-sm">
        <button
          onClick={logout}
          className="w-full flex justify-center py-3 px-4 border border-transparent text-base font-medium rounded-md
        text-white bg-primary hover:bg-primaryHover focus:outline-none focus:bg-primaryHover focus:shadow-outline-indigo
        active:bg-red-700 transition duration-150 ease-in-out"
        >
          {label}
        </button>
      </span>
    </div>
  )
}

/**
 * PropTypes
 */

LogoutButton.propTypes = {
  label: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default LogoutButton

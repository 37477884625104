import React from 'react'
import { Transition } from 'react-transition-group'
import PropTypes from 'prop-types'

/**
 * Constants
 */

const DEFAULT_STYLE = {
  transition: 'transform 500ms ease-in-out',
  transform: 'translateY(10px)',
}

const TRANSITION_STYLES = {
  entering: { transform: 'translateY(10px)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(0)' },
  exited: { transform: 'translateY(10px)' },
}

/**
 * SessionCardBottomBar
 */

const SessionCardBottomBar = ({ show }) => {
  return (
    <Transition in={show} appear timeout={100}>
      {state => (
        <div
          style={{ ...DEFAULT_STYLE, ...TRANSITION_STYLES[state] }}
          className="absolute bottom-0 left-0 right-0 h-2 rounded-b-lg bg-gradient-to-r from-blue-100 to-blue-900 transition-all"
        />
      )}
    </Transition>
  )
}

/**
 * PropTypes
 */

SessionCardBottomBar.propTypes = {
  show: PropTypes.bool.isRequired,
}

/**
 * Exports
 */

export default SessionCardBottomBar

import React, { useCallback, useEffect, useRef, useState } from 'react'
import reactHtmlParser from 'react-html-parser'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import { get, isEmpty, isNil, size } from 'lodash'
import PropTypes from 'prop-types'
import twemoji from 'twemoji'

import { bugsnag } from '~/lib/bugsnag'
import { SvgAvatar } from '../../util/svgavatar'
import Avatar from '../Avatar'
import deleteIcon from '../MemberView/resources/delete.svg'

import './style.scss'

/**
 * Helpers
 */

const getMessageBubbleColor = (role, isPress) => {
  if (isPress) {
    return 'linear-gradient(to right, #2195BE, #3EB5C1)'
  }

  if (role === 'moderador') {
    return '#131267'
  }

  return '#F5F6FA'
}

/**
 * ReceiverMessageBubble
 */

const ReceiverMessageBubble = ({ message, loggedInUser, actionGenerated }) => {
  const reactions = get(message, "metadata['@injected'].extensions.reactions[':heart:']")
  const alreadyReacted = !isEmpty(reactions) && !isNil(reactions[loggedInUser.uid])
  const [hasLocalReaction, setLocalReaction] = useState(alreadyReacted)
  const reactionCount = useRef(size(reactions))

  useEffect(() => {
    if (message.receiverType === 'group') {
      if (!message.sender.avatar) {
        const uid = message.sender.getUid()
        const char = message.sender
          .getName()
          .charAt(0)
          .toUpperCase()

        message.sender.setAvatar(SvgAvatar.getAvatar(uid, char))
      }
    }
  }, [message.receiverType, message.sender])

  const handleReaction = useCallback(() => {
    setLocalReaction(!hasLocalReaction)
    reactionCount.current = alreadyReacted || hasLocalReaction ? reactionCount.current - 1 : reactionCount.current + 1
    CometChat.callExtension('reactions', 'POST', 'v1/react', { msgId: message.id, emoji: ':heart:' })
  }, [alreadyReacted, hasLocalReaction, message.id])

  const emojiParsedMessage = twemoji.parse(message.text, { folder: 'svg', ext: '.svg' })
  const parsedMessage = reactHtmlParser(emojiParsedMessage)
  const emojiMessage = parsedMessage.filter(message => message instanceof Object && message.type === 'img')
  const messageClassName = classNames({
    'chat-txt-msg': true,
    size1x: parsedMessage.length === emojiMessage.length && emojiMessage.length === 1,
    size2x: parsedMessage.length === emojiMessage.length && emojiMessage.length === 2,
    size3x: parsedMessage.length === emojiMessage.length && emojiMessage.length > 2,
  })

  const reactionImageSrc = hasLocalReaction ? '/images/heart-checked.svg' : '/images/heart-unchecked.svg'

  const senderRole = get(message, 'sender.role')
  const isModerator = senderRole === 'moderador'
  const isPress = get(message, 'sender.metadata.press', false)
  const avatarImageSrc = isModerator ? '/images/moderator-avatar.svg' : message.sender.avatar
  const username = isModerator ? 'ORGANIZADOR' : message.sender.name

  useEffect(() => {
    const newReactionCount = size(reactions)
    reactionCount.current = newReactionCount
  }, [reactions])

  const handleDeleteMessage = useCallback(async () => {
    try {
      await CometChat.deleteMessage(message.id)
      actionGenerated('messageDeleted', [message])
    } catch (err) {
      bugsnag.notify(err)
    }
  }, [actionGenerated, message])

  return (
    <div className="cc1-chat-win-rcvr-row clearfix">
      <div className="cc1-chat-win-msg-block">
        <div className="cc1-chat-win-rcvr-thumbnail-wrap">
          <Avatar cornerRadius="50%" borderColor="#CCC" borderWidth="1px" image={avatarImageSrc}></Avatar>
        </div>
        <div className="cc1-chat-win-rcvr-dtls">
          <div className="flex flex-col">
            <div className={`cc1-chat-win-rcvr-heading ${isPress ? '' : 'mb-1'}`}>
              <div className="cc1-chat-win-rcvr-name-wrap">
                <span className="cc1-chat-win-rcvr-name">{username}</span>
              </div>
              <span className="cc1-chat-win-timestamp">
                {new Date(message.sentAt * 1000).toLocaleTimeString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              </span>
              {!isModerator && (
                <div className="reactions">
                  <img
                    onClick={handleReaction}
                    className="icon"
                    alt="Cantidad de reacciones positivas"
                    src={reactionImageSrc}
                  />
                  <p className="cc1-chat-win-reaction">{reactionCount.current}</p>
                </div>
              )}
              {loggedInUser.role === 'moderador' && (
                <div className="delete-button ml-1" onClick={handleDeleteMessage} role="button">
                  <img src={deleteIcon} alt="Borrar mensaje" />
                </div>
              )}
            </div>
            {isPress && <span className="journalist-label text-xs font-bold mb-1 text-gray-600">PERIODISTA</span>}
          </div>
          <div className="cc1-chat-win-rcvr-msg-wrap message-bubble">
            <p className={messageClassName}>{parsedMessage}</p>
          </div>
          <style jsx>{`
            .message-bubble {
              background: ${getMessageBubbleColor(senderRole, isPress)} !important;
            }

            .chat-txt-msg {
              color: ${isModerator || isPress ? '#EEF5FF' : '#373F45'} !important;
            }

            .journalist-label {
              margin-top: -3px;
            }

            .delete-button {
              width: 20px;
            }
          `}</style>
        </div>
      </div>
    </div>
  )
}

ReceiverMessageBubble.propTypes = {
  message: PropTypes.object,
  loggedInUser: PropTypes.object,
  actionGenerated: PropTypes.func,
}

export default ReceiverMessageBubble

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import io from 'socket.io-client'

const SocketManagerContext = React.createContext()

const SOCKET_URL = process.env.REACT_APP_API_BASE_URL
let socket = null

export function SocketManager(props) {
  // Session Events
  const [requests, setRequests] = useState([])
  const [programStatus, setProgramStatus] = useState('finished')
  const [programStatusPrevious, setProgramStatusPrevious] = useState('finished')
  const [programStatusNext, setProgramStatusNext] = useState('finished')
  const [programGuest, setProgramGuest] = useState('')
  const [programGraphics, setProgramGraphics] = useState(null)
  const [sessionOnline, setSessionOnline] = useState(null)

  // NavBar Events
  const [mainStageLive, setMainStageLive] = useState({})
  const [navSessionLive, setSessionLive] = useState(false)
  const [loginKickOut, setLoginKickout] = useState(false)
  const [sidebarMessage, setSidebarMessage] = useState({})

  // Pool Events
  const [pollInfo, setPollInfo] = useState({})
  const [pollResults, setPollResults] = useState({})

  useEffect(() => {
    function handleMessage(response) {
      // console.log('handleMessage', response.type, response.data)
      switch (response.type) {
        case 'auth_revalidate':
          socket.emit('ehlo', { token: props.user.token })
          break
        case 'need_login':
          setLoginKickout(true)
          break
        case 'full_capacity':
          setLoginKickout(true)
          break
        case 'poll_info':
          setPollInfo(response.data)
          break
        case 'poll_answers':
          setPollResults(response.data)
          break
        case 'program_status':
          setProgramStatusNext(response.data.program_status)
          break
        case 'program_guest':
          setProgramGuest(response.data.program_guest)
          break
        case 'all_request':
          setRequests(response.data || [])
          break
        case 'program_graphics':
          setProgramGraphics(response.data)
          break
        case 'session_online':
          setSessionOnline(response.data.online)
          break
        case 'mainstage_live':
          setMainStageLive(response.data.mainstage_live)
          break
        case 'session_live':
          setSessionLive(response.data.session_live)
          break
        case 'sidebar_message':
          setSidebarMessage(response.data)
          break
        default:
          break
      }
    }

    async function loadSocket() {
      socket = await io.connect(SOCKET_URL, {
        transports: ['websocket'],
        rejectUnauthorized: false,
        secure: true,
        reconnect: true,
        'reconnection delay': 2000,
        'max reconnection attempts': 15,
      })
      if (props.user.token) {
        socket.emit('ehlo', { token: props.user.token })
      }
      socket.on('message', handleMessage)
    }

    loadSocket()

    return () => {
      if (socket) {
        socket.off('message', handleMessage)
        socket.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (programStatusNext) {
      setProgramStatusPrevious(programStatus)
      setProgramStatus(programStatusNext)
      setProgramStatusNext(null)
    }
  }, [programStatus, programStatusPrevious, programStatusNext])

  function socketEmit(message, data) {
    socket.emit(message, data)
  }

  const value = useMemo(() => {
    return {
      requests,
      programStatus,
      programStatusPrevious,
      programGuest,
      programGraphics,
      sessionOnline,
      mainStageLive,
      navSessionLive,
      sidebarMessage,
      loginKickOut,
      pollInfo,
      pollResults,
      socketEmit,
    }
  }, [
    requests,
    programStatus,
    programStatusPrevious,
    programGuest,
    programGraphics,
    sessionOnline,
    mainStageLive,
    navSessionLive,
    sidebarMessage,
    loginKickOut,
    pollInfo,
    pollResults,
  ])

  return <SocketManagerContext.Provider value={value} {...props} />
}

export function useSocketManager() {
  const context = React.useContext(SocketManagerContext)
  if (!context) {
    throw new Error('useSocketManager should be inside SocketManagerContext provider')
  }

  return context
}

/**
 * PropTypes
 */

SocketManager.propTypes = {
  user: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }),
}

export const Routes = Object.freeze({
  HOME: '/',
  HOMEFULL: '/:message?',
  PLENARY: '/plenaria',
  SESSIONS: '/sesiones',
  SESSIONSDETAIL: '/sesiones/:id',
  SESSIONSLIVE: '/sesiones/vivo/:id',
  SESSIONSSTREAM: '/sesiones/stream/:id',
  SESSIONSZOOM: '/sesiones/zoom/:id',
  EXPO: '/expo',
  VOD: '/vod',
  VODDETAIL: '/vod/:id',
  SUPPORT: '/soporte-tecnico',
  RECOVERY: '/recuperar-contrasena/:h',
  PROFILE: '/perfil',
})

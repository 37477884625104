import React from 'react'
import { isSafari } from 'react-device-detect'
import { CometChat } from '@cometchat-pro/chat'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'
import * as enums from '../../util/enums.js'
import { SvgAvatar } from '../../util/svgavatar'
import CometChatAddMembers from '../CometChatAddMembers'
import CometChatBanMembers from '../CometChatBanMembers'
import CometChatViewMembers from '../CometChatViewMembers'
import GroupDetailContext from './context'
import { GroupDetailManager } from './controller'

import './style.scss'

/**
 * CometChatGroupDetail
 */

class CometChatGroupDetail extends React.Component {
  loggedInUser = null

  constructor() {
    super()

    this.state = {
      user: {},
      memberlist: [],
      bannedmemberlist: [],
      administratorslist: [],
      moderatorslist: [],
      viewMember: false,
      addMember: false,
      banMember: false,
      addAdministrator: false,
      addModerator: false,
    }
  }

  componentDidMount() {
    this.setState({
      memberlist: [],
      administratorslist: [],
      moderatorslist: [],
      bannedmemberlist: [],
    })

    const guid = this.props.item.guid
    this.GroupDetailManager = new GroupDetailManager(guid)
    this.getGroupMembers()
    this.getBannedGroupMembers()
    this.GroupDetailManager.attachListeners(this.groupUpdated)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item.guid !== this.props.item.guid) {
      this.setState({
        memberlist: [],
        administratorslist: [],
        moderatorslist: [],
        bannedmemberlist: [],
      })

      const guid = this.props.item.guid
      this.GroupDetailManager.removeListeners()
      this.GroupDetailManager = new GroupDetailManager(guid)
      this.getGroupMembers()
      this.getBannedGroupMembers()
      this.GroupDetailManager.attachListeners(this.groupUpdated)
    }
  }

  componentWillUnmount() {
    this.GroupDetailManager.removeListeners()
    this.GroupDetailManager = null
  }

  groupUpdated = (key, message, group, options) => {
    const guid = this.props.item.guid
    if (guid !== group.guid) {
      return false
    }

    switch (key) {
      case enums.USER_ONLINE:
      case enums.USER_OFFLINE:
        this.groupMemberUpdated(options.user)
        break
      case enums.GROUP_MEMBER_ADDED:
      case enums.GROUP_MEMBER_JOINED:
        {
          const member = options.user
          this.setAvatar(member)
          this.addParticipants([member], group, false)
        }
        break
      case enums.GROUP_MEMBER_LEFT:
        {
          const member = options.user
          this.removeParticipants(member, false)
        }
        break
      case enums.GROUP_MEMBER_KICKED: {
        this.removeParticipants(options.user, false)
        break
      }
      case enums.GROUP_MEMBER_BANNED:
        {
          const member = options.user
          this.setAvatar(member)
          this.banMembers([member])
          this.removeParticipants(member, false)
        }
        break
      case enums.GROUP_MEMBER_UNBANNED:
        {
          const member = options.user
          this.unbanMembers([member])
        }
        break
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
        {
          const member = options.user

          const updatedMember = Object.assign({}, member, { scope: options['scope'] })
          this.updateParticipants(updatedMember)
        }
        break
      default:
        break
    }
  }

  groupMemberUpdated = member => {
    const memberlist = [...this.state.memberlist]
    // search for user
    const memberKey = memberlist.findIndex(m => m.uid === member.uid)
    // if found in the list, update user object
    if (memberKey > -1) {
      const memberObj = memberlist[memberKey]
      const newMemberObj = Object.assign({}, memberObj, member)
      memberlist.splice(memberKey, 1, newMemberObj)

      this.setState({ memberlist: memberlist })
    }

    const bannedmemberlist = [...this.state.bannedmemberlist]
    // search for user
    const bannedMemberKey = bannedmemberlist.findIndex(m => m.uid === member.uid)
    // if found in the list, update user object
    if (bannedMemberKey > -1) {
      const bannedMemberObj = bannedmemberlist[bannedMemberKey]
      const newBannedMemberObj = Object.assign({}, bannedMemberObj, member)
      bannedmemberlist.splice(bannedMemberKey, 1, newBannedMemberObj)

      this.setState({ bannedmemberlist: bannedmemberlist })
    }
  }

  getGroupMembers = async () => {
    const administratorslist = []
    const moderatorslist = []

    try {
      const user = await new CometChatManager().getLoggedInUser()
      this.loggedInUser = user

      const groupMembers = await this.GroupDetailManager.fetchNextGroupMembers()
      groupMembers.forEach(member => {
        this.setAvatar(member)

        if (member.scope === 'admin') {
          administratorslist.push(member)
        }

        if (member.scope === 'moderator') {
          moderatorslist.push(member)
        }
      })

      this.setState({
        memberlist: [...this.state.memberlist, ...groupMembers],
        administratorslist: [...this.state.administratorslist, ...administratorslist],
        moderatorslist: [...this.state.moderatorslist, ...moderatorslist],
      })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  getBannedGroupMembers = async () => {
    if (this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT) {
      return false
    }

    const bannedMembers = await this.GroupDetailManager.fetchNextBannedGroupMembers()
    bannedMembers.forEach(member => this.setAvatar(member))

    this.setState({
      bannedmemberlist: [...this.state.bannedmemberlist, ...bannedMembers],
    })
  }

  setAvatar(member) {
    if (!member.avatar) {
      const uid = member.uid
      const char = member.name.charAt(0).toUpperCase()

      member.avatar = SvgAvatar.getAvatar(uid, char)
    }
  }

  deleteGroup = async () => {
    const guid = this.props.item.guid
    await CometChat.deleteGroup(guid)
    this.props.actionGenerated('groupDeleted', this.props.item)
  }

  leaveGroup = async () => {
    const guid = this.props.item.guid

    try {
      await CometChat.leaveGroup(guid)
      this.props.actionGenerated('leftGroup', this.props.item)
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  clickHandler = (action, flag) => {
    switch (action) {
      case 'viewmember':
        this.setState({ viewMember: flag })
        break
      case 'addmember':
        this.setState({ addMember: flag })
        break
      case 'banmember':
        this.setState({ banMember: flag })
        break
      default:
        break
    }
  }

  membersActionHandler = (action, members, group) => {
    switch (action) {
      case 'banGroupMembers':
        this.banMembers(members)
        break
      case 'unbanGroupMembers':
        this.unbanMembers(members)
        break
      case 'addGroupParticipants':
        this.addParticipants(members, group)
        break
      case 'removeGroupParticipants':
        this.removeParticipants(members, true, group)
        break
      case 'fetchGroupMembers':
        this.getGroupMembers()
        break
      case 'fetchBannedMembers':
        this.getBannedGroupMembers()
        break
      default:
        break
    }
  }

  banMembers = members => {
    this.setState({
      bannedmemberlist: [...this.state.bannedmemberlist, ...members],
    })
  }

  unbanMembers = members => {
    const bannedMembers = [...this.state.bannedmemberlist]
    const filteredBannedMembers = bannedMembers.filter(bannedmember => {
      const found = members.find(member => bannedmember.uid === member.uid)
      if (found) {
        return false
      }

      return true
    })

    this.setState({
      bannedmemberlist: [...filteredBannedMembers],
    })
  }

  addParticipants = (members, group, triggerUpdate = true) => {
    const memberlist = [...this.state.memberlist, ...members]
    this.setState({
      memberlist: memberlist,
    })

    if (triggerUpdate) {
      this.props.actionGenerated('membersUpdated', group, memberlist.length)
    }
  }

  removeParticipants = (member, triggerUpdate = true, group) => {
    const groupmembers = [...this.state.memberlist]
    const filteredMembers = groupmembers.filter(groupmember => {
      if (groupmember.uid === member.uid) {
        return false
      }

      return true
    })

    this.setState({ memberlist: filteredMembers })

    if (triggerUpdate) {
      this.props.actionGenerated('membersUpdated', group, filteredMembers.length)
    }
  }

  updateParticipants = updatedMember => {
    const memberlist = [...this.state.memberlist]

    const memberKey = memberlist.findIndex(member => member.uid === updatedMember.uid)
    if (memberKey > -1) {
      const memberObj = memberlist[memberKey]
      const newMemberObj = Object.assign({}, memberObj, updatedMember, { scope: updatedMember['scope'] })

      memberlist.splice(memberKey, 1, newMemberObj)

      this.setState({ memberlist })
    }
  }

  render() {
    const isAdmin = this.props.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN

    return (
      <GroupDetailContext.Provider
        value={{
          memberlist: this.state.memberlist,
          bannedmemberlist: this.state.bannedmemberlist,
          administratorslist: this.state.administratorslist,
          moderatorslist: this.state.moderatorslist,
          loggedinuser: this.loggedInUser,
          item: this.props.item,
        }}
      >
        <div className="ccl-dtls-panel-wrap">
          <div className="ccl-right-panel-head-wrap">
            <div className="cc1-right-panel-close" onClick={() => this.props.actionGenerated('closeDetailClicked')} />
          </div>
          <div className="ccl-dtls-panel-body">
            {!isSafari && (
              <div className="ccl-dtls-panel-section">
                <h6 className="ccl-dtls-panel-section-head">Participantes</h6>
                <div className="chat-ppl-list-wrap">
                  {isAdmin && (
                    <div className="chat-ppl-listitem add-member">
                      <div className="chat-ppl-listitem-dtls">
                        <span className="chat-ppl-listitem-name" onClick={() => this.clickHandler('addmember', true)}>
                          Agregar personas
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="chat-ppl-listitem add-member">
                    <div className="chat-ppl-listitem-dtls">
                      <span className="chat-ppl-listitem-name" onClick={() => this.clickHandler('viewmember', true)}>
                        Ver personas
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="ccl-dtls-panel-section">
              <h6 className="ccl-dtls-panel-section-head">Opciones</h6>
              <div className="ccl-dtls-section-list">
                {!isAdmin && (
                  <div className="ccl-dtls-section-listitem">
                    <span className="ccl-dtls-section-listitem-link" onClick={this.leaveGroup}>
                      Abandonar grupo
                    </span>
                  </div>
                )}
                {isAdmin && (
                  <div className="ccl-dtls-section-listitem">
                    <span className="ccl-dtls-section-listitem-link" onClick={this.deleteGroup}>
                      Eliminar y salir
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.viewMember && (
          <CometChatViewMembers
            item={this.props.item}
            open={this.state.viewMember}
            close={() => this.clickHandler('viewmember', false)}
            actionGenerated={this.membersActionHandler}
          />
        )}
        {this.state.addMember && (
          <CometChatAddMembers
            open={this.state.addMember}
            close={() => this.clickHandler('addmember', false)}
            actionGenerated={this.membersActionHandler}
          />
        )}
        {this.state.banMember && (
          <CometChatBanMembers
            item={this.props.item}
            open={this.state.banMember}
            close={() => this.clickHandler('banmember', false)}
            actionGenerated={this.membersActionHandler}
          />
        )}
      </GroupDetailContext.Provider>
    )
  }
}

/**
 * PropTypes
 */

CometChatGroupDetail.propTypes = {
  actionGenerated: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired, // Group object item
}

/**
 * Exports
 */

export default CometChatGroupDetail

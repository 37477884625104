import React from 'react'
import ReactGA from 'react-ga'

import LinkDuo from '~/components/global/LinkDuo'
import { useSidebarMessageQuery } from '~/hooks/sidebarMessages'

/**
 * Constants
 */

export const SidebarMessage_TIME = 25

/**
 * SidebarMessage
 */

const SidebarMessage = () => {
  const { data } = useSidebarMessageQuery()
  const { title, btnLabel, link } = data

  if (!title) {
    return <div />
  }

  return (
    <>
      <div className="SidebarMessage absolute top-0 w-full z-10 p-4 bg-heading">
        <div className="p-4 rounded-lg bg-white">
          <h3 className="font-bold text-heading">{title}</h3>
          {link && (
            <div className="mt-2 mb-3">
              <LinkDuo
                to={link}
                target="_blank"
                onClick={() => {
                  ReactGA.event({ category: 'MainStage', action: 'SideBar', label: link })
                }}
                className="block option relative overflow-hidden mb-2 rounded-full cursor-pointer bg-primary text-white text-sm"
                rel="noopener noreferrer"
              >
                <div className="flex flex-row h-full items-center justify-between py-2 px-3">
                  <div className="flex flex-row z-10">
                    <img className="mr-2" src="/images/icon-link-arrow-circle.svg" alt="Link" />
                    <span className="font-semibold flex justify-center items-center">{btnLabel}</span>
                  </div>
                </div>
              </LinkDuo>
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .SidebarMessage {
          opacity: 1;
          animation-name: fadeIn;
          animation-iteration-count: 1;
          animation-timing-function: ease-in-out;
          animation-duration: 0.6s;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      `}</style>
    </>
  )
}

/**
 * Exports
 */

export default SidebarMessage

import { CometChat } from '@cometchat-pro/chat'
import { isEmpty } from 'lodash'

import { bugsnag } from './bugsnag'

const APP_ID = process.env.REACT_APP_COMET_CHAT_APP_ID
const REGION = 'us'

const init = async () => {
  const APP_SETTING = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(REGION)
    .build()

  await CometChat.init(APP_ID, APP_SETTING)
}

const login = async authToken => {
  try {
    return await CometChat.login(authToken)
  } catch (error) {
    bugsnag.notify(error)
  }
}

const getLoggedInUser = async () => {
  try {
    const loggedInUser = await CometChat.getLoggedinUser()

    if (isEmpty(loggedInUser)) {
      return {}
    }

    const user = await CometChat.getUser(loggedInUser.uid)

    if (isEmpty(user)) {
      return {}
    }

    return user
  } catch (error) {
    bugsnag.notify(error)
  }
}

export { init, login, getLoggedInUser }

import React from 'react'
import PropTypes from 'prop-types'

function GraphUser({ programGuest }) {
  if (programGuest.full_name === '' || programGuest.company_fantasy === '') {
    return null
  }

  return (
    <div className="absolute bottom-0 right-0 w-2/4 z-10">
      <div className="rounded-full bg-white px-8 py-3 border-b mx-2 border-gray-200">
        <h3 className="text-heading text-lg leading-6 font-bold">{programGuest.full_name}</h3>
        <p className="text-heading text-sm leading-5">{programGuest.company_role}</p>
      </div>
      <div
        className="text-heading text-sm leading-10 font-bold h-10 mx-4 mb-6 pl-12 pr-5 bg-contain bg-no-repeat overflow-hidden"
        style={{ backgroundImage: `url(/images/graph_sub.svg)` }}
      >
        {programGuest.company_fantasy}
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

GraphUser.propTypes = {
  programGuest: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    company_fantasy: PropTypes.string.isRequired,
    company_role: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default GraphUser

import React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * PrivateChatMessage
 */

const PrivateChatMessage = () => {
  const { t } = useTranslation()

  return (
    <div className="relative flex flex-row items-center justify-center h-10 bg-privateMessageBackground">
      <img className="mr-2" alt={t('sidebar:chat.privateMessage.imageAlt')} src="/images/icon-lock.svg" />
      <p className="text-sm text-privateMessageText">{t('sidebar:chat.privateMessage.title')}</p>
    </div>
  )
}

/**
 * Exports
 */

export default PrivateChatMessage

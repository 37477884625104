import React from 'react'
import PropTypes from 'prop-types'

function ScreenPadBtn({ title, active, enable, onClick }) {
  let activeShow = active ? ' bg-green-600' : 'bg-pink-600'
  let enableLi = enable ? ' cursor-pointer' : 'cursor-not-allowed'

  if (!enable) {
    activeShow = ' bg-gray-500'
  }

  function onClickHandler(e) {
    if (enable) {
      onClick(e)
    }
  }

  return (
    <li className={`col-span-1 flex shadow-sm justify-center rounded-md ${enableLi}`} onClick={onClickHandler}>
      <div
        className={`flex-shrink-0 flex items-center justify-center w-full h-12 text-white text-sm leading-5 font-medium rounded-md ${activeShow}`}
      >
        {title}
      </div>
    </li>
  )
}

/**
 * PropTypes
 */
ScreenPadBtn.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  enable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ScreenPadBtn

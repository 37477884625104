import React from 'react'
import PropTypes from 'prop-types'

/**
 * SidebarToggleButton
 */

const SidebarToggleButton = ({ isSidebarOpen, onClick }) => {
  const imgSrc = isSidebarOpen ? '/images/icon-close-sidebar.svg' : '/images/icon-open-sidebar.svg'

  return (
    <>
      <button onClick={onClick} className="hidden md:block mr-2">
        <img src={imgSrc} alt="" />
      </button>
      <style jsx>{`
        button {
          margin-right: ${isSidebarOpen ? '13.5rem' : '2rem'};
        }
      `}</style>
    </>
  )
}

/**
 * PropTypes
 */

SidebarToggleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}

/**
 * Exports
 */

export default SidebarToggleButton

import { useCallback, useState } from 'react'

import { bugsnag } from '~/lib/bugsnag'

export function useUserMedia(requestedMedia) {
  const [mediaStream, setMediaStream] = useState(null)

  const enableStream = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(requestedMedia)
      setMediaStream(stream)
    } catch (error) {
      bugsnag.notify(error)
    }

    return function cleanup() {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => {
          track.stop()
        })
      }
    }
  }, [mediaStream, requestedMedia])

  const disableStream = useCallback(async () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => {
        track.stop()
      })
    }
  }, [mediaStream])

  return {
    mediaStream,
    enableStream,
    disableStream,
  }
}

import React, { useContext, useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

import { AuthContext } from '~/context/auth'
import { useSessionManager } from '~/context/session'
import { useSocketManager } from '~/context/socketManager'
import * as animationDataLoading from '~/lotties/session_loading.json'
import * as animationData from '~/lotties/session_to_start.json'
import CameraLocal from './WebRTC/CameraLocal'
import CameraRemote from './WebRTC/CameraRemote'
import GraphUser from './GraphUser'

function Program() {
  const {
    programStatus,
    programStatusPrevious,
    programGuest,
    programGraphics,
    sessionOnline,
    socketEmit,
  } = useSocketManager()
  const { user } = useContext(AuthContext)
  const { session, moderator, isModerator, isCameraActive, mediaConstraints } = useSessionManager()

  const [play1, setPlay1] = useState(true)
  const [play2, setPlay2] = useState(false)
  const [play3, setPlay3] = useState(false)
  const [classCamera1, setClassCamera1] = useState('')
  const [classCamera2, setClassCamera2] = useState('')
  const [classCamera3, setClassCamera3] = useState('')

  useEffect(() => {
    setTimeout(function() {
      socketEmit('program')
    }, 1200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let classCamera1Aux =
      'absolute top-0 bottom-0 h-full object-cover transition-width duration-500 transform ease-in-out z-1 '
    let classCamera2Aux =
      'absolute top-0 bottom-0 h-full object-cover transition-width duration-500 transform ease-in-out z-2 '
    let classCamera3Aux =
      'absolute top-0 bottom-0 h-full object-cover transition-width duration-500 transform ease-in-out z-3 '

    if (programStatus === 'split') {
      if (moderator === user.id) {
        classCamera1Aux += ' w-1/2 left-0'
        classCamera2Aux += ' w-0 left-0'
        classCamera3Aux += ' w-1/2 right-0'
      } else if (programGuest.id === user.id) {
        classCamera1Aux += ' w-1/2 right-0'
        classCamera2Aux += ' w-1/2 left-0'
        classCamera3Aux += ' w-0'
      } else {
        classCamera1Aux += ' w-0 left-0'
        classCamera2Aux += ' w-1/2 left-0'
        classCamera3Aux += ' w-1/2 right-0'
      }
    } else if (programStatus === 'moderator') {
      classCamera3Aux += ' w-0 right-0'
      if (isModerator) {
        classCamera1Aux += ' w-full left-0'
        classCamera2Aux += ' w-0'
      } else {
        classCamera1Aux += ' w-0 right-0'
        classCamera2Aux += ' w-full left-0'
      }
    } else if (programStatus === 'guest') {
      classCamera2Aux += ' w-0'
      if (programGuest.id === user.id) {
        classCamera1Aux += ' w-full right-0'
        classCamera3Aux += ' w-0 right-0'
      } else {
        classCamera1Aux += ' w-0'
        classCamera3Aux += ' w-full right-0'
      }
    } else {
      classCamera1Aux += ' w-0 left-0'
      classCamera2Aux += ' w-0 left-0'
      classCamera3Aux += ' w-0 right-0'
    }

    setTimeout(() => {
      let play1Aux = classCamera1Aux.indexOf('w-0') <= 0
      let play2Aux = classCamera2Aux.indexOf('w-0') <= 0 || programStatus === 'guest'
      let play3Aux = classCamera3Aux.indexOf('w-0') <= 0

      setPlay1(play1Aux)
      setPlay2(play2Aux)
      setPlay3(play3Aux)
    }, 600)

    setClassCamera1(classCamera1Aux)
    setClassCamera2(classCamera2Aux)
    setClassCamera3(classCamera3Aux)
  }, [programStatus, programStatusPrevious, programGuest, user, isModerator, moderator])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
  }

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
  }
  // console.log('sessionOnline', programGraphics, sessionOnline, sessionOnline === 2)

  return (
    <div className="relative">
      <div
        className="relative pb-16/9 object-cover bg-cover w-full overflow-hidden"
        style={{ backgroundImage: `url(${sessionOnline === 2 ? session.program_image_end : session.program_image})` }}
      >
        {programGraphics && programGraphics.status === 'bump' && (
          <div className="absolute top-0 bottom-0 left-0 right-0 h-full object-cover transition-width duration-500 transform ease-in-out z-10">
            <Lottie isClickToPauseDisabled options={defaultOptions} />
          </div>
        )}
        <div className="w-full-4">
          <div className={classCamera1 + ' z-0 bg-white flex justify-center items-center'}>
            <Lottie isClickToPauseDisabled options={defaultOptions2} />
          </div>
          <CameraLocal
            play={play1}
            displayOption={classCamera1}
            isCameraActive={isCameraActive}
            mediaConstraints={mediaConstraints}
          />
        </div>
        <div className="w-full-4">
          <CameraRemote play={play2} muted={false} stream={'' + moderator} displayOption={classCamera2} domId="pgr" />
        </div>
        <div className="w-full-4">
          <CameraRemote
            play={play3}
            muted={false}
            stream={'' + programGuest.id}
            displayOption={classCamera3}
            domId="pgr"
          />
          {programGraphics && programGraphics.status === 'snipe' && <GraphUser programGuest={programGuest} />}
        </div>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */
Program.propTypes = {
  session: PropTypes.shape({
    moderator: PropTypes.string.isRequired,
    program_image: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default Program

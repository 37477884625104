import React, { useEffect, useRef, useState } from 'react'
// import { isSafari } from 'react-device-detect'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import * as animationDataLoading from '~/lotties/session_loading.json'

let webRTCAdaptor = null

function PlayWebRTC({ stream, displayOption, play, muted, domId }) {
  const videoRef = useRef()
  const [adaptorReady, setAdaptorReady] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [showReload, setShowReload] = useState(false)

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: animationDataLoading.default,
  }

  function initializeWebRTCPlayer(name, token, noStreamCallback) {
    // console.log('initializeWebRTCPlayer Stream:', name, process.env.REACT_APP_WEBRTC_PLAY_BASE_URL)
    // webRTCAdaptor is a global variable
    webRTCAdaptor = new window.WebRTCAdaptor({
      websocket_url: process.env.REACT_APP_WEBRTC_PLAY_BASE_URL,
      mediaConstraints: { video: false, audio: false },
      peerconnection_config: null,
      sdp_constraints: { OfferToReceiveAudio: true, OfferToReceiveVideo: true },
      remoteVideoId: videoRef.current.id,
      isPlayMode: true,
      debug: true,
      callback: function(info, description) {
        if (info === 'initialized') {
          // console.log('initialized', name)
          webRTCAdaptor.getStreamInfo(name)
        } else if (info === 'streamInformation') {
          // console.log('stream information', name)
          webRTCAdaptor.play(name, token)
        } else if (info === 'play_started') {
          // joined the stream
          // console.log('play started')
          // document.getElementById("video_info").style.display = "none";
          // playWebRTCVideo();
        } else if (info === 'play_finished') {
          // leaved the stream
          // console.log('play finished')
          // check that publish may start again
          // setTimeout(function() {
          //   webRTCAdaptor.getStreamInfo(name)
          // }, 3000)
        } else if (info === 'closed') {
          // console.log("Connection closed");
          if (typeof description != 'undefined') {
            // console.log('Connecton closed: ' + JSON.stringify(description))
          }
        }
      },
      callbackError: function(error) {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

        // console.log('error callback: ' + error)

        if (error === 'no_stream_exist') {
          if (typeof noStreamCallback != 'undefined') {
            noStreamCallback()
          }
        }
      },
    })
    // setWebRTCAdaptor(webRTCAdaptor)
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = '/webrtc_adaptor.js'
    script.async = false
    script.onload = () => {
      setAdaptorReady(true)
    }
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (adaptorReady && stream > 0 && play === true) {
      setPlaying(true)
      initializeWebRTCPlayer('stream_' + stream, 'token')
      setTimeout(() => {
        if (videoRef.current && videoRef.current.paused) {
          setShowReload(true)
        }
      }, 2000)
    } else if (play === false && playing) {
      videoRef.current.pause()
      videoRef.current.removeAttribute('src')
      setPlaying(false)
      setShowReload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stream, adaptorReady, play, playing])

  function handleCanPlay() {
    try {
      videoRef.current.play().catch(() => {
        setShowReload(true)
      })
      setPlaying(true)
    } catch (error) {
      videoRef.current.removeAttribute('src')
      console.error('PLAY ERROR', error)
      bugsnag.notify(error)
      setShowReload(true)
    }
  }

  function reloadPlayer() {
    setPlaying(true)
    initializeWebRTCPlayer('stream_' + stream, 'token')
    setShowReload(false)
  }

  return (
    <>
      <div className={displayOption + ' z-0 bg-white flex justify-center items-center'}>
        <Lottie isClickToPauseDisabled options={defaultOptions2} />
        {/* <pre>
          {JSON.stringify(playing)} - {JSON.stringify(showReload)}
        </pre> */}
        {showReload && (
          <button
            className="absolute bottom-0 mb-2 -ml-4 inline-flex items-center px-3 py-2 border border-transparent text-xs font-medium rounded-md text-white bg-primary hover:bg-primaryHover focus:outline-none focus:bg-primaryHover focus:shadow-outline-indigo active:bg-primaryHover delay-3000 transition ease-in-out duration-150"
            onClick={reloadPlayer}
          >
            PLAY VIDEO
          </button>
        )}
      </div>
      <video
        id={`${domId}_remoteVideo_${stream}`}
        ref={videoRef}
        onCanPlay={handleCanPlay}
        autoPlay
        muted={muted}
        playsInline
        className={`${displayOption} pointer-events-none`}
      />
    </>
  )
}

/**
 * PropTypes
 */
PlayWebRTC.propTypes = {
  stream: PropTypes.string.isRequired,
  play: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  displayOption: PropTypes.string.isRequired,
  domId: PropTypes.string.isRequired,
}

/**
 * Exports
 */

export default PlayWebRTC

import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CometChat } from '@cometchat-pro/chat'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import ButtonLoader from '~/components/ButtonLoader'
import { bugsnag } from '~/lib/bugsnag'

/**
 * Constants
 */

const COMET_CHAT_SYSTEM_USER_ID = 'app_system'

/**
 * InvitationMessage
 */

const InvitationMessage = ({ item, type, onAcceptClick, onDenyClick, open }) => {
  const [ownerName, setOwnerName] = useState()
  const [loading, setLoading] = useState(false)
  const isGroup = type === 'group'
  const [accepted, setAccepted] = useState(!open)
  const { t } = useTranslation()

  useEffect(() => {
    const getOwnerName = async () => {
      if (item.owner === COMET_CHAT_SYSTEM_USER_ID) {
        setOwnerName(t('sidebar:chat.invitationMessage.appSystemName'))

        return
      }

      try {
        const owner = await CometChat.getUser(item.owner)
        setOwnerName(owner.name)
      } catch (error) {
        bugsnag.notify(error)
      }
    }

    if (isGroup) {
      getOwnerName()
    }
  }, [isGroup, item, t])

  const name = isGroup ? ownerName : item.name

  const handleDenyClick = useCallback(() => {
    onDenyClick()
    setAccepted(true)
  }, [onDenyClick])

  const handleAcceptClick = useCallback(async () => {
    setLoading(true)
    try {
      await onAcceptClick(item)
      setAccepted(true)
    } catch (error) {
      bugsnag.notify(error)
    }
    setLoading(false)
  }, [item, onAcceptClick])

  return (
    <>
      <div className="invitation absolute inset-0 h-full w-full">
        <div className="box absolute bottom-0 font-bold text-heading flex flex-col w-full p-4">
          <div className="mb-3">
            <span className="text-primary">{name}</span>
            {t('sidebar:chat.invitationMessage.title')}
          </div>
          <div className="flex flex-row w-full">
            <button
              onClick={handleDenyClick}
              disabled={loading}
              className="flex flex-1 items-center justify-center mr-2 bg-white border-2 border-primary text-primary px-4 py-1 rounded-lg text-sm font-bold"
            >
              {t('sidebar:chat.invitationMessage.deny')}
            </button>
            <button
              onClick={handleAcceptClick}
              disabled={loading}
              className="flex flex-1 items-center justify-center ml-2 bg-primary text-white px-4 py-1 rounded-lg text-sm font-bold"
            >
              {!loading && t('sidebar:chat.invitationMessage.accept')}
              {loading && <ButtonLoader size={18} />}
            </button>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .invitation {
            background: linear-gradient(rgba(255, 255, 255, 0.2), #fff);
            z-index: 2;
            pointer-events: ${!accepted ? 'auto' : 'none'};
            opacity: ${!accepted ? '1' : '0'};
            transition: opacity 0.3s ease-in-out;
          }

          .box {
            opacity: ${!accepted && !isEmpty(name) ? '1' : '0'};
            transition: opacity 0.3s ease-in-out;
          }
        `}
      </style>
    </>
  )
}

/**
 * PropTypes
 */

InvitationMessage.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  type: PropTypes.string,
  onAcceptClick: PropTypes.func,
  onDenyClick: PropTypes.func,
}

/**
 * Exports
 */

export default InvitationMessage

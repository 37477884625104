import React, { useCallback, useState } from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { isEmpty } from 'lodash'

import { CometChatConversationListScreen } from '~/components/CometChat'
import { useAuth } from '~/context/auth'
import { useChat } from '~/context/chat'
import CometChatCreateGroupMembers from '../CometChat/components/CometChatCreateGroupMembers'
import ChatLoading from './ChatLoading'

/**
 * ChatInbox
 */

const ChatInbox = () => {
  const { cometChatUser, setCometChatUser } = useAuth()
  const {
    conversations,
    setNewConversations,
    getConversations,
    selectedConversation,
    setSelectedConversation,
    updateConversation,
  } = useChat()
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false)

  const handleNewGroupClick = useCallback(() => {
    setCreateGroupModalOpen(true)
  }, [])

  const handleCloseGroupModalClick = useCallback(() => {
    setCreateGroupModalOpen(false)
  }, [])

  const handleGroupCreate = useCallback(
    async (group, conversation) => {
      const newConversationList = [...conversations, conversation]
      setNewConversations(newConversationList)
      updateConversation(conversation.lastMessage, group, undefined, {
        hasJoined: true,
        scope: CometChat.GROUP_MEMBER_SCOPE.ADMIN,
      })
    },
    [conversations, setNewConversations, updateConversation]
  )

  if (isEmpty(cometChatUser)) {
    return <ChatLoading />
  }

  return (
    <>
      <CometChatConversationListScreen
        loggedInUser={cometChatUser}
        conversations={conversations}
        setNewConversations={setNewConversations}
        getConversations={getConversations}
        selectedConversation={selectedConversation}
        setSelectedConversation={setSelectedConversation}
        updateConversation={updateConversation}
        updateUser={setCometChatUser}
        onNewGroupClick={handleNewGroupClick}
      />
      <CometChatCreateGroupMembers
        open={isCreateGroupModalOpen}
        close={handleCloseGroupModalClick}
        onGroupCreated={handleGroupCreate}
      />
    </>
  )
}

export default ChatInbox

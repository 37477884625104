import React from 'react'
import PropTypes from 'prop-types'

import ProgramTab from './ProgramTab'

/**
 * ProgramTabs
 */

const ProgramTabs = ({ dates, selectedDate, onTabClick }) => (
  <div className="flex flex-col lg:flex-row justify-between py-8 lg:px-6">
    {dates.map((date, index) => {
      const selected = selectedDate === index

      return <ProgramTab key={date} date={date} selected={selected} onClick={onTabClick} index={index} />
    })}
  </div>
)

/**
 * PropTypes
 */

ProgramTabs.propTypes = {
  dates: PropTypes.array.isRequired,
  selectedDate: PropTypes.number.isRequired,
  onTabClick: PropTypes.func.isRequired,
}

/**
 * Exports
 */

export default ProgramTabs

import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

/**
 * Helpers
 */

const getButtonText = (hasEnded, isOpened, isRegistered, isFull, t) => {
  if (hasEnded) {
    return t('sessions:cardButtons.finished')
  }

  if (isOpened && isRegistered) {
    return t('sessions:cardButtons.enter')
  }

  if (isRegistered) {
    return t('sessions:cardButtons.registered')
  }

  if (isFull) {
    return t('sessions:cardButtons.full')
  }

  return t('sessions:cardButtons.register')
}

const getButtonStyles = (hasEnded, isOpened, isRegistered, isFull, loading) => {
  if ((isRegistered && hasEnded) || hasEnded) {
    return 'text-blue-900 border border-blue-900 bg-white cursor-default'
  }

  if (isRegistered) {
    return `text-white bg-blue-900 cursor-default ${isOpened ? 'pointer-events-none' : ''}`
  }

  if (isFull) {
    return 'text-blue-900 border border-blue-900 bg-white cursor-default'
  }

  return `${!loading ? 'bg-primary hover:bg-blue-300' : 'bg-blue-200'} text-white cursor-pointer`
}

/**
 * CardBtnRegister
 */

const CardBtnRegister = ({ onClick, hasEnded, isOpened, isFull, isRegistered }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const text = getButtonText(hasEnded, isOpened, isRegistered, isFull, t)
  const styles = getButtonStyles(hasEnded, isOpened, isRegistered, isFull, loading)

  const handleClick = useCallback(async () => {
    setLoading(true)
    await onClick()
    setLoading(false)
  }, [onClick])

  return (
    <>
      <div
        onClick={handleClick}
        disabled={loading || isRegistered || isFull || hasEnded}
        className={`button rounded-full h-8 flex items-center justify-center px-4 py-1 text-xs mr-2 leading-5 transition-all ${styles}`}
      >
        {text}
      </div>
      {!hasEnded && !(isFull && !isRegistered) && (
        <div
          className={`arrow rounded-full h-8 w-8 flex items-center justify-center transition-all ${
            !isRegistered ? 'bg-primary hover:bg-blue-400' : 'bg-blue-900'
          }`}
        >
          {!isOpened && isRegistered ? (
            <img className="align-middle w-4" src="/images/icon-check.svg" alt="" />
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  fill="#FFF"
                  fillRule="nonzero"
                  d="M12.625,22 C12.4583333,22 12.3008333,21.9341333 12.1833333,21.8142222 C11.94,21.5676444 11.94,21.1656889 12.1833333,20.9182667 L19.8666667,13.1324889 L2.625,13.1324889 C2.28,13.1333333 2,12.8496 2,12.5 C2,12.1504 2.28,11.8666667 2.625,11.8666667 L19.8658333,11.8666667 L12.1825,4.08088889 C12.065,3.96182222 12,3.80222222 12,3.63333333 C12,3.46444444 12.065,3.30484444 12.1833333,3.18577778 C12.3008333,3.06586667 12.4583333,3 12.625,3 C12.7916667,3 12.9491667,3.06586667 13.0666667,3.18577778 L21.8166667,12.0524444 C21.875,12.1115556 21.9208333,12.1808 21.9525,12.2593333 C21.955,12.2660889 21.9583333,12.2736889 21.9608333,12.2812889 C21.9866667,12.3496889 22,12.4248444 22,12.5 C22,12.5734667 21.9866667,12.6469333 21.9608333,12.7178667 C21.9591667,12.7229333 21.9575,12.7271556 21.9558333,12.7313778 C21.9208333,12.8192 21.8741667,12.8892889 21.8158333,12.9484 L13.0666667,21.8142222 C12.9491667,21.9341333 12.7916667,22 12.625,22 Z"
                />
              </g>
            </svg>
          )}
        </div>
      )}
      <style jsx>
        {`
          .button {
            width: 120px;
            transition: background-color 0.2s ease-in-out;
          }

          .arrow {
            transition: background-color 0.2s ease-in-out;
          }
        `}
      </style>
    </>
  )
}

/**
 * PropTypes
 */

CardBtnRegister.propTypes = {
  onClick: PropTypes.func.isRequired,
  hasEnded: PropTypes.bool.isRequired,
  isOpened: PropTypes.bool.isRequired,
  isRegistered: PropTypes.bool.isRequired,
}

/**
 * Exports
 */

export default CardBtnRegister

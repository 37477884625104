import React from 'react'
import { isEmpty } from 'lodash'

import { useExpos } from '~/api'
import ExpoCard from '~/components/expo/ExpoCard'
import PageContainer from '~/components/layout/PageContainer'

/**
 * Expo
 */

const Expo = () => {
  const { expos } = useExpos()

  return (
    <PageContainer>
      <ul className="grid grid-cols-1 gap-6 lg:grid-cols-2 pb-8">
        {!isEmpty(expos) &&
          expos.map((expo, index) => {
            return <ExpoCard expo={expo} key={index} />
          })}
      </ul>
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Expo

import { clamp } from 'lodash'
import { isNil } from 'lodash'
import moment from 'moment'

export const getPollCountdownTime = endDate => moment.duration(moment(endDate).diff(moment.now())).as('seconds')

export const setVhProperty = value => document.documentElement.style.setProperty('--vh', `${value}px`)

export const getUserPhotoUrl = user =>
  isNil(user.photo_url)
    ? 'https://d2x5ku95bkycr3.cloudfront.net/App_Themes/Common/images/profile/0_200.png'
    : user.photo_url

export const getUnreadMessagesText = unreadMessageCount => {
  const plusText = unreadMessageCount > 99 ? '+' : ''

  return `${plusText}${clamp(unreadMessageCount, 0, 99)}`
}

export const removeEmojis = text =>
  text.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  )

import React from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash'

import { useFAQs } from '~/api'
import Heading from '~/components/Heading'
import PageContainer from '~/components/layout/PageContainer'
import FAQCard from '~/components/support/FAQCard'

/**
 * Support
 */

const Support = () => {
  const { t } = useTranslation()
  const { support } = useFAQs(process.env.REACT_APP_EVENT_ID)
  const faqs = get(support, 'faqs.faqs')

  return (
    <PageContainer>
      <div className="banner flex flex-1 flex-col p-6 bg-cover bg-center bg-white rounded-lg shadow mb-20">
        <Heading level={1} size="md" className="mb-8">
          {t('support:title')}
        </Heading>
        <div>
          <div className="flex flex-row items-center mb-4">
            <img src="/images/icon-phone.svg" className="mr-2" alt="Teléfono" />
            <a
              href={`tel:+${t('support:phone')}`}
              onClick={() => {
                ReactGA.event({ category: 'Support', action: 'Link', label: 'Phone' })
              }}
            >
              <span className="text-lg leading-5 font-bold text-heading">{t('support:phone')}</span>
            </a>
          </div>
          <div className="flex flex-row items-center">
            <img src="/images/icon-whatsapp.svg" className="mr-2" alt="WhatsApp" />
            <a
              href={`mailto:${t('support:email')}`}
              onClick={() => {
                ReactGA.event({ category: 'Support', action: 'Link', label: 'Email' })
              }}
            >
              <span className="text-lg leading-5 font-bold text-heading">{t('support:email')}</span>
            </a>
          </div>
        </div>
      </div>
      <Heading level={2} size="lg" className="mb-8">
        {t('support:faq')}
      </Heading>
      <div className="p-6 bg-white rounded-lg shadow">
        <ul className="grid grid-cols-1 lg:grid-cols-1">
          {!isEmpty(faqs) &&
            faqs.map((faq, index) => {
              return <FAQCard faq={faq} key={index}></FAQCard>
            })}
        </ul>
      </div>
      <style jsx>{`
        .banner {
          min-height: 335px;
          background-image: url('/images/sessions/session_detail.jpg');
          border-radius: 15px;
        }
      `}</style>
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Support

import { useCallback, useMemo } from 'react'
import { isEmpty, isNil, reduce, toNumber } from 'lodash'

import { useSocketManager } from '~/context/socketManager'

export const usePollQuery = () => {
  const { pollInfo = {}, pollResults, socketEmit } = useSocketManager()

  const sendAnswer = useCallback(
    option => {
      if (pollInfo) {
        socketEmit('pollAnswer', { id: pollInfo.id, option })
      }
    },
    [pollInfo, socketEmit]
  )

  const totalResults = useMemo(
    () =>
      !isEmpty(pollResults)
        ? reduce(
            pollResults,
            (item, n) => {
              const numberToAdd = isNil(n.total) ? 0 : toNumber(n.total)

              return item + numberToAdd
            },
            0
          )
        : 0,
    [pollResults]
  )

  return {
    data: pollInfo,
    results: pollResults,
    totalResults,
    sendAnswer,
  }
}

import React, { useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import { Route, Switch, useLocation } from 'react-router-dom'

import MainLayout from '~/components/layout/MainLayout'
import { Routes as RouteIds } from '~/constants/routes'
import { AuthContext } from '~/context/auth'
import Expo from '~/pages/Expo'
import Guest from '~/pages/Guest'
import Home from '~/pages/Home'
import MainStage from '~/pages/MainStage'
import Profile from '~/pages/Profile'
import RecoverPassword from '~/pages/RecoverPassword'
import SessionsDetail from '~/pages/sessions/Detail'
import Sessions from '~/pages/sessions/Home'
import SessionsLive from '~/pages/sessions/Live'
import SessionsStream from '~/pages/sessions/Stream'
import SessionsZoom from '~/pages/sessions/Zoom'
import Support from '~/pages/Support'
import Vod from '~/pages/Vod'
import VodDetail from '~/pages/VodDetail'

/**
 * Routes
 */

const Routes = () => {
  const { isLoggedIn } = useContext(AuthContext)
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location.pathname, location.search])

  return (
    <div>
      {!isLoggedIn && (
        <Switch>
          <Route path={RouteIds.RECOVERY} render={props => <RecoverPassword {...props} />} exact />
          <Route path={RouteIds.HOMEFULL} render={props => <Guest {...props} />} />
        </Switch>
      )}
      {isLoggedIn && (
        <MainLayout>
          <Switch>
            <Route path={RouteIds.PLENARY} render={props => <MainStage {...props} />} />
            <Route path={RouteIds.SESSIONSLIVE} render={props => <SessionsLive {...props} />} />
            <Route path={RouteIds.SESSIONSZOOM} render={props => <SessionsZoom {...props} />} />
            <Route path={RouteIds.SESSIONSSTREAM} render={props => <SessionsStream {...props} />} />
            <Route path={RouteIds.SESSIONSDETAIL} render={props => <SessionsDetail {...props} />} />
            <Route path={RouteIds.SESSIONS} render={props => <Sessions {...props} />} />
            <Route path={RouteIds.EXPO} render={props => <Expo {...props} />} />
            <Route path={RouteIds.VODDETAIL} render={props => <VodDetail {...props} />} />
            <Route path={RouteIds.VOD} render={props => <Vod {...props} />} />
            <Route path={RouteIds.SUPPORT} render={props => <Support {...props} />} />
            <Route path={RouteIds.PROFILE} render={props => <Profile {...props} />} />
            <Route path={RouteIds.HOMEFULL} render={props => <Home {...props} />} exact />
          </Switch>
        </MainLayout>
      )}
    </div>
  )
}

/**
 * Exports
 */

export default Routes

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

const truncate = time => (time > 100 ? 100 : time < 0 ? 0 : Math.trunc(time))

const RemainingTime = props => {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const calculateTimeLeft = () => {
      // eslint-disable-next-line react/prop-types
      const start = moment(props.start).unix()
      // eslint-disable-next-line react/prop-types
      const end = moment(props.end).unix()
      const now = moment().unix()
      const time = ((now - start) * 100) / (end - start)

      return time
    }
    setTimeLeft(calculateTimeLeft())
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 10000)
  })

  return (
    <>
      <div className="text-xs text-paragraph">{t('mainStage:reactions.remainingTime')}</div>
      <div className="relative pt-1 w-32">
        <div className="overflow-hidden h-1 text-xs flex rounded bg-backgroundPrimary">
          <div
            style={{ width: `${truncate(timeLeft)}%` }}
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary"
          ></div>
        </div>
      </div>
    </>
  )
}

export default RemainingTime

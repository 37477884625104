import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { getUnreadMessagesText } from '~/lib/helpers'

/**
 * UnreadMessageCount
 */

const UnreadMessageCount = ({ count, offset }) => (
  <>
    <div className="message-count flex items-center justify-center absolute rounded-full bg-primary text-2xs text-white">
      {getUnreadMessagesText(count)}
    </div>
    <style jsx>{`
      .message-count {
        width: 20px;
        height: 20px;
        top: ${!isEmpty(offset) ? offset.x : '-7'}px;
        right: ${!isEmpty(offset) ? offset.y : '-24'}px;
        padding-left: 1px;
      }
    `}</style>
  </>
)

/**
 * PropTypes
 */

UnreadMessageCount.propTypes = {
  count: PropTypes.number,
  offset: PropTypes.object,
}

/**
 * Exports
 */

export default UnreadMessageCount

import React from 'react'
import { CometChat } from '@cometchat-pro/chat'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { bugsnag } from '~/lib/bugsnag'
import { CometChatManager } from '../../util/controller'
import CometChatMessageListScreen from '../CometChatMessageListScreen'
import CometChatUserDetail from '../CometChatUserDetail'
import CometChatUserList from '../CometChatUserList'

import './style.scss'

/**
 * CometChatUserListScreen
 */

class CometChatUserListScreen extends React.Component {
  leftPanelRef = React.createRef()
  loggedInUser = null

  state = {
    viewdetailscreen: false,
    item: {},
    type: 'user',
    tab: 'contacts',
  }

  async componentDidMount() {
    if (!Object.keys(this.state.item).length) {
      this.toggleSideBar()
    }

    try {
      const loggedInUser = await new CometChatManager().getLoggedInUser()
      const user = await CometChat.getUser(loggedInUser.uid)
      this.loggedInUser = user
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  onItemClicked = (item, type) => {
    this.toggleSideBar()
    this.setState({ item: { ...item }, type, viewdetailscreen: false })
  }

  actionHandler = (action, item) => {
    switch (action) {
      case 'blockUser':
        this.blockUser()
        break
      case 'unblockUser':
        this.unblockUser()
        break
      case 'viewDetail':
      case 'closeDetailClicked':
        this.toggleDetailView()
        break
      case 'menuClicked':
      case 'closeMenuClicked':
        this.toggleSideBar()
        break
      case 'closeThreadClicked':
        this.closeThreadMessages()
        break
      case 'updateConversation':
        this.props.updateConversation(item, null, null, { isUserListConversation: true })
        break
      default:
        break
    }
  }

  blockUser = async () => {
    let usersList = [this.state.item.uid]

    try {
      CometChatManager.blockUsers(usersList)
      this.setState({ item: { ...this.state.item, blockedByMe: true } })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  unblockUser = async () => {
    let usersList = [this.state.item.uid]

    try {
      CometChatManager.unblockUsers(usersList)
      this.setState({ item: { ...this.state.item, blockedByMe: false } })
    } catch (error) {
      bugsnag.notify(error)
    }
  }

  toggleSideBar = () => {
    const elem = this.leftPanelRef.current
    if (elem.classList.contains('active')) {
      elem.classList.remove('active')
    } else {
      elem.classList.add('active')
    }
  }

  toggleDetailView = () => {
    this.setState({ viewdetailscreen: !this.state.viewdetailscreen })
  }

  render() {
    const { viewdetailscreen, item, tab, type } = this.state

    const wrapperClassName = classNames({
      users: true,
    })

    const centerPanelClassName = classNames({
      'ccl-center-panel': true,
      'ccl-chat-center-panel': true,
      'right-panel-active': viewdetailscreen,
    })

    return (
      <div className={wrapperClassName}>
        <div className="ccl-left-panel" ref={this.leftPanelRef}>
          <CometChatUserList item={item} onItemClick={this.onItemClicked} actionGenerated={this.actionHandler} />
        </div>
        <div className={centerPanelClassName}>
          {Object.keys(item).length && (
            <CometChatMessageListScreen
              showPrivateMessageHeader
              item={item}
              tab={tab}
              type={type}
              widgetconfig={{ 'threaded-chats': false }}
              actionGenerated={this.actionHandler}
              loggedInUser={this.loggedInUser}
            />
          )}
        </div>
        {viewdetailscreen && (
          <div className="ccl-right-panel">
            <CometChatUserDetail item={item} type={type} actionGenerated={this.actionHandler} />
          </div>
        )}
      </div>
    )
  }
}

/**
 * PropTypes
 */

CometChatUserListScreen.propTypes = {
  updateConversation: PropTypes.func,
}

/**
 * Exports
 */

export default CometChatUserListScreen

import React from 'react'
import { useParams } from 'react-router-dom'

import { useVod } from '~/api'
import PageContainer from '~/components/layout/PageContainer'
import VODPlayer from '~/components/vod/VODPlayer'

/**
 * VOD
 */

const VOD = () => {
  const { id } = useParams()
  const { vod } = useVod(id)

  return (
    <PageContainer>
      <VODPlayer vod={vod} />
    </PageContainer>
  )
}

/**
 * Exports
 */

export default VOD

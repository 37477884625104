import React from 'react'
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const client = bugsnag.createClient({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_ENV,
  plugins: [new BugsnagPluginReact()],
})

const BugsnagErrorBoundary = client.getPlugin('react').createErrorBoundary(React)

/**
 * Exports
 */

export { client as bugsnag, BugsnagErrorBoundary }

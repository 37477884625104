import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * SuccessMsg
 */

const SuccessMsg = ({ title, onClose }) => {
  const titleOn = title && title !== '' && title !== false

  useEffect(() => {
    if (titleOn) {
      setTimeout(() => {
        onClose()
      }, 4000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleOn])

  return (
    <div
      className={`${
        titleOn ? 'h-auto' : 'h-0'
      } transition ease-out duration-200 absolute z-100 left-0 right-0 top-0 rounded-md bg-green-100 overflow-hidden`}
    >
      <div className="flex p-4">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-green-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium text-green-800">{title}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              onClick={onClose}
              className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
              aria-label="Dismiss"
            >
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
/**
 * PropTypes
 */

SuccessMsg.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

SuccessMsg.defaultProps = {
  title: '',
}

/**
 * Exports
 */

export default SuccessMsg

import PropTypes from 'prop-types'

export const vodPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  hls: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  poster: PropTypes.string.isRequired,
})

export const expoPropType = PropTypes.shape({
  banner: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  web: PropTypes.string,
})

export const agendaPropType = PropTypes.shape({
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  Hosts: PropTypes.array.isRequired,
  description: PropTypes.string,
})

export const sessionPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  enrolled: PropTypes.number.isRequired,
  quota: PropTypes.number.isRequired,
  full_title: PropTypes.string.isRequired,
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  moderator: PropTypes.string.isRequired,
  Users: PropTypes.array,
})

import React from 'react'
import { isEmpty } from 'lodash'

import Avatar from '../Avatar'
import StatusIndicator from '../StatusIndicator'

import './style.scss'

const userview = ({ user }) => {
  const { avatar, status, name, metadata } = user

  return (
    <div className="contact-listitem">
      <div className="contact-thumbnail-wrap">
        <Avatar image={avatar} cornerRadius="50%" />
      </div>
      <div className="contact-listitem-dtls">
        <div className="contact-listitem-name">
          <div>{name}</div>
          {!isEmpty(metadata) && (
            <div className="contact-listitem-metadata">
              {metadata.company_role} {metadata.company_fantasy}
            </div>
          )}
        </div>
        <StatusIndicator status={status} cornerRadius="50%" borderColor="rgb(238, 238, 238)" borderWidth="1px" />
      </div>
    </div>
  )
}

export default userview

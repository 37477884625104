import React, { useCallback } from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { get, includes, isEmpty, replace } from 'lodash'
import PropTypes from 'prop-types'

import SvgAvatar from '../../util/svgavatar'
import Avatar from '../Avatar'
import BadgeCount from '../BadgeCount'
import StatusIndicator from '../StatusIndicator'

import './style.scss'

/**
 * ConversationView
 */

const ConversationView = ({ config, conversation, conversationKey, handleClick }) => {
  const isModerator = conversation.conversationWith.role === 'moderador'
  const isUserConversation = conversation.conversationType === 'user'
  const isGroupConversation = conversation.conversationType === 'group'

  const getMessage = useCallback(() => {
    let message = ''
    const type = conversation.lastMessage.type

    switch (type) {
      case CometChat.MESSAGE_TYPE.TEXT:
        message = get(conversation, 'lastMessage.text', '')
        break
      case CometChat.MESSAGE_TYPE.CUSTOM:
        message = 'Custom message'
        break
      default:
        break
    }

    return message
  }, [conversation])

  const getActionMessage = useCallback(() => {
    let message = get(conversation, 'lastMessage.message')

    // if action messages are set to hide in config
    if (config) {
      const found = config.find(cfg => {
        return cfg.action === message.action && cfg.category === message.category
      })

      if (found && found.enabled === false) {
        message = ''
      }
    }

    if (includes(message, 'System') && includes(message, 'added')) {
      const replacedAction = replace(message, 'added', 'agregó a')

      return replace(replacedAction, 'System', 'Organización')
    }

    if (includes(message, 'added')) {
      return replace(message, 'added', 'agregó a')
    }

    if (includes(message, 'kicked')) {
      return replace(message, 'kicked', 'eliminó a')
    }

    if (includes(message, 'left')) {
      return replace(message, 'left', 'abandonó el grupo')
    }

    return message
  }, [config, conversation])

  const getLastMessage = useCallback(() => {
    if (!conversation.lastMessage) {
      return false
    }

    let message = ''

    switch (conversation.lastMessage.category) {
      case 'message':
        message = getMessage()
        break
      case 'action':
        message = getActionMessage()
        break
      default:
        break
    }

    return message
  }, [conversation.lastMessage, getActionMessage, getMessage])

  const getAvatar = useCallback(() => {
    const { conversationWith } = conversation

    if (isUserConversation && !isModerator) {
      return conversationWith.avatar
    }

    if (isUserConversation && isModerator) {
      return '/images/moderator-avatar.svg'
    }

    if (isGroupConversation) {
      const metadataPhotoUrl = get(conversationWith, 'metadata.photoUrl')
      if (!isEmpty(metadataPhotoUrl)) {
        return metadataPhotoUrl
      }

      if (!conversationWith.icon) {
        const guid = conversationWith.guid
        const char = conversationWith.name.charAt(0).toUpperCase()

        return SvgAvatar.getAvatar(guid, char)
      }

      return conversationWith.icon
    }

    return '/images/moderator-avatar.svg'
  }, [conversation, isGroupConversation, isModerator, isUserConversation])

  const name = `${conversation.conversationWith.name}${isModerator ? ' (Organizador)' : ''}`

  const handleItemClick = useCallback(() => handleClick(conversation, conversationKey), [
    conversation,
    conversationKey,
    handleClick,
  ])

  return (
    <div className="chat-listitem" onClick={handleItemClick}>
      <div className="chat-thumbnail-wrap">
        <Avatar image={getAvatar()} cornerRadius="18px" borderColor="#CCC" borderWidth="1px" />
      </div>
      <div className="chat-listitem-dtls">
        <div className="flex items-center">
          <div className="chat-listitem-name">{name}</div>
          {isUserConversation && (
            <StatusIndicator
              status={conversation.conversationWith.status}
              cornerRadius="50%"
              borderColor="rgb(238, 238, 238)"
              borderWidth="1px"
            />
          )}
        </div>
        <p className="chat-listitem-txt">{getLastMessage()} </p>
      </div>
      <BadgeCount count={conversation.unreadMessageCount}></BadgeCount>
    </div>
  )
}

/**
 * PropTypes
 */

ConversationView.propTypes = {
  conversation: PropTypes.object.isRequired,
  conversationKey: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  config: PropTypes.array,
}

/**
 * Exports
 */

export default ConversationView
